import { RpcError } from 'grpc-web';
import ReconnectingWebSocket from 'reconnecting-websocket';
import {
  DatetimeLimitation,
  DecimalLimitation,
  Edge,
  InputVariable,
  InputVariables,
  IntegerLimitation,
  NodeHandle,
  NodeHandlePosition,
  NodePosition,
  OutputVariables,
  StringComparison,
  StringLimitation,
  TypeDescription,
  Value,
  ValueType,
  Variable,
  VariableKey
} from '../protos/graph/v1/public_messages_pb';
import {
  AddEdges,
  AddNode,
  AddNodes,
  AffectedItems,
  ItemDescription,
  ItemRemovedMessage,
  ModifyPosition,
  ModifyPositions,
  PatchAppliedMessage,
  PatchError,
  RemoveElements,
  ServerMessage,
  WorkspaceRemovedMessage
} from '../protos/portal/v1/editor_messages_pb';
import { ProjectServicePromiseClient } from '../protos/portal/v1/project_service_grpc_web_pb';
import {
  ApplyPatchRequest,
  ApplyPatchResponse,
  Connection,
  GetExtensionProjectUrlRequest,
  GetExtensionProjectUrlResponse,
  GetExtensionTaskUrlRequest,
  GetExtensionTaskUrlResponse,
  GetInputConfigRequest,
  GetInputConfigResponse,
  NegotiateRequest,
  NegotiateResponse,
  NodeInput,
  PostInputConfigRequest,
  PostInputConfigResponse,
  StartDebugExecutionRequest,
  StartDebugExecutionResponse
} from '../protos/portal/v1/project_service_pb';
import { getDefaultMetadata, handleUnauthorized } from './utils';

const projectService = new ProjectServicePromiseClient(`${window.location.protocol}//${window.location.host}`);

export async function negotiate(request: NegotiateRequest): Promise<NegotiateResponse> {
  try {
    return await projectService.negotiate(request, getDefaultMetadata());
  } catch (error: unknown) {
    if (error instanceof RpcError && handleUnauthorized(error)) {
      console.log('Unauthorized. Redirecting to login....');
    }
    throw error;
  }
}

export async function applyPatch(request: ApplyPatchRequest): Promise<ApplyPatchResponse> {
  try {
    return await projectService.applyPatch(request, getDefaultMetadata());
  } catch (error: unknown) {
    if (error instanceof RpcError && handleUnauthorized(error)) {
      console.log('Unauthorized. Redirecting to login....');
    }
    throw error;
  }
}

export async function getExtensionProjectUrl(request: GetExtensionProjectUrlRequest): Promise<GetExtensionProjectUrlResponse> {
  try {
    return await projectService.getExtensionProjectUrl(request, getDefaultMetadata());
  } catch (error: unknown) {
    if (error instanceof RpcError && handleUnauthorized(error)) {
      console.log('Unauthorized. Redirecting to login....');
    }
    throw error;
  }
}

export async function getExtensionTaskUrl(request: GetExtensionTaskUrlRequest): Promise<GetExtensionTaskUrlResponse> {
  try {
    return await projectService.getExtensionTaskUrl(request, getDefaultMetadata());
  } catch (error: unknown) {
    if (error instanceof RpcError && handleUnauthorized(error)) {
      console.log('Unauthorized. Redirecting to login....');
    }
    throw error;
  }
}

export async function getInputConfig(request: GetInputConfigRequest): Promise<GetInputConfigResponse> {
  try {
    return await projectService.getInputConfig(request, getDefaultMetadata());
  } catch (error: unknown) {
    if (error instanceof RpcError && handleUnauthorized(error)) {
      console.log('Unauthorized. Redirecting to login....');
    }
    throw error;
  }
}

export async function postInputConfig(request: PostInputConfigRequest): Promise<GetInputConfigResponse> {
  try {
    return await projectService.postInputConfig(request, getDefaultMetadata());
  } catch (error: unknown) {
    if (error instanceof RpcError && handleUnauthorized(error)) {
      console.log('Unauthorized. Redirecting to login....');
    }
    throw error;
  }
}

export async function startDebugExecution(request: StartDebugExecutionRequest): Promise<StartDebugExecutionResponse> {
  try {
    return await projectService.startDebugExecution(request, getDefaultMetadata());
  } catch (error: unknown) {
    if (error instanceof RpcError && handleUnauthorized(error)) {
      console.log('Unauthorized. Redirecting to login....');
    }
    throw error;
  }
}

export class ProjectSocket {
  socket_: ReconnectingWebSocket;
  disposed_: boolean;
  patch_applied_callback_: null | ((message: PatchAppliedMessage) => void);
  item_removed_callback_: null | ((message: ItemRemovedMessage) => void);
  workspace_removed_callback_: null | ((message: WorkspaceRemovedMessage) => void);
  onclose_callback_: null | (() => void);
  onopen_callback_: null | (() => void);

  constructor(workspaceId: string) {
    this.disposed_ = false;
    this.patch_applied_callback_ = null;
    this.item_removed_callback_ = null;
    this.workspace_removed_callback_ = null;
    this.onclose_callback_ = null;
    this.onopen_callback_ = null;

    this.socket_ = new ReconnectingWebSocket(async () => {
      const response = await negotiate(new NegotiateRequest().setWorkspaceId(workspaceId));
      return response.getUri();
    });

    this.socket_.onopen = () => {
      if (this.disposed_) {
        return;
      }
      if (this.onopen_callback_) {
        this.onopen_callback_();
      }
    };
    this.socket_.onclose = () => {
      if (this.disposed_) {
        return;
      }
      if (this.onclose_callback_) {
        this.onclose_callback_();
      }
    };
    this.socket_.onmessage = async (event) => {
      if (this.disposed_) {
        return;
      }
      const arrayBuffer = await event.data.arrayBuffer();
      const message = ServerMessage.deserializeBinary(arrayBuffer);
      switch (message.getMessageCase()) {
        case ServerMessage.MessageCase.MESSAGE_NOT_SET:
          console.error('Received empty message from server');
          break;
        case ServerMessage.MessageCase.PATCH: {
          if (this.patch_applied_callback_) {
            this.patch_applied_callback_(message.getPatch() as PatchAppliedMessage);
          }
          break;
        }
        case ServerMessage.MessageCase.ITEM_REMOVED: {
          if (this.item_removed_callback_) {
            this.item_removed_callback_(message.getItemRemoved() as ItemRemovedMessage);
          }
          break;
        }
        case ServerMessage.MessageCase.WORKSPACE_REMOVED: {
          if (this.workspace_removed_callback_) {
            this.workspace_removed_callback_(message.getWorkspaceRemoved() as WorkspaceRemovedMessage);
          }
          break;
        }
      }
    };
    this.socket_.onerror = (event) => {
      console.error(event);

      if (this.disposed_) {
        return;
      }

      if (this.onclose_callback_) {
        this.onclose_callback_();
      }
    };
  }

  onPatchedApplied(callback: (message: PatchAppliedMessage) => void): void {
    if (this.disposed_) {
      return;
    }
    this.patch_applied_callback_ = callback;
  }

  onItemRemoved(callback: (message: ItemRemovedMessage) => void): void {
    if (this.disposed_) {
      return;
    }
    this.item_removed_callback_ = callback;
  }

  onWorkspaceRemoved(callback: (message: WorkspaceRemovedMessage) => void): void {
    if (this.disposed_) {
      return;
    }
    this.workspace_removed_callback_ = callback;
  }

  onopen(callback: () => void): void {
    this.onopen_callback_ = callback;
  }

  onclose(callback: () => void): void {
    this.onclose_callback_ = callback;
  }

  dispose(): void {
    this.disposed_ = true;
    this.socket_.close();
  }
}

export {
  AddEdges,
  AddNode,
  AddNodes,
  AffectedItems,
  Connection,
  DatetimeLimitation,
  DecimalLimitation,
  Edge,
  GetExtensionProjectUrlRequest,
  GetExtensionProjectUrlResponse,
  GetExtensionTaskUrlRequest,
  GetExtensionTaskUrlResponse,
  GetInputConfigRequest,
  GetInputConfigResponse,
  InputVariable,
  InputVariables,
  IntegerLimitation,
  ItemDescription,
  ItemRemovedMessage,
  ModifyPosition,
  ModifyPositions,
  NegotiateRequest,
  NegotiateResponse,
  NodeHandle,
  NodeHandlePosition,
  NodeInput,
  NodePosition,
  OutputVariables,
  PatchAppliedMessage,
  PatchError,
  PostInputConfigRequest,
  PostInputConfigResponse,
  RemoveElements,
  ServerMessage,
  StartDebugExecutionRequest,
  StartDebugExecutionResponse,
  StringComparison,
  StringLimitation,
  TypeDescription,
  Value,
  ValueType,
  Variable,
  VariableKey,
  WorkspaceRemovedMessage
};

