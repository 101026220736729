import { Metadata, RpcError } from 'grpc-web';
import {
  ExtensionServicePromiseClient
} from '../protos/portal/v1/extension_service_grpc_web_pb';
import {
  CreateExtensionRequest,
  CreateExtensionResponse,
  CreateVersionRequest,
  CreateVersionResponse,
  DeleteExtensionProgress,
  DeleteExtensionRequest,
  DeleteExtensionResponse,
  DeleteVersionProgress,
  DeleteVersionRequest,
  DeleteVersionResponse,
  DeprecateProgress,
  DeprecateRequest,
  DeprecateResponse,
  Deprecation,
  Extension,
  ExtensionTaskType,
  ExtensionVersion,
  GetExtensionRequest,
  GetExtensionResponse,
  GetVersionRequest,
  GetVersionResponse,
  InstallExtensionRequest,
  InstallExtensionResponse,
  ListExtensionRequest,
  ListExtensionResponse,
  ListVersionsRequest,
  ListVersionsResponse,
  ProgressStatus,
  UninstallExtensionRequest,
  UninstallExtensionResponse,
  VersionSummary
} from '../protos/portal/v1/extension_service_pb';
import { getDefaultMetadata, handleUnauthorized } from './utils';

const extensionService = new ExtensionServicePromiseClient(`${window.location.protocol}//${window.location.host}`);

async function resolveCall<TResponse>(call: (metadata: Metadata) => Promise<TResponse>): Promise<TResponse> {
  try {
    return await call(getDefaultMetadata());
  } catch (error: unknown) {
    if (error instanceof RpcError && handleUnauthorized(error)) {
      console.log('Unauthorized. Redirecting to login....');
    }
    throw error;
  }
}

export function listExtensions(request: ListExtensionRequest): Promise<ListExtensionResponse> {
  return resolveCall(extensionService.list.bind(extensionService, request));
}

export function createExtension(request: CreateExtensionRequest): Promise<CreateExtensionResponse> {
  return resolveCall(extensionService.create.bind(extensionService, request));
}

export function installExtension(request: InstallExtensionRequest): Promise<InstallExtensionResponse> {
  return resolveCall(extensionService.install.bind(extensionService, request));
}

export function uninstallExtension(request: UninstallExtensionRequest): Promise<UninstallExtensionResponse> {
  return resolveCall(extensionService.uninstall.bind(extensionService, request));
}

export function getExtension(request: GetExtensionRequest): Promise<GetExtensionResponse> {
  return resolveCall(extensionService.get.bind(extensionService, request));
}

export function createVersion(request: CreateVersionRequest): Promise<CreateVersionResponse> {
  return resolveCall(extensionService.createVersion.bind(extensionService, request));
}

export function getVersion(request: GetVersionRequest): Promise<GetVersionResponse> {
  return resolveCall(extensionService.getVersion.bind(extensionService, request));
}

export function deleteExtension(request: DeleteExtensionRequest): Promise<DeleteExtensionResponse> {
  return resolveCall(extensionService.delete.bind(extensionService, request));
}

export function deprecateVersion(request: DeprecateRequest): Promise<DeprecateResponse> {
  return resolveCall(extensionService.deprecateVersion.bind(extensionService, request));
}

export function deleteVersion(request: DeleteVersionRequest): Promise<DeleteVersionResponse> {
  return resolveCall(extensionService.deleteVersion.bind(extensionService, request));
}

export function listVersions(request: ListVersionsRequest): Promise<ListVersionsResponse> {
  return resolveCall(extensionService.listVersions.bind(extensionService, request));
}

export {
  CreateExtensionRequest,
  CreateExtensionResponse,
  CreateVersionRequest,
  CreateVersionResponse,
  DeleteExtensionProgress,
  DeleteExtensionRequest,
  DeleteExtensionResponse,
  DeleteVersionProgress,
  DeleteVersionRequest,
  DeleteVersionResponse,
  DeprecateProgress,
  DeprecateRequest,
  DeprecateResponse,
  Deprecation,
  Extension,
  ExtensionTaskType,
  ExtensionVersion,
  GetExtensionRequest,
  GetExtensionResponse,
  GetVersionRequest,
  GetVersionResponse,
  ListExtensionRequest,
  ListExtensionResponse,
  ListVersionsRequest,
  ListVersionsResponse,
  ProgressStatus,
  VersionSummary
};

