import { LinearProgress, useTheme } from '@mui/material';
import React, { createContext, useContext, useEffect, useState } from 'react';
import PacmanLoader from 'react-spinners/PacmanLoader';
import { getUser } from '../services/user';
import './loader.css';

export interface User {
  picture?: string;
  name: string;
  email: string;
  givenName: string;
  familyName: string;
  tenantName: string;
  tenantLogo?: string;
  tenantId: string;
}

const userContext = createContext<User | null>(null);

export function useUser(): User {
  return useContext(userContext) as User;
}

type UserProviderProps = NonNullable<unknown>;

export function UserProvider({ children }: React.PropsWithChildren<UserProviderProps>) {
  const [user, setUser] = useState<User | null>(null);
  const theme = useTheme();
  const loaderSize = 100;

  useEffect(() => {
    let subscribed = true;

    getUser()
      .then(result => {
        if (!subscribed) {
          return;
        }
        setUser({
          familyName: result.getFamilyName(),
          name: result.getName(),
          email: result.getEmail(),
          givenName: result.getGivenName(),
          picture: result.getPicture(),
          tenantId: result.getTenantId(),
          tenantLogo: result.getTenantLogo(),
          tenantName: result.getTenantName(),
        });
      }).catch(err => console.log(err));

    return () => { subscribed = false; };
  }, []);

  return user == null
    ? (<div className="loader-screen">
      <LinearProgress
        color="primary"
        style={{ width: '100%' }}
        aria-label="Loading user"
      />
      <div style={{
        position: 'fixed',
        top: `calc(50% - ${loaderSize}px)`,
        left: `calc(50% - ${loaderSize}px)`,
      }}>
        <PacmanLoader size={loaderSize} color={theme.palette.primary.main} />
      </div>
    </div>)
    :
    (
      <userContext.Provider value={user}>
        {children}
      </userContext.Provider>
    );
}
