export type ValidationErrors = { [key: string]: unknown };
export type ValidatorFn = (value?: unknown) => ValidationErrors | null;

export const requiredValidator: ValidatorFn = (value?: unknown) => {
  return value
    ? null
    : { required: true };
};

export function patternValidator(regex: RegExp): ValidatorFn {
  return (value?: unknown) => {
    return !value || (typeof value === 'string' && regex.test(value))
      ? null
      : { regex };
  };
}

export function minValidator(min: number): ValidatorFn {
  return (value?: unknown) => {
    return !value || (value instanceof Date ? value.getTime() >= min : value >= min)
      ? null
      : { min };
  };
}

export function maxValidator(max: number): ValidatorFn {
  return (value?: unknown) => {
    return !value || (value instanceof Date ? value.getTime() <= max : value <= max)
      ? null
      : { max };
  };
}

export function minLengthValidator(minLength: number): ValidatorFn {
  return (value?: unknown) => {
    return !value || (typeof value === 'string' && value.length >= minLength)
      ? null
      : { minLength };
  };
}

export function maxLengthValidator(maxLength: number): ValidatorFn {
  return (value?: unknown) => {
    return !value || (typeof value === 'string' && value.length <= maxLength)
      ? null
      : { maxLength };
  };
}

export function intervalValidator(min: number, max: number): ValidatorFn {
  return (value?: unknown) => {
    return !value || (
      value instanceof Date
        ? value.getTime() >= min && value.getTime() <= max
        : value >= min && value <= max
    )
      ? null
      : { interval: { min, max } };
  };
}

export function composeOrValidator(...validators: ValidatorFn[]): ValidatorFn {
  return (value?: unknown) => {
    const validationResult: ValidationErrors | null = validators.reduce(
      (errors: ValidationErrors | null, validator: ValidatorFn) => {
        if (errors === null) {
          return null;
        }
        const validation = validator(value);
        return validation === null
          ? null
          : { ...errors, ...validation };
      },
      {},
    );
    return validationResult === null || Object.keys(validationResult).length === 0
      ? null
      : validationResult;
  };
}

export function composeValidator(...validators: ValidatorFn[]): ValidatorFn {
  return (value?: unknown) => {
    const validationResult: ValidationErrors = validators.reduce(
      (errors: ValidationErrors, validator: ValidatorFn) => {
        const validation = validator(value);
        return validation === null
          ? errors
          : { ...errors, ...validation };
      },
      {},
    );
    return Object.keys(validationResult).length === 0
      ? null
      : validationResult;
  };
}
