export function fuzzyDate(unixTime: number) {
  if (!unixTime) {
    return '';
  }
  const delta = Math.round((+new Date() - +new Date(unixTime * 1000)) / 1000);

  const minute = 60,
    hour = minute * 60,
    day = hour * 24,
    week = day * 7,
    month = day * 30,
    year = 12 * month;
  if (delta < 30) {
    return 'just now';
  } else if (delta < minute) {
    return delta + ' seconds ago';
  } else if (delta < 2 * minute) {
    return 'a minute ago';
  } else if (delta < hour) {
    return Math.floor(delta / minute) + ' minutes ago';
  } else if (Math.floor(delta / hour) === 1) {
    return '1 hour ago';
  } else if (delta < day) {
    return Math.floor(delta / hour) + ' hours ago';
  } else if (delta < day * 2) {
    return 'yesterday';
  } else if (delta < week && delta < 2 * week) {
    return '1 week ago';
  } else if (delta < month) {
    return Math.floor(delta / week) + ' weeks ago';
  } else if (delta < year) {
    return Math.floor(delta / month) + ' months ago';
  } else {
    return Math.floor(delta / year) + ' years ago';
  }
}

export function fuzzyCount(count: number) {
  const billion = 1E9,
    million = 1E6,
    thousand = 1E3;

  if (count >= billion) {
    return '999 M+';
  } else if (count >= million) {
    return (count / million).toFixed(1) + ' M';
  } else if (count >= thousand) {
    return (count / thousand).toFixed(1) + ' K';
  } else {
    return '< 1 K';
  }
}
