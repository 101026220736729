import { Link } from '@mui/material';
import { PropsWithChildren } from 'react';
import { Link as RouterLink, To } from 'react-router-dom';

interface BreadcrumbProps extends PropsWithChildren {
  to: To;
}

export function Breadcrumb({
  children,
  to,
}: BreadcrumbProps) {
  return (
    <Link
      component={RouterLink}
      to={to}
      underline="hover"
      color="inherit"
      title={children?.toString()}
      style={{ textTransform: 'lowercase' }}
    >
      {children}
    </Link>
  );
}
