/**
 * @fileoverview gRPC-Web generated client stub for dibric.portal.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.19.1
// source: portal/v1/extension_service.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.dibric = {};
proto.dibric.portal = {};
proto.dibric.portal.v1 = require('./extension_service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dibric.portal.v1.ExtensionServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dibric.portal.v1.ExtensionServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dibric.portal.v1.ListExtensionRequest,
 *   !proto.dibric.portal.v1.ListExtensionResponse>}
 */
const methodDescriptor_ExtensionService_List = new grpc.web.MethodDescriptor(
  '/dibric.portal.v1.ExtensionService/List',
  grpc.web.MethodType.UNARY,
  proto.dibric.portal.v1.ListExtensionRequest,
  proto.dibric.portal.v1.ListExtensionResponse,
  /**
   * @param {!proto.dibric.portal.v1.ListExtensionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dibric.portal.v1.ListExtensionResponse.deserializeBinary
);


/**
 * @param {!proto.dibric.portal.v1.ListExtensionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dibric.portal.v1.ListExtensionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dibric.portal.v1.ListExtensionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dibric.portal.v1.ExtensionServiceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dibric.portal.v1.ExtensionService/List',
      request,
      metadata || {},
      methodDescriptor_ExtensionService_List,
      callback);
};


/**
 * @param {!proto.dibric.portal.v1.ListExtensionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dibric.portal.v1.ListExtensionResponse>}
 *     Promise that resolves to the response
 */
proto.dibric.portal.v1.ExtensionServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dibric.portal.v1.ExtensionService/List',
      request,
      metadata || {},
      methodDescriptor_ExtensionService_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dibric.portal.v1.GetExtensionRequest,
 *   !proto.dibric.portal.v1.GetExtensionResponse>}
 */
const methodDescriptor_ExtensionService_Get = new grpc.web.MethodDescriptor(
  '/dibric.portal.v1.ExtensionService/Get',
  grpc.web.MethodType.UNARY,
  proto.dibric.portal.v1.GetExtensionRequest,
  proto.dibric.portal.v1.GetExtensionResponse,
  /**
   * @param {!proto.dibric.portal.v1.GetExtensionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dibric.portal.v1.GetExtensionResponse.deserializeBinary
);


/**
 * @param {!proto.dibric.portal.v1.GetExtensionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dibric.portal.v1.GetExtensionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dibric.portal.v1.GetExtensionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dibric.portal.v1.ExtensionServiceClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dibric.portal.v1.ExtensionService/Get',
      request,
      metadata || {},
      methodDescriptor_ExtensionService_Get,
      callback);
};


/**
 * @param {!proto.dibric.portal.v1.GetExtensionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dibric.portal.v1.GetExtensionResponse>}
 *     Promise that resolves to the response
 */
proto.dibric.portal.v1.ExtensionServicePromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dibric.portal.v1.ExtensionService/Get',
      request,
      metadata || {},
      methodDescriptor_ExtensionService_Get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dibric.portal.v1.CreateExtensionRequest,
 *   !proto.dibric.portal.v1.CreateExtensionResponse>}
 */
const methodDescriptor_ExtensionService_Create = new grpc.web.MethodDescriptor(
  '/dibric.portal.v1.ExtensionService/Create',
  grpc.web.MethodType.UNARY,
  proto.dibric.portal.v1.CreateExtensionRequest,
  proto.dibric.portal.v1.CreateExtensionResponse,
  /**
   * @param {!proto.dibric.portal.v1.CreateExtensionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dibric.portal.v1.CreateExtensionResponse.deserializeBinary
);


/**
 * @param {!proto.dibric.portal.v1.CreateExtensionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dibric.portal.v1.CreateExtensionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dibric.portal.v1.CreateExtensionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dibric.portal.v1.ExtensionServiceClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dibric.portal.v1.ExtensionService/Create',
      request,
      metadata || {},
      methodDescriptor_ExtensionService_Create,
      callback);
};


/**
 * @param {!proto.dibric.portal.v1.CreateExtensionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dibric.portal.v1.CreateExtensionResponse>}
 *     Promise that resolves to the response
 */
proto.dibric.portal.v1.ExtensionServicePromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dibric.portal.v1.ExtensionService/Create',
      request,
      metadata || {},
      methodDescriptor_ExtensionService_Create);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dibric.portal.v1.DeleteExtensionRequest,
 *   !proto.dibric.portal.v1.DeleteExtensionResponse>}
 */
const methodDescriptor_ExtensionService_Delete = new grpc.web.MethodDescriptor(
  '/dibric.portal.v1.ExtensionService/Delete',
  grpc.web.MethodType.UNARY,
  proto.dibric.portal.v1.DeleteExtensionRequest,
  proto.dibric.portal.v1.DeleteExtensionResponse,
  /**
   * @param {!proto.dibric.portal.v1.DeleteExtensionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dibric.portal.v1.DeleteExtensionResponse.deserializeBinary
);


/**
 * @param {!proto.dibric.portal.v1.DeleteExtensionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dibric.portal.v1.DeleteExtensionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dibric.portal.v1.DeleteExtensionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dibric.portal.v1.ExtensionServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dibric.portal.v1.ExtensionService/Delete',
      request,
      metadata || {},
      methodDescriptor_ExtensionService_Delete,
      callback);
};


/**
 * @param {!proto.dibric.portal.v1.DeleteExtensionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dibric.portal.v1.DeleteExtensionResponse>}
 *     Promise that resolves to the response
 */
proto.dibric.portal.v1.ExtensionServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dibric.portal.v1.ExtensionService/Delete',
      request,
      metadata || {},
      methodDescriptor_ExtensionService_Delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dibric.portal.v1.InstallExtensionRequest,
 *   !proto.dibric.portal.v1.InstallExtensionResponse>}
 */
const methodDescriptor_ExtensionService_Install = new grpc.web.MethodDescriptor(
  '/dibric.portal.v1.ExtensionService/Install',
  grpc.web.MethodType.UNARY,
  proto.dibric.portal.v1.InstallExtensionRequest,
  proto.dibric.portal.v1.InstallExtensionResponse,
  /**
   * @param {!proto.dibric.portal.v1.InstallExtensionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dibric.portal.v1.InstallExtensionResponse.deserializeBinary
);


/**
 * @param {!proto.dibric.portal.v1.InstallExtensionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dibric.portal.v1.InstallExtensionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dibric.portal.v1.InstallExtensionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dibric.portal.v1.ExtensionServiceClient.prototype.install =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dibric.portal.v1.ExtensionService/Install',
      request,
      metadata || {},
      methodDescriptor_ExtensionService_Install,
      callback);
};


/**
 * @param {!proto.dibric.portal.v1.InstallExtensionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dibric.portal.v1.InstallExtensionResponse>}
 *     Promise that resolves to the response
 */
proto.dibric.portal.v1.ExtensionServicePromiseClient.prototype.install =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dibric.portal.v1.ExtensionService/Install',
      request,
      metadata || {},
      methodDescriptor_ExtensionService_Install);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dibric.portal.v1.UninstallExtensionRequest,
 *   !proto.dibric.portal.v1.UninstallExtensionResponse>}
 */
const methodDescriptor_ExtensionService_Uninstall = new grpc.web.MethodDescriptor(
  '/dibric.portal.v1.ExtensionService/Uninstall',
  grpc.web.MethodType.UNARY,
  proto.dibric.portal.v1.UninstallExtensionRequest,
  proto.dibric.portal.v1.UninstallExtensionResponse,
  /**
   * @param {!proto.dibric.portal.v1.UninstallExtensionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dibric.portal.v1.UninstallExtensionResponse.deserializeBinary
);


/**
 * @param {!proto.dibric.portal.v1.UninstallExtensionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dibric.portal.v1.UninstallExtensionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dibric.portal.v1.UninstallExtensionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dibric.portal.v1.ExtensionServiceClient.prototype.uninstall =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dibric.portal.v1.ExtensionService/Uninstall',
      request,
      metadata || {},
      methodDescriptor_ExtensionService_Uninstall,
      callback);
};


/**
 * @param {!proto.dibric.portal.v1.UninstallExtensionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dibric.portal.v1.UninstallExtensionResponse>}
 *     Promise that resolves to the response
 */
proto.dibric.portal.v1.ExtensionServicePromiseClient.prototype.uninstall =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dibric.portal.v1.ExtensionService/Uninstall',
      request,
      metadata || {},
      methodDescriptor_ExtensionService_Uninstall);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dibric.portal.v1.GetVersionRequest,
 *   !proto.dibric.portal.v1.GetVersionResponse>}
 */
const methodDescriptor_ExtensionService_GetVersion = new grpc.web.MethodDescriptor(
  '/dibric.portal.v1.ExtensionService/GetVersion',
  grpc.web.MethodType.UNARY,
  proto.dibric.portal.v1.GetVersionRequest,
  proto.dibric.portal.v1.GetVersionResponse,
  /**
   * @param {!proto.dibric.portal.v1.GetVersionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dibric.portal.v1.GetVersionResponse.deserializeBinary
);


/**
 * @param {!proto.dibric.portal.v1.GetVersionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dibric.portal.v1.GetVersionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dibric.portal.v1.GetVersionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dibric.portal.v1.ExtensionServiceClient.prototype.getVersion =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dibric.portal.v1.ExtensionService/GetVersion',
      request,
      metadata || {},
      methodDescriptor_ExtensionService_GetVersion,
      callback);
};


/**
 * @param {!proto.dibric.portal.v1.GetVersionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dibric.portal.v1.GetVersionResponse>}
 *     Promise that resolves to the response
 */
proto.dibric.portal.v1.ExtensionServicePromiseClient.prototype.getVersion =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dibric.portal.v1.ExtensionService/GetVersion',
      request,
      metadata || {},
      methodDescriptor_ExtensionService_GetVersion);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dibric.portal.v1.CreateVersionRequest,
 *   !proto.dibric.portal.v1.CreateVersionResponse>}
 */
const methodDescriptor_ExtensionService_CreateVersion = new grpc.web.MethodDescriptor(
  '/dibric.portal.v1.ExtensionService/CreateVersion',
  grpc.web.MethodType.UNARY,
  proto.dibric.portal.v1.CreateVersionRequest,
  proto.dibric.portal.v1.CreateVersionResponse,
  /**
   * @param {!proto.dibric.portal.v1.CreateVersionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dibric.portal.v1.CreateVersionResponse.deserializeBinary
);


/**
 * @param {!proto.dibric.portal.v1.CreateVersionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dibric.portal.v1.CreateVersionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dibric.portal.v1.CreateVersionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dibric.portal.v1.ExtensionServiceClient.prototype.createVersion =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dibric.portal.v1.ExtensionService/CreateVersion',
      request,
      metadata || {},
      methodDescriptor_ExtensionService_CreateVersion,
      callback);
};


/**
 * @param {!proto.dibric.portal.v1.CreateVersionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dibric.portal.v1.CreateVersionResponse>}
 *     Promise that resolves to the response
 */
proto.dibric.portal.v1.ExtensionServicePromiseClient.prototype.createVersion =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dibric.portal.v1.ExtensionService/CreateVersion',
      request,
      metadata || {},
      methodDescriptor_ExtensionService_CreateVersion);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dibric.portal.v1.ListVersionsRequest,
 *   !proto.dibric.portal.v1.ListVersionsResponse>}
 */
const methodDescriptor_ExtensionService_ListVersions = new grpc.web.MethodDescriptor(
  '/dibric.portal.v1.ExtensionService/ListVersions',
  grpc.web.MethodType.UNARY,
  proto.dibric.portal.v1.ListVersionsRequest,
  proto.dibric.portal.v1.ListVersionsResponse,
  /**
   * @param {!proto.dibric.portal.v1.ListVersionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dibric.portal.v1.ListVersionsResponse.deserializeBinary
);


/**
 * @param {!proto.dibric.portal.v1.ListVersionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dibric.portal.v1.ListVersionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dibric.portal.v1.ListVersionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dibric.portal.v1.ExtensionServiceClient.prototype.listVersions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dibric.portal.v1.ExtensionService/ListVersions',
      request,
      metadata || {},
      methodDescriptor_ExtensionService_ListVersions,
      callback);
};


/**
 * @param {!proto.dibric.portal.v1.ListVersionsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dibric.portal.v1.ListVersionsResponse>}
 *     Promise that resolves to the response
 */
proto.dibric.portal.v1.ExtensionServicePromiseClient.prototype.listVersions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dibric.portal.v1.ExtensionService/ListVersions',
      request,
      metadata || {},
      methodDescriptor_ExtensionService_ListVersions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dibric.portal.v1.DeleteVersionRequest,
 *   !proto.dibric.portal.v1.DeleteVersionResponse>}
 */
const methodDescriptor_ExtensionService_DeleteVersion = new grpc.web.MethodDescriptor(
  '/dibric.portal.v1.ExtensionService/DeleteVersion',
  grpc.web.MethodType.UNARY,
  proto.dibric.portal.v1.DeleteVersionRequest,
  proto.dibric.portal.v1.DeleteVersionResponse,
  /**
   * @param {!proto.dibric.portal.v1.DeleteVersionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dibric.portal.v1.DeleteVersionResponse.deserializeBinary
);


/**
 * @param {!proto.dibric.portal.v1.DeleteVersionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dibric.portal.v1.DeleteVersionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dibric.portal.v1.DeleteVersionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dibric.portal.v1.ExtensionServiceClient.prototype.deleteVersion =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dibric.portal.v1.ExtensionService/DeleteVersion',
      request,
      metadata || {},
      methodDescriptor_ExtensionService_DeleteVersion,
      callback);
};


/**
 * @param {!proto.dibric.portal.v1.DeleteVersionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dibric.portal.v1.DeleteVersionResponse>}
 *     Promise that resolves to the response
 */
proto.dibric.portal.v1.ExtensionServicePromiseClient.prototype.deleteVersion =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dibric.portal.v1.ExtensionService/DeleteVersion',
      request,
      metadata || {},
      methodDescriptor_ExtensionService_DeleteVersion);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dibric.portal.v1.DeprecateRequest,
 *   !proto.dibric.portal.v1.DeprecateResponse>}
 */
const methodDescriptor_ExtensionService_DeprecateVersion = new grpc.web.MethodDescriptor(
  '/dibric.portal.v1.ExtensionService/DeprecateVersion',
  grpc.web.MethodType.UNARY,
  proto.dibric.portal.v1.DeprecateRequest,
  proto.dibric.portal.v1.DeprecateResponse,
  /**
   * @param {!proto.dibric.portal.v1.DeprecateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dibric.portal.v1.DeprecateResponse.deserializeBinary
);


/**
 * @param {!proto.dibric.portal.v1.DeprecateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dibric.portal.v1.DeprecateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dibric.portal.v1.DeprecateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dibric.portal.v1.ExtensionServiceClient.prototype.deprecateVersion =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dibric.portal.v1.ExtensionService/DeprecateVersion',
      request,
      metadata || {},
      methodDescriptor_ExtensionService_DeprecateVersion,
      callback);
};


/**
 * @param {!proto.dibric.portal.v1.DeprecateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dibric.portal.v1.DeprecateResponse>}
 *     Promise that resolves to the response
 */
proto.dibric.portal.v1.ExtensionServicePromiseClient.prototype.deprecateVersion =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dibric.portal.v1.ExtensionService/DeprecateVersion',
      request,
      metadata || {},
      methodDescriptor_ExtensionService_DeprecateVersion);
};


module.exports = proto.dibric.portal.v1;

