import { Modal, Paper, Stack, Typography } from '@mui/material';
import errorIllustration from '../assets/error-illustration.png';
import { useState } from 'react';

type SomethingWrongDialogProps = {
  open: boolean;
  onClose: () => void;
  text: string;
}
let idCounter = 0;

export function SomethingWrongDialog({ open, onClose, text }: SomethingWrongDialogProps) {
  const [id] = useState(() => idCounter++);

  return (<Modal
    open={open}
    onClose={() => onClose()}
    aria-labelledby={`"wrong-info-title-${id}"`}
    aria-describedby={`wrong-info-description-${id}`}>
    <Paper sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      padding: 4,
    }} elevation={20}>
      <Stack flexDirection={'column'} alignItems={'center'} spacing={2}>
        <Typography id={`"wrong-info-title-${id}"`} variant="h5">
          Oops, something&apos;s wrong!
        </Typography>
        <img src={errorIllustration} alt={'Illustration of an error'} style={{ alignSelf: 'stretch' }} />
        <Typography color={'error'} id={`wrong-info-description-${id}`}>
          {text}
        </Typography>
      </Stack>
    </Paper>
  </Modal>);
}
