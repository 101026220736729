/**
 * @fileoverview gRPC-Web generated client stub for dibric.portal.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.19.1
// source: portal/v1/deployments_service.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.dibric = {};
proto.dibric.portal = {};
proto.dibric.portal.v1 = require('./deployments_service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dibric.portal.v1.DeploymentsServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dibric.portal.v1.DeploymentsServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dibric.portal.v1.CreateDeploymentSlotRequest,
 *   !proto.dibric.portal.v1.CreateDeploymentSlotResponse>}
 */
const methodDescriptor_DeploymentsService_CreateDeploymentSlot = new grpc.web.MethodDescriptor(
  '/dibric.portal.v1.DeploymentsService/CreateDeploymentSlot',
  grpc.web.MethodType.UNARY,
  proto.dibric.portal.v1.CreateDeploymentSlotRequest,
  proto.dibric.portal.v1.CreateDeploymentSlotResponse,
  /**
   * @param {!proto.dibric.portal.v1.CreateDeploymentSlotRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dibric.portal.v1.CreateDeploymentSlotResponse.deserializeBinary
);


/**
 * @param {!proto.dibric.portal.v1.CreateDeploymentSlotRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dibric.portal.v1.CreateDeploymentSlotResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dibric.portal.v1.CreateDeploymentSlotResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dibric.portal.v1.DeploymentsServiceClient.prototype.createDeploymentSlot =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dibric.portal.v1.DeploymentsService/CreateDeploymentSlot',
      request,
      metadata || {},
      methodDescriptor_DeploymentsService_CreateDeploymentSlot,
      callback);
};


/**
 * @param {!proto.dibric.portal.v1.CreateDeploymentSlotRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dibric.portal.v1.CreateDeploymentSlotResponse>}
 *     Promise that resolves to the response
 */
proto.dibric.portal.v1.DeploymentsServicePromiseClient.prototype.createDeploymentSlot =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dibric.portal.v1.DeploymentsService/CreateDeploymentSlot',
      request,
      metadata || {},
      methodDescriptor_DeploymentsService_CreateDeploymentSlot);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dibric.portal.v1.ListDeploymentSlotsRequest,
 *   !proto.dibric.portal.v1.ListDeploymentSlotsResponse>}
 */
const methodDescriptor_DeploymentsService_ListDeploymentSlots = new grpc.web.MethodDescriptor(
  '/dibric.portal.v1.DeploymentsService/ListDeploymentSlots',
  grpc.web.MethodType.UNARY,
  proto.dibric.portal.v1.ListDeploymentSlotsRequest,
  proto.dibric.portal.v1.ListDeploymentSlotsResponse,
  /**
   * @param {!proto.dibric.portal.v1.ListDeploymentSlotsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dibric.portal.v1.ListDeploymentSlotsResponse.deserializeBinary
);


/**
 * @param {!proto.dibric.portal.v1.ListDeploymentSlotsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dibric.portal.v1.ListDeploymentSlotsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dibric.portal.v1.ListDeploymentSlotsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dibric.portal.v1.DeploymentsServiceClient.prototype.listDeploymentSlots =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dibric.portal.v1.DeploymentsService/ListDeploymentSlots',
      request,
      metadata || {},
      methodDescriptor_DeploymentsService_ListDeploymentSlots,
      callback);
};


/**
 * @param {!proto.dibric.portal.v1.ListDeploymentSlotsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dibric.portal.v1.ListDeploymentSlotsResponse>}
 *     Promise that resolves to the response
 */
proto.dibric.portal.v1.DeploymentsServicePromiseClient.prototype.listDeploymentSlots =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dibric.portal.v1.DeploymentsService/ListDeploymentSlots',
      request,
      metadata || {},
      methodDescriptor_DeploymentsService_ListDeploymentSlots);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dibric.portal.v1.GetDeploymentSlotRequest,
 *   !proto.dibric.portal.v1.GetDeploymentSlotResponse>}
 */
const methodDescriptor_DeploymentsService_GetDeploymentSlot = new grpc.web.MethodDescriptor(
  '/dibric.portal.v1.DeploymentsService/GetDeploymentSlot',
  grpc.web.MethodType.UNARY,
  proto.dibric.portal.v1.GetDeploymentSlotRequest,
  proto.dibric.portal.v1.GetDeploymentSlotResponse,
  /**
   * @param {!proto.dibric.portal.v1.GetDeploymentSlotRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dibric.portal.v1.GetDeploymentSlotResponse.deserializeBinary
);


/**
 * @param {!proto.dibric.portal.v1.GetDeploymentSlotRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dibric.portal.v1.GetDeploymentSlotResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dibric.portal.v1.GetDeploymentSlotResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dibric.portal.v1.DeploymentsServiceClient.prototype.getDeploymentSlot =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dibric.portal.v1.DeploymentsService/GetDeploymentSlot',
      request,
      metadata || {},
      methodDescriptor_DeploymentsService_GetDeploymentSlot,
      callback);
};


/**
 * @param {!proto.dibric.portal.v1.GetDeploymentSlotRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dibric.portal.v1.GetDeploymentSlotResponse>}
 *     Promise that resolves to the response
 */
proto.dibric.portal.v1.DeploymentsServicePromiseClient.prototype.getDeploymentSlot =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dibric.portal.v1.DeploymentsService/GetDeploymentSlot',
      request,
      metadata || {},
      methodDescriptor_DeploymentsService_GetDeploymentSlot);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dibric.portal.v1.AttachSnapshotRequest,
 *   !proto.dibric.portal.v1.AttachSnapshotResponse>}
 */
const methodDescriptor_DeploymentsService_AttachSnapshot = new grpc.web.MethodDescriptor(
  '/dibric.portal.v1.DeploymentsService/AttachSnapshot',
  grpc.web.MethodType.UNARY,
  proto.dibric.portal.v1.AttachSnapshotRequest,
  proto.dibric.portal.v1.AttachSnapshotResponse,
  /**
   * @param {!proto.dibric.portal.v1.AttachSnapshotRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dibric.portal.v1.AttachSnapshotResponse.deserializeBinary
);


/**
 * @param {!proto.dibric.portal.v1.AttachSnapshotRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dibric.portal.v1.AttachSnapshotResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dibric.portal.v1.AttachSnapshotResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dibric.portal.v1.DeploymentsServiceClient.prototype.attachSnapshot =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dibric.portal.v1.DeploymentsService/AttachSnapshot',
      request,
      metadata || {},
      methodDescriptor_DeploymentsService_AttachSnapshot,
      callback);
};


/**
 * @param {!proto.dibric.portal.v1.AttachSnapshotRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dibric.portal.v1.AttachSnapshotResponse>}
 *     Promise that resolves to the response
 */
proto.dibric.portal.v1.DeploymentsServicePromiseClient.prototype.attachSnapshot =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dibric.portal.v1.DeploymentsService/AttachSnapshot',
      request,
      metadata || {},
      methodDescriptor_DeploymentsService_AttachSnapshot);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dibric.portal.v1.CreateSnapshotRequest,
 *   !proto.dibric.portal.v1.CreateSnapshotResponse>}
 */
const methodDescriptor_DeploymentsService_CreateSnapshot = new grpc.web.MethodDescriptor(
  '/dibric.portal.v1.DeploymentsService/CreateSnapshot',
  grpc.web.MethodType.UNARY,
  proto.dibric.portal.v1.CreateSnapshotRequest,
  proto.dibric.portal.v1.CreateSnapshotResponse,
  /**
   * @param {!proto.dibric.portal.v1.CreateSnapshotRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dibric.portal.v1.CreateSnapshotResponse.deserializeBinary
);


/**
 * @param {!proto.dibric.portal.v1.CreateSnapshotRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dibric.portal.v1.CreateSnapshotResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dibric.portal.v1.CreateSnapshotResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dibric.portal.v1.DeploymentsServiceClient.prototype.createSnapshot =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dibric.portal.v1.DeploymentsService/CreateSnapshot',
      request,
      metadata || {},
      methodDescriptor_DeploymentsService_CreateSnapshot,
      callback);
};


/**
 * @param {!proto.dibric.portal.v1.CreateSnapshotRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dibric.portal.v1.CreateSnapshotResponse>}
 *     Promise that resolves to the response
 */
proto.dibric.portal.v1.DeploymentsServicePromiseClient.prototype.createSnapshot =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dibric.portal.v1.DeploymentsService/CreateSnapshot',
      request,
      metadata || {},
      methodDescriptor_DeploymentsService_CreateSnapshot);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dibric.portal.v1.ListSnapshotsRequest,
 *   !proto.dibric.portal.v1.ListSnapshotsResponse>}
 */
const methodDescriptor_DeploymentsService_ListSnapshots = new grpc.web.MethodDescriptor(
  '/dibric.portal.v1.DeploymentsService/ListSnapshots',
  grpc.web.MethodType.UNARY,
  proto.dibric.portal.v1.ListSnapshotsRequest,
  proto.dibric.portal.v1.ListSnapshotsResponse,
  /**
   * @param {!proto.dibric.portal.v1.ListSnapshotsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dibric.portal.v1.ListSnapshotsResponse.deserializeBinary
);


/**
 * @param {!proto.dibric.portal.v1.ListSnapshotsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dibric.portal.v1.ListSnapshotsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dibric.portal.v1.ListSnapshotsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dibric.portal.v1.DeploymentsServiceClient.prototype.listSnapshots =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dibric.portal.v1.DeploymentsService/ListSnapshots',
      request,
      metadata || {},
      methodDescriptor_DeploymentsService_ListSnapshots,
      callback);
};


/**
 * @param {!proto.dibric.portal.v1.ListSnapshotsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dibric.portal.v1.ListSnapshotsResponse>}
 *     Promise that resolves to the response
 */
proto.dibric.portal.v1.DeploymentsServicePromiseClient.prototype.listSnapshots =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dibric.portal.v1.DeploymentsService/ListSnapshots',
      request,
      metadata || {},
      methodDescriptor_DeploymentsService_ListSnapshots);
};


module.exports = proto.dibric.portal.v1;

