import {Box} from '@mui/material';

type ExtensionLogoProps = {
    logo: string;
}

export function ExtensionLogo({logo}: ExtensionLogoProps) {
  return (
    <Box sx={{
      position: 'relative',
      textAlign: 'center',
    }}>
      <img src={logo} style={{maxWidth: 40, maxHeight: 40}}
        alt={'Extension logotype'}/>
    </Box>);
}
