import { ListItemIcon, ListItemText, MenuItem, MenuList, Paper } from '@mui/material';
import { AddNode } from '../../services/project';
import { ExtensionLogo } from './TasksDrawer';
import { getTaskFlowIcon, TaskDropData } from './utils';

export type TaskDropMenuProps = {
  x: number;
  y: number;
  tasks: TaskDropData[];
  onClick: (item: TaskDropData) => void;
}

export function TaskDropMenu({ x, y, tasks, onClick }: TaskDropMenuProps) {
  return (
    <Paper sx={{
      maxWidth: 320,
      width: '100%',
      position: 'absolute',
      zIndex: 1250, // TODO: Use the theme instead
      top: y - 20,
      left: x - 160,
    }}>
      <MenuList>
        {tasks.map((item, index) => (
          <MenuItem key={index} onClick={() => onClick(item)}>
            <ListItemIcon>
              {item.type === AddNode.NodeType.EXTENSIONTASK ? (
                <ExtensionLogo logo={item.logo as string} />) :
                getTaskFlowIcon(item.type)}
            </ListItemIcon>
            <ListItemText>{item.name}</ListItemText>
          </MenuItem>
        ))}
      </MenuList>
    </Paper>
  );
}
