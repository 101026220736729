import { Save } from '@mui/icons-material';
import { Button, CircularProgress, Snackbar, Stack, styled, Tooltip, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { dividerWidth } from '../../../drawer/ResizableDrawer';
import {
  getInputConfig,
  GetInputConfigRequest,
  NodeHandle,
  postInputConfig,
  PostInputConfigRequest,
  Variable
} from '../../../services/project';
import { HandleEditor } from './HandleEditor';

type InputSettingsProps = {
  workspaceId: string;
  itemId: string;
  nodeId: string;
  onCancel: () => void;
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void;
}

const FixedBottomToolbar = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  width: `calc(100% - ${dividerWidth}px)`,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  backgroundColor: theme.palette.background.default,
  borderTop: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(1),
  gap: theme.spacing(1),
}));

function isEqualVariable(a: Variable, b: Variable): boolean {
  return a.getAccepting()?.getType() === b.getAccepting()?.getType()
    && a.getDescription() === b.getDescription()
    && a.getId() == b.getId()
    && a.getLabel() === b.getLabel()
    && a.getOptional() === b.getOptional();
}

function isEqualNodeHandle(a: NodeHandle, b: NodeHandle): boolean {
  const sameProperties = a.getId() === b.getId()
    && a.getDescription() === b.getDescription()
    && a.getLabel() === b.getLabel();
  if (!sameProperties) {
    return false;
  }
  // Note: Input nodes can only have output handles, so we don't have to check for input variables
  const aVariables = a.getOutputVariables()?.getVariablesList();
  const bVariables = b.getOutputVariables()?.getVariablesList();
  if (aVariables === undefined || bVariables === undefined) {
    return aVariables === bVariables;
  }
  return aVariables.length === bVariables.length
    && aVariables.every((variable, index) => isEqualVariable(variable, bVariables[index]));
}

export function InputSettings({
  itemId,
  nodeId,
  workspaceId,
  onCancel,
  setHasUnsavedChanges,
}: InputSettingsProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [handles, setHandles] = useState<NodeHandle[]>([]);
  const [unsavedHandles, setUnsavedHandles] = useState<NodeHandle[] | null>(null);

  const hasHandleChanges = useMemo(
    () => unsavedHandles !== null
      && (
        handles.length !== unsavedHandles.length
        || handles.some((handle, index) => !isEqualNodeHandle(handle, unsavedHandles[index]))
      ),
    [handles, unsavedHandles]
  );
  const [savedMessage, setSavedMessage] = useState<string | null>(null);
  useEffect(
    () => setHasUnsavedChanges(hasHandleChanges),
    [hasHandleChanges]
  );

  useEffect(() => {
    let subscribed = true;

    async function getConfig() {
      try {
        setLoading(true);
        const response = await getInputConfig(
          new GetInputConfigRequest().setNodeId(nodeId)
            .setWorkspaceId(workspaceId)
            .setItemId(itemId));
        if (subscribed) {
          setHandles(response.getHandlesList());
          setUnsavedHandles(response.getHandlesList());
        }
      } finally {
        setLoading(false);
      }
    }

    getConfig();

    return () => { subscribed = false; };
  }, [itemId, nodeId, workspaceId]);

  function handlesChange(newHandles: NodeHandle[]) {
    setHandles(newHandles);
    setUnsavedHandles(previous => previous ?? handles);
  }

  async function handleSave() {
    try {
      setLoading(true);
      const response = await postInputConfig(new PostInputConfigRequest()
        .setItemId(itemId)
        .setNodeId(nodeId)
        .setWorkspaceId(workspaceId)
        .setHandlesList(handles));
      setHandles(response.getHandlesList());
      setUnsavedHandles(response.getHandlesList());
      setSavedMessage('Updates saved');
      setHasUnsavedChanges(false);
    } catch {
      const response = await getInputConfig(
        new GetInputConfigRequest().setNodeId(nodeId)
          .setWorkspaceId(workspaceId)
          .setItemId(itemId));
      setHandles(response.getHandlesList());
      setUnsavedHandles(response.getHandlesList());
    } finally {
      setLoading(false);
    }
  }

  return (
    <Stack sx={{ height: '100%', overflowY: 'hidden' }}>
      <Stack sx={{ px: 2, pb: 4, overflowY: 'auto', maxHeight: 'calc(100% - 54px)' }}>
        <HandleEditor
          handles={handles}
          onChange={handlesChange}
        />
        {loading && (
          <Stack
            alignItems="center"
          >
            <CircularProgress
              color="primary"
              size={24}
              aria-label="Loading configuration"
            />
          </Stack>
        )}
      </Stack>
      <FixedBottomToolbar>
        {hasHandleChanges && (
          <Typography variant="caption" color="textSecondary">
            You have unsaved changes
          </Typography>
        )}
        <Button
          onClick={onCancel}
          color="inherit"
          variant="text"
          type="button"
        >Cancel</Button>
        <Tooltip title={hasHandleChanges ? 'Save all unsaved changes' : 'No changes to save'}>
          <div>
            <Button
              onClick={handleSave}
              startIcon={<Save />}
              disabled={!hasHandleChanges}
              variant="contained"
              color="primary"
            >Save</Button>
          </div>
        </Tooltip>
      </FixedBottomToolbar>
      <Snackbar
        open={savedMessage !== null}
        autoHideDuration={3000}
        onClose={() => setSavedMessage(null)}
        message={savedMessage}
      />
    </Stack>
  );
}
