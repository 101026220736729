import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { Resource } from '@opentelemetry/resources';
import { BatchSpanProcessor, WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { SEMRESATTRS_SERVICE_NAME } from '@opentelemetry/semantic-conventions';
import React, { useEffect } from 'react';
import { getConfiguration } from '../services/client-app';

export function TelemetryWrapper({ children }: React.PropsWithChildren) {
  useEffect(() => {
    let subscribed = true;

    async function fetchConfiguration() {
      try {
        const result = await getConfiguration();
        if (!subscribed) {
          return;
        }

        const provider = new WebTracerProvider({
          resource: new Resource({
            [SEMRESATTRS_SERVICE_NAME]: 'frontend',
          }),
        });

        provider.addSpanProcessor(new BatchSpanProcessor(new OTLPTraceExporter({
          url: result.getOpenTelemetryCollectorUrl(),
        })));

        provider.register({ contextManager: new ZoneContextManager() });

        registerInstrumentations({
          tracerProvider: provider,
          instrumentations: [getWebAutoInstrumentations()],
        });
      } catch (err: unknown) {
        console.error(err);
      }
    }
    fetchConfiguration();

    return () => { subscribed = false; };
  }, []);

  return (
    <>{children}</>
  );
}