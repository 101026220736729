import CloseIcon from '@mui/icons-material/Close';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { Box, Drawer, IconButton, Stack, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { GetExtensionProjectUrlRequest } from '../../protos/portal/v1/project_service_pb';
import { getExtensionProjectUrl } from '../../services/project';
import { ExtensionIdentifier } from './utils';

type ConfigDrawerProps = {
  open: boolean;
  workspaceId: string;
  itemId: string;
  extension: ExtensionIdentifier | undefined;
  onClose: () => void;
}
const minDrawerWidth = 300;
const dividerWidth = 13;

export function ProjectConfigDrawer({ onClose, open, workspaceId, itemId, extension }: ConfigDrawerProps) {
  const theme = useTheme();
  const [drawerWidth, setDrawerWidth] = useState<number>(minDrawerWidth + 50);
  const [url, setUrl] = useState<string>('');

  useEffect(() => {
    function handleWindowResize() {
      setDrawerWidth(Math.min(document.body.clientWidth, Math.max(minDrawerWidth, drawerWidth)));
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [drawerWidth]);

  useEffect(() => {
    async function fetch() {
      if (!extension) {
        return;
      }

      const response = await getExtensionProjectUrl(new GetExtensionProjectUrlRequest()
        .setExtensionId(extension.id)
        .setExtensionVersion(extension.version)
        .setWorkspaceId(workspaceId)
        .setItemId(itemId));
      setUrl(response.getUrl());
    }

    fetch();
  }, [extension, itemId, workspaceId]);

  const handlePointerDown = useCallback(
    (event: React.PointerEvent<HTMLDivElement>) => {
      event.currentTarget.setPointerCapture(event.pointerId);
      event.currentTarget.addEventListener('pointermove', handlePointerMove, true);
    },
    []
  );

  const handlePointerUp = useCallback(
    (event: React.PointerEvent<HTMLDivElement>) => {
      event.currentTarget.releasePointerCapture(event.pointerId);
      event.currentTarget.removeEventListener('pointermove', handlePointerMove, true);
    },
    []
  );

  const handlePointerMove = useCallback(
    (e: PointerEvent) => {
      setDrawerWidth((previous: number) => previous - e.movementX);
    },
    []
  );

  function handleUnfoldClick() {
    setDrawerWidth(document.body.clientWidth);
  }

  function handleMinimizeClick() {
    setDrawerWidth(minDrawerWidth);
  }

  return (
    <Drawer
      open={open}
      anchor={'right'}
      elevation={20}
      variant={'temporary'}
    >
      <Box
        sx={{
          width: dividerWidth,
          cursor: 'ew-resize',
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          zIndex: theme.zIndex.drawer + 1,
          backgroundColor: theme.palette.divider,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onPointerDown={handlePointerDown}
        onPointerUp={handlePointerUp}
      >
        <DragIndicatorIcon sx={{ color: 'text.secondary' }} />
      </Box>
      <Stack sx={{ height: '100%', minWidth: minDrawerWidth, width: drawerWidth, ml: `${dividerWidth}px` }}>
        <Stack direction={'row'} alignItems={'center'}>
          <IconButton onClick={handleUnfoldClick}>
            <UnfoldMoreIcon style={{ transform: 'rotate(90deg)' }} />
          </IconButton>
          <IconButton onClick={handleMinimizeClick}>
            <UnfoldLessIcon style={{ transform: 'rotate(90deg)' }} />
          </IconButton>
          <div style={{ flex: 1 }} />
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <iframe title="Extension configuration" style={{ width: '100%', height: '100%', border: 0 }} src={url} />
      </Stack>
    </Drawer>
  );
}