// source: portal/v1/editor_messages.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var portal_v1_workspace_service_pb = require('../../portal/v1/workspace_service_pb.js');
goog.object.extend(proto, portal_v1_workspace_service_pb);
var graph_v1_public_messages_pb = require('../../graph/v1/public_messages_pb.js');
goog.object.extend(proto, graph_v1_public_messages_pb);
goog.exportSymbol('proto.dibric.portal.v1.AddEdges', null, global);
goog.exportSymbol('proto.dibric.portal.v1.AddNode', null, global);
goog.exportSymbol('proto.dibric.portal.v1.AddNode.NodeType', null, global);
goog.exportSymbol('proto.dibric.portal.v1.AddNodes', null, global);
goog.exportSymbol('proto.dibric.portal.v1.AffectedItems', null, global);
goog.exportSymbol('proto.dibric.portal.v1.ItemDescription', null, global);
goog.exportSymbol('proto.dibric.portal.v1.ItemRemovedMessage', null, global);
goog.exportSymbol('proto.dibric.portal.v1.ModifyPosition', null, global);
goog.exportSymbol('proto.dibric.portal.v1.ModifyPositions', null, global);
goog.exportSymbol('proto.dibric.portal.v1.PatchAppliedMessage', null, global);
goog.exportSymbol('proto.dibric.portal.v1.PatchError', null, global);
goog.exportSymbol('proto.dibric.portal.v1.PatchError.ErrorCode', null, global);
goog.exportSymbol('proto.dibric.portal.v1.RemoveElements', null, global);
goog.exportSymbol('proto.dibric.portal.v1.ServerMessage', null, global);
goog.exportSymbol('proto.dibric.portal.v1.ServerMessage.MessageCase', null, global);
goog.exportSymbol('proto.dibric.portal.v1.WorkspaceRemovedMessage', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.ServerMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.dibric.portal.v1.ServerMessage.oneofGroups_);
};
goog.inherits(proto.dibric.portal.v1.ServerMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.ServerMessage.displayName = 'proto.dibric.portal.v1.ServerMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.ItemRemovedMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.ItemRemovedMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.ItemRemovedMessage.displayName = 'proto.dibric.portal.v1.ItemRemovedMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.WorkspaceRemovedMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.WorkspaceRemovedMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.WorkspaceRemovedMessage.displayName = 'proto.dibric.portal.v1.WorkspaceRemovedMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.PatchAppliedMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dibric.portal.v1.PatchAppliedMessage.repeatedFields_, null);
};
goog.inherits(proto.dibric.portal.v1.PatchAppliedMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.PatchAppliedMessage.displayName = 'proto.dibric.portal.v1.PatchAppliedMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.AffectedItems = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dibric.portal.v1.AffectedItems.repeatedFields_, null);
};
goog.inherits(proto.dibric.portal.v1.AffectedItems, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.AffectedItems.displayName = 'proto.dibric.portal.v1.AffectedItems';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.PatchError = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.PatchError, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.PatchError.displayName = 'proto.dibric.portal.v1.PatchError';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.ItemDescription = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.ItemDescription, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.ItemDescription.displayName = 'proto.dibric.portal.v1.ItemDescription';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.AddEdges = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dibric.portal.v1.AddEdges.repeatedFields_, null);
};
goog.inherits(proto.dibric.portal.v1.AddEdges, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.AddEdges.displayName = 'proto.dibric.portal.v1.AddEdges';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.RemoveElements = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dibric.portal.v1.RemoveElements.repeatedFields_, null);
};
goog.inherits(proto.dibric.portal.v1.RemoveElements, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.RemoveElements.displayName = 'proto.dibric.portal.v1.RemoveElements';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.ModifyPositions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dibric.portal.v1.ModifyPositions.repeatedFields_, null);
};
goog.inherits(proto.dibric.portal.v1.ModifyPositions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.ModifyPositions.displayName = 'proto.dibric.portal.v1.ModifyPositions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.ModifyPosition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.ModifyPosition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.ModifyPosition.displayName = 'proto.dibric.portal.v1.ModifyPosition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.AddNodes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dibric.portal.v1.AddNodes.repeatedFields_, null);
};
goog.inherits(proto.dibric.portal.v1.AddNodes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.AddNodes.displayName = 'proto.dibric.portal.v1.AddNodes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.AddNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.AddNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.AddNode.displayName = 'proto.dibric.portal.v1.AddNode';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.dibric.portal.v1.ServerMessage.oneofGroups_ = [[2,3,4]];

/**
 * @enum {number}
 */
proto.dibric.portal.v1.ServerMessage.MessageCase = {
  MESSAGE_NOT_SET: 0,
  PATCH: 2,
  ITEM_REMOVED: 3,
  WORKSPACE_REMOVED: 4
};

/**
 * @return {proto.dibric.portal.v1.ServerMessage.MessageCase}
 */
proto.dibric.portal.v1.ServerMessage.prototype.getMessageCase = function() {
  return /** @type {proto.dibric.portal.v1.ServerMessage.MessageCase} */(jspb.Message.computeOneofCase(this, proto.dibric.portal.v1.ServerMessage.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.ServerMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.ServerMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.ServerMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.ServerMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    patch: (f = msg.getPatch()) && proto.dibric.portal.v1.PatchAppliedMessage.toObject(includeInstance, f),
    itemRemoved: (f = msg.getItemRemoved()) && proto.dibric.portal.v1.ItemRemovedMessage.toObject(includeInstance, f),
    workspaceRemoved: (f = msg.getWorkspaceRemoved()) && proto.dibric.portal.v1.WorkspaceRemovedMessage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.ServerMessage}
 */
proto.dibric.portal.v1.ServerMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.ServerMessage;
  return proto.dibric.portal.v1.ServerMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.ServerMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.ServerMessage}
 */
proto.dibric.portal.v1.ServerMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.dibric.portal.v1.PatchAppliedMessage;
      reader.readMessage(value,proto.dibric.portal.v1.PatchAppliedMessage.deserializeBinaryFromReader);
      msg.setPatch(value);
      break;
    case 3:
      var value = new proto.dibric.portal.v1.ItemRemovedMessage;
      reader.readMessage(value,proto.dibric.portal.v1.ItemRemovedMessage.deserializeBinaryFromReader);
      msg.setItemRemoved(value);
      break;
    case 4:
      var value = new proto.dibric.portal.v1.WorkspaceRemovedMessage;
      reader.readMessage(value,proto.dibric.portal.v1.WorkspaceRemovedMessage.deserializeBinaryFromReader);
      msg.setWorkspaceRemoved(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.ServerMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.ServerMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.ServerMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.ServerMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPatch();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.dibric.portal.v1.PatchAppliedMessage.serializeBinaryToWriter
    );
  }
  f = message.getItemRemoved();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.dibric.portal.v1.ItemRemovedMessage.serializeBinaryToWriter
    );
  }
  f = message.getWorkspaceRemoved();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.dibric.portal.v1.WorkspaceRemovedMessage.serializeBinaryToWriter
    );
  }
};


/**
 * optional PatchAppliedMessage patch = 2;
 * @return {?proto.dibric.portal.v1.PatchAppliedMessage}
 */
proto.dibric.portal.v1.ServerMessage.prototype.getPatch = function() {
  return /** @type{?proto.dibric.portal.v1.PatchAppliedMessage} */ (
    jspb.Message.getWrapperField(this, proto.dibric.portal.v1.PatchAppliedMessage, 2));
};


/**
 * @param {?proto.dibric.portal.v1.PatchAppliedMessage|undefined} value
 * @return {!proto.dibric.portal.v1.ServerMessage} returns this
*/
proto.dibric.portal.v1.ServerMessage.prototype.setPatch = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.dibric.portal.v1.ServerMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.ServerMessage} returns this
 */
proto.dibric.portal.v1.ServerMessage.prototype.clearPatch = function() {
  return this.setPatch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.ServerMessage.prototype.hasPatch = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ItemRemovedMessage item_removed = 3;
 * @return {?proto.dibric.portal.v1.ItemRemovedMessage}
 */
proto.dibric.portal.v1.ServerMessage.prototype.getItemRemoved = function() {
  return /** @type{?proto.dibric.portal.v1.ItemRemovedMessage} */ (
    jspb.Message.getWrapperField(this, proto.dibric.portal.v1.ItemRemovedMessage, 3));
};


/**
 * @param {?proto.dibric.portal.v1.ItemRemovedMessage|undefined} value
 * @return {!proto.dibric.portal.v1.ServerMessage} returns this
*/
proto.dibric.portal.v1.ServerMessage.prototype.setItemRemoved = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.dibric.portal.v1.ServerMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.ServerMessage} returns this
 */
proto.dibric.portal.v1.ServerMessage.prototype.clearItemRemoved = function() {
  return this.setItemRemoved(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.ServerMessage.prototype.hasItemRemoved = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional WorkspaceRemovedMessage workspace_removed = 4;
 * @return {?proto.dibric.portal.v1.WorkspaceRemovedMessage}
 */
proto.dibric.portal.v1.ServerMessage.prototype.getWorkspaceRemoved = function() {
  return /** @type{?proto.dibric.portal.v1.WorkspaceRemovedMessage} */ (
    jspb.Message.getWrapperField(this, proto.dibric.portal.v1.WorkspaceRemovedMessage, 4));
};


/**
 * @param {?proto.dibric.portal.v1.WorkspaceRemovedMessage|undefined} value
 * @return {!proto.dibric.portal.v1.ServerMessage} returns this
*/
proto.dibric.portal.v1.ServerMessage.prototype.setWorkspaceRemoved = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.dibric.portal.v1.ServerMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.ServerMessage} returns this
 */
proto.dibric.portal.v1.ServerMessage.prototype.clearWorkspaceRemoved = function() {
  return this.setWorkspaceRemoved(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.ServerMessage.prototype.hasWorkspaceRemoved = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.ItemRemovedMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.ItemRemovedMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.ItemRemovedMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.ItemRemovedMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.ItemRemovedMessage}
 */
proto.dibric.portal.v1.ItemRemovedMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.ItemRemovedMessage;
  return proto.dibric.portal.v1.ItemRemovedMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.ItemRemovedMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.ItemRemovedMessage}
 */
proto.dibric.portal.v1.ItemRemovedMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.ItemRemovedMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.ItemRemovedMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.ItemRemovedMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.ItemRemovedMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.dibric.portal.v1.ItemRemovedMessage.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.ItemRemovedMessage} returns this
 */
proto.dibric.portal.v1.ItemRemovedMessage.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.WorkspaceRemovedMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.WorkspaceRemovedMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.WorkspaceRemovedMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.WorkspaceRemovedMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.WorkspaceRemovedMessage}
 */
proto.dibric.portal.v1.WorkspaceRemovedMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.WorkspaceRemovedMessage;
  return proto.dibric.portal.v1.WorkspaceRemovedMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.WorkspaceRemovedMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.WorkspaceRemovedMessage}
 */
proto.dibric.portal.v1.WorkspaceRemovedMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.WorkspaceRemovedMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.WorkspaceRemovedMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.WorkspaceRemovedMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.WorkspaceRemovedMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.dibric.portal.v1.WorkspaceRemovedMessage.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.WorkspaceRemovedMessage} returns this
 */
proto.dibric.portal.v1.WorkspaceRemovedMessage.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dibric.portal.v1.PatchAppliedMessage.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.PatchAppliedMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.PatchAppliedMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.PatchAppliedMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.PatchAppliedMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    etag: jspb.Message.getFieldWithDefault(msg, 2, ""),
    previousEtag: jspb.Message.getFieldWithDefault(msg, 3, ""),
    affectedItemsList: jspb.Message.toObjectList(msg.getAffectedItemsList(),
    proto.dibric.portal.v1.AffectedItems.toObject, includeInstance),
    itemDescription: (f = msg.getItemDescription()) && proto.dibric.portal.v1.ItemDescription.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.dibric.portal.v1.PatchError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.PatchAppliedMessage}
 */
proto.dibric.portal.v1.PatchAppliedMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.PatchAppliedMessage;
  return proto.dibric.portal.v1.PatchAppliedMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.PatchAppliedMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.PatchAppliedMessage}
 */
proto.dibric.portal.v1.PatchAppliedMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEtag(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreviousEtag(value);
      break;
    case 4:
      var value = new proto.dibric.portal.v1.AffectedItems;
      reader.readMessage(value,proto.dibric.portal.v1.AffectedItems.deserializeBinaryFromReader);
      msg.addAffectedItems(value);
      break;
    case 5:
      var value = new proto.dibric.portal.v1.ItemDescription;
      reader.readMessage(value,proto.dibric.portal.v1.ItemDescription.deserializeBinaryFromReader);
      msg.setItemDescription(value);
      break;
    case 6:
      var value = new proto.dibric.portal.v1.PatchError;
      reader.readMessage(value,proto.dibric.portal.v1.PatchError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.PatchAppliedMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.PatchAppliedMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.PatchAppliedMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.PatchAppliedMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEtag();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPreviousEtag();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAffectedItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.dibric.portal.v1.AffectedItems.serializeBinaryToWriter
    );
  }
  f = message.getItemDescription();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.dibric.portal.v1.ItemDescription.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.dibric.portal.v1.PatchError.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.dibric.portal.v1.PatchAppliedMessage.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.PatchAppliedMessage} returns this
 */
proto.dibric.portal.v1.PatchAppliedMessage.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string etag = 2;
 * @return {string}
 */
proto.dibric.portal.v1.PatchAppliedMessage.prototype.getEtag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.PatchAppliedMessage} returns this
 */
proto.dibric.portal.v1.PatchAppliedMessage.prototype.setEtag = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string previous_etag = 3;
 * @return {string}
 */
proto.dibric.portal.v1.PatchAppliedMessage.prototype.getPreviousEtag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.PatchAppliedMessage} returns this
 */
proto.dibric.portal.v1.PatchAppliedMessage.prototype.setPreviousEtag = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated AffectedItems affected_items = 4;
 * @return {!Array<!proto.dibric.portal.v1.AffectedItems>}
 */
proto.dibric.portal.v1.PatchAppliedMessage.prototype.getAffectedItemsList = function() {
  return /** @type{!Array<!proto.dibric.portal.v1.AffectedItems>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dibric.portal.v1.AffectedItems, 4));
};


/**
 * @param {!Array<!proto.dibric.portal.v1.AffectedItems>} value
 * @return {!proto.dibric.portal.v1.PatchAppliedMessage} returns this
*/
proto.dibric.portal.v1.PatchAppliedMessage.prototype.setAffectedItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.dibric.portal.v1.AffectedItems=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dibric.portal.v1.AffectedItems}
 */
proto.dibric.portal.v1.PatchAppliedMessage.prototype.addAffectedItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.dibric.portal.v1.AffectedItems, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dibric.portal.v1.PatchAppliedMessage} returns this
 */
proto.dibric.portal.v1.PatchAppliedMessage.prototype.clearAffectedItemsList = function() {
  return this.setAffectedItemsList([]);
};


/**
 * optional ItemDescription item_description = 5;
 * @return {?proto.dibric.portal.v1.ItemDescription}
 */
proto.dibric.portal.v1.PatchAppliedMessage.prototype.getItemDescription = function() {
  return /** @type{?proto.dibric.portal.v1.ItemDescription} */ (
    jspb.Message.getWrapperField(this, proto.dibric.portal.v1.ItemDescription, 5));
};


/**
 * @param {?proto.dibric.portal.v1.ItemDescription|undefined} value
 * @return {!proto.dibric.portal.v1.PatchAppliedMessage} returns this
*/
proto.dibric.portal.v1.PatchAppliedMessage.prototype.setItemDescription = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.PatchAppliedMessage} returns this
 */
proto.dibric.portal.v1.PatchAppliedMessage.prototype.clearItemDescription = function() {
  return this.setItemDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.PatchAppliedMessage.prototype.hasItemDescription = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional PatchError error = 6;
 * @return {?proto.dibric.portal.v1.PatchError}
 */
proto.dibric.portal.v1.PatchAppliedMessage.prototype.getError = function() {
  return /** @type{?proto.dibric.portal.v1.PatchError} */ (
    jspb.Message.getWrapperField(this, proto.dibric.portal.v1.PatchError, 6));
};


/**
 * @param {?proto.dibric.portal.v1.PatchError|undefined} value
 * @return {!proto.dibric.portal.v1.PatchAppliedMessage} returns this
*/
proto.dibric.portal.v1.PatchAppliedMessage.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.PatchAppliedMessage} returns this
 */
proto.dibric.portal.v1.PatchAppliedMessage.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.PatchAppliedMessage.prototype.hasError = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dibric.portal.v1.AffectedItems.repeatedFields_ = [1,2,3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.AffectedItems.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.AffectedItems.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.AffectedItems} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.AffectedItems.toObject = function(includeInstance, msg) {
  var f, obj = {
    removedNodesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    removedEdgesList: jspb.Message.toObjectList(msg.getRemovedEdgesList(),
    graph_v1_public_messages_pb.Edge.toObject, includeInstance),
    modifiedNodesList: jspb.Message.toObjectList(msg.getModifiedNodesList(),
    portal_v1_workspace_service_pb.Node.toObject, includeInstance),
    addedNodesList: jspb.Message.toObjectList(msg.getAddedNodesList(),
    portal_v1_workspace_service_pb.Node.toObject, includeInstance),
    addedEdgesList: jspb.Message.toObjectList(msg.getAddedEdgesList(),
    graph_v1_public_messages_pb.Edge.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.AffectedItems}
 */
proto.dibric.portal.v1.AffectedItems.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.AffectedItems;
  return proto.dibric.portal.v1.AffectedItems.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.AffectedItems} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.AffectedItems}
 */
proto.dibric.portal.v1.AffectedItems.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addRemovedNodes(value);
      break;
    case 2:
      var value = new graph_v1_public_messages_pb.Edge;
      reader.readMessage(value,graph_v1_public_messages_pb.Edge.deserializeBinaryFromReader);
      msg.addRemovedEdges(value);
      break;
    case 3:
      var value = new portal_v1_workspace_service_pb.Node;
      reader.readMessage(value,portal_v1_workspace_service_pb.Node.deserializeBinaryFromReader);
      msg.addModifiedNodes(value);
      break;
    case 4:
      var value = new portal_v1_workspace_service_pb.Node;
      reader.readMessage(value,portal_v1_workspace_service_pb.Node.deserializeBinaryFromReader);
      msg.addAddedNodes(value);
      break;
    case 5:
      var value = new graph_v1_public_messages_pb.Edge;
      reader.readMessage(value,graph_v1_public_messages_pb.Edge.deserializeBinaryFromReader);
      msg.addAddedEdges(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.AffectedItems.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.AffectedItems.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.AffectedItems} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.AffectedItems.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRemovedNodesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getRemovedEdgesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      graph_v1_public_messages_pb.Edge.serializeBinaryToWriter
    );
  }
  f = message.getModifiedNodesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      portal_v1_workspace_service_pb.Node.serializeBinaryToWriter
    );
  }
  f = message.getAddedNodesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      portal_v1_workspace_service_pb.Node.serializeBinaryToWriter
    );
  }
  f = message.getAddedEdgesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      graph_v1_public_messages_pb.Edge.serializeBinaryToWriter
    );
  }
};


/**
 * repeated string removed_nodes = 1;
 * @return {!Array<string>}
 */
proto.dibric.portal.v1.AffectedItems.prototype.getRemovedNodesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.dibric.portal.v1.AffectedItems} returns this
 */
proto.dibric.portal.v1.AffectedItems.prototype.setRemovedNodesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.dibric.portal.v1.AffectedItems} returns this
 */
proto.dibric.portal.v1.AffectedItems.prototype.addRemovedNodes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dibric.portal.v1.AffectedItems} returns this
 */
proto.dibric.portal.v1.AffectedItems.prototype.clearRemovedNodesList = function() {
  return this.setRemovedNodesList([]);
};


/**
 * repeated dibric.graph.v1.Edge removed_edges = 2;
 * @return {!Array<!proto.dibric.graph.v1.Edge>}
 */
proto.dibric.portal.v1.AffectedItems.prototype.getRemovedEdgesList = function() {
  return /** @type{!Array<!proto.dibric.graph.v1.Edge>} */ (
    jspb.Message.getRepeatedWrapperField(this, graph_v1_public_messages_pb.Edge, 2));
};


/**
 * @param {!Array<!proto.dibric.graph.v1.Edge>} value
 * @return {!proto.dibric.portal.v1.AffectedItems} returns this
*/
proto.dibric.portal.v1.AffectedItems.prototype.setRemovedEdgesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.dibric.graph.v1.Edge=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dibric.graph.v1.Edge}
 */
proto.dibric.portal.v1.AffectedItems.prototype.addRemovedEdges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.dibric.graph.v1.Edge, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dibric.portal.v1.AffectedItems} returns this
 */
proto.dibric.portal.v1.AffectedItems.prototype.clearRemovedEdgesList = function() {
  return this.setRemovedEdgesList([]);
};


/**
 * repeated Node modified_nodes = 3;
 * @return {!Array<!proto.dibric.portal.v1.Node>}
 */
proto.dibric.portal.v1.AffectedItems.prototype.getModifiedNodesList = function() {
  return /** @type{!Array<!proto.dibric.portal.v1.Node>} */ (
    jspb.Message.getRepeatedWrapperField(this, portal_v1_workspace_service_pb.Node, 3));
};


/**
 * @param {!Array<!proto.dibric.portal.v1.Node>} value
 * @return {!proto.dibric.portal.v1.AffectedItems} returns this
*/
proto.dibric.portal.v1.AffectedItems.prototype.setModifiedNodesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.dibric.portal.v1.Node=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dibric.portal.v1.Node}
 */
proto.dibric.portal.v1.AffectedItems.prototype.addModifiedNodes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.dibric.portal.v1.Node, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dibric.portal.v1.AffectedItems} returns this
 */
proto.dibric.portal.v1.AffectedItems.prototype.clearModifiedNodesList = function() {
  return this.setModifiedNodesList([]);
};


/**
 * repeated Node added_nodes = 4;
 * @return {!Array<!proto.dibric.portal.v1.Node>}
 */
proto.dibric.portal.v1.AffectedItems.prototype.getAddedNodesList = function() {
  return /** @type{!Array<!proto.dibric.portal.v1.Node>} */ (
    jspb.Message.getRepeatedWrapperField(this, portal_v1_workspace_service_pb.Node, 4));
};


/**
 * @param {!Array<!proto.dibric.portal.v1.Node>} value
 * @return {!proto.dibric.portal.v1.AffectedItems} returns this
*/
proto.dibric.portal.v1.AffectedItems.prototype.setAddedNodesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.dibric.portal.v1.Node=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dibric.portal.v1.Node}
 */
proto.dibric.portal.v1.AffectedItems.prototype.addAddedNodes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.dibric.portal.v1.Node, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dibric.portal.v1.AffectedItems} returns this
 */
proto.dibric.portal.v1.AffectedItems.prototype.clearAddedNodesList = function() {
  return this.setAddedNodesList([]);
};


/**
 * repeated dibric.graph.v1.Edge added_edges = 5;
 * @return {!Array<!proto.dibric.graph.v1.Edge>}
 */
proto.dibric.portal.v1.AffectedItems.prototype.getAddedEdgesList = function() {
  return /** @type{!Array<!proto.dibric.graph.v1.Edge>} */ (
    jspb.Message.getRepeatedWrapperField(this, graph_v1_public_messages_pb.Edge, 5));
};


/**
 * @param {!Array<!proto.dibric.graph.v1.Edge>} value
 * @return {!proto.dibric.portal.v1.AffectedItems} returns this
*/
proto.dibric.portal.v1.AffectedItems.prototype.setAddedEdgesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.dibric.graph.v1.Edge=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dibric.graph.v1.Edge}
 */
proto.dibric.portal.v1.AffectedItems.prototype.addAddedEdges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.dibric.graph.v1.Edge, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dibric.portal.v1.AffectedItems} returns this
 */
proto.dibric.portal.v1.AffectedItems.prototype.clearAddedEdgesList = function() {
  return this.setAddedEdgesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.PatchError.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.PatchError.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.PatchError} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.PatchError.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.PatchError}
 */
proto.dibric.portal.v1.PatchError.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.PatchError;
  return proto.dibric.portal.v1.PatchError.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.PatchError} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.PatchError}
 */
proto.dibric.portal.v1.PatchError.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.dibric.portal.v1.PatchError.ErrorCode} */ (reader.readEnum());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.PatchError.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.PatchError.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.PatchError} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.PatchError.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.dibric.portal.v1.PatchError.ErrorCode = {
  UNKNOWN: 0,
  INVALID_ETAG: 1,
  INVALID_REQUEST: 2,
  NOT_FOUND: 3,
  EXTENSION_NOT_FOUND: 4
};

/**
 * optional ErrorCode code = 1;
 * @return {!proto.dibric.portal.v1.PatchError.ErrorCode}
 */
proto.dibric.portal.v1.PatchError.prototype.getCode = function() {
  return /** @type {!proto.dibric.portal.v1.PatchError.ErrorCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.dibric.portal.v1.PatchError.ErrorCode} value
 * @return {!proto.dibric.portal.v1.PatchError} returns this
 */
proto.dibric.portal.v1.PatchError.prototype.setCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.ItemDescription.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.ItemDescription.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.ItemDescription} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.ItemDescription.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.ItemDescription}
 */
proto.dibric.portal.v1.ItemDescription.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.ItemDescription;
  return proto.dibric.portal.v1.ItemDescription.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.ItemDescription} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.ItemDescription}
 */
proto.dibric.portal.v1.ItemDescription.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.ItemDescription.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.ItemDescription.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.ItemDescription} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.ItemDescription.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.dibric.portal.v1.ItemDescription.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.ItemDescription} returns this
 */
proto.dibric.portal.v1.ItemDescription.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.dibric.portal.v1.ItemDescription.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.ItemDescription} returns this
 */
proto.dibric.portal.v1.ItemDescription.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dibric.portal.v1.AddEdges.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.AddEdges.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.AddEdges.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.AddEdges} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.AddEdges.toObject = function(includeInstance, msg) {
  var f, obj = {
    edgesList: jspb.Message.toObjectList(msg.getEdgesList(),
    graph_v1_public_messages_pb.Edge.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.AddEdges}
 */
proto.dibric.portal.v1.AddEdges.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.AddEdges;
  return proto.dibric.portal.v1.AddEdges.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.AddEdges} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.AddEdges}
 */
proto.dibric.portal.v1.AddEdges.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new graph_v1_public_messages_pb.Edge;
      reader.readMessage(value,graph_v1_public_messages_pb.Edge.deserializeBinaryFromReader);
      msg.addEdges(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.AddEdges.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.AddEdges.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.AddEdges} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.AddEdges.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEdgesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      graph_v1_public_messages_pb.Edge.serializeBinaryToWriter
    );
  }
};


/**
 * repeated dibric.graph.v1.Edge edges = 1;
 * @return {!Array<!proto.dibric.graph.v1.Edge>}
 */
proto.dibric.portal.v1.AddEdges.prototype.getEdgesList = function() {
  return /** @type{!Array<!proto.dibric.graph.v1.Edge>} */ (
    jspb.Message.getRepeatedWrapperField(this, graph_v1_public_messages_pb.Edge, 1));
};


/**
 * @param {!Array<!proto.dibric.graph.v1.Edge>} value
 * @return {!proto.dibric.portal.v1.AddEdges} returns this
*/
proto.dibric.portal.v1.AddEdges.prototype.setEdgesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.dibric.graph.v1.Edge=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dibric.graph.v1.Edge}
 */
proto.dibric.portal.v1.AddEdges.prototype.addEdges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.dibric.graph.v1.Edge, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dibric.portal.v1.AddEdges} returns this
 */
proto.dibric.portal.v1.AddEdges.prototype.clearEdgesList = function() {
  return this.setEdgesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dibric.portal.v1.RemoveElements.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.RemoveElements.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.RemoveElements.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.RemoveElements} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.RemoveElements.toObject = function(includeInstance, msg) {
  var f, obj = {
    nodesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    edgesList: jspb.Message.toObjectList(msg.getEdgesList(),
    graph_v1_public_messages_pb.Edge.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.RemoveElements}
 */
proto.dibric.portal.v1.RemoveElements.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.RemoveElements;
  return proto.dibric.portal.v1.RemoveElements.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.RemoveElements} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.RemoveElements}
 */
proto.dibric.portal.v1.RemoveElements.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addNodes(value);
      break;
    case 2:
      var value = new graph_v1_public_messages_pb.Edge;
      reader.readMessage(value,graph_v1_public_messages_pb.Edge.deserializeBinaryFromReader);
      msg.addEdges(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.RemoveElements.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.RemoveElements.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.RemoveElements} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.RemoveElements.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNodesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getEdgesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      graph_v1_public_messages_pb.Edge.serializeBinaryToWriter
    );
  }
};


/**
 * repeated string nodes = 1;
 * @return {!Array<string>}
 */
proto.dibric.portal.v1.RemoveElements.prototype.getNodesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.dibric.portal.v1.RemoveElements} returns this
 */
proto.dibric.portal.v1.RemoveElements.prototype.setNodesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.dibric.portal.v1.RemoveElements} returns this
 */
proto.dibric.portal.v1.RemoveElements.prototype.addNodes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dibric.portal.v1.RemoveElements} returns this
 */
proto.dibric.portal.v1.RemoveElements.prototype.clearNodesList = function() {
  return this.setNodesList([]);
};


/**
 * repeated dibric.graph.v1.Edge edges = 2;
 * @return {!Array<!proto.dibric.graph.v1.Edge>}
 */
proto.dibric.portal.v1.RemoveElements.prototype.getEdgesList = function() {
  return /** @type{!Array<!proto.dibric.graph.v1.Edge>} */ (
    jspb.Message.getRepeatedWrapperField(this, graph_v1_public_messages_pb.Edge, 2));
};


/**
 * @param {!Array<!proto.dibric.graph.v1.Edge>} value
 * @return {!proto.dibric.portal.v1.RemoveElements} returns this
*/
proto.dibric.portal.v1.RemoveElements.prototype.setEdgesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.dibric.graph.v1.Edge=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dibric.graph.v1.Edge}
 */
proto.dibric.portal.v1.RemoveElements.prototype.addEdges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.dibric.graph.v1.Edge, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dibric.portal.v1.RemoveElements} returns this
 */
proto.dibric.portal.v1.RemoveElements.prototype.clearEdgesList = function() {
  return this.setEdgesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dibric.portal.v1.ModifyPositions.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.ModifyPositions.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.ModifyPositions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.ModifyPositions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.ModifyPositions.toObject = function(includeInstance, msg) {
  var f, obj = {
    positionsList: jspb.Message.toObjectList(msg.getPositionsList(),
    proto.dibric.portal.v1.ModifyPosition.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.ModifyPositions}
 */
proto.dibric.portal.v1.ModifyPositions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.ModifyPositions;
  return proto.dibric.portal.v1.ModifyPositions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.ModifyPositions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.ModifyPositions}
 */
proto.dibric.portal.v1.ModifyPositions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dibric.portal.v1.ModifyPosition;
      reader.readMessage(value,proto.dibric.portal.v1.ModifyPosition.deserializeBinaryFromReader);
      msg.addPositions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.ModifyPositions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.ModifyPositions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.ModifyPositions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.ModifyPositions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPositionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.dibric.portal.v1.ModifyPosition.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ModifyPosition positions = 1;
 * @return {!Array<!proto.dibric.portal.v1.ModifyPosition>}
 */
proto.dibric.portal.v1.ModifyPositions.prototype.getPositionsList = function() {
  return /** @type{!Array<!proto.dibric.portal.v1.ModifyPosition>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dibric.portal.v1.ModifyPosition, 1));
};


/**
 * @param {!Array<!proto.dibric.portal.v1.ModifyPosition>} value
 * @return {!proto.dibric.portal.v1.ModifyPositions} returns this
*/
proto.dibric.portal.v1.ModifyPositions.prototype.setPositionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.dibric.portal.v1.ModifyPosition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dibric.portal.v1.ModifyPosition}
 */
proto.dibric.portal.v1.ModifyPositions.prototype.addPositions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.dibric.portal.v1.ModifyPosition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dibric.portal.v1.ModifyPositions} returns this
 */
proto.dibric.portal.v1.ModifyPositions.prototype.clearPositionsList = function() {
  return this.setPositionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.ModifyPosition.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.ModifyPosition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.ModifyPosition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.ModifyPosition.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    position: (f = msg.getPosition()) && graph_v1_public_messages_pb.NodePosition.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.ModifyPosition}
 */
proto.dibric.portal.v1.ModifyPosition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.ModifyPosition;
  return proto.dibric.portal.v1.ModifyPosition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.ModifyPosition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.ModifyPosition}
 */
proto.dibric.portal.v1.ModifyPosition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new graph_v1_public_messages_pb.NodePosition;
      reader.readMessage(value,graph_v1_public_messages_pb.NodePosition.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.ModifyPosition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.ModifyPosition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.ModifyPosition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.ModifyPosition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      graph_v1_public_messages_pb.NodePosition.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.dibric.portal.v1.ModifyPosition.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.ModifyPosition} returns this
 */
proto.dibric.portal.v1.ModifyPosition.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional dibric.graph.v1.NodePosition position = 2;
 * @return {?proto.dibric.graph.v1.NodePosition}
 */
proto.dibric.portal.v1.ModifyPosition.prototype.getPosition = function() {
  return /** @type{?proto.dibric.graph.v1.NodePosition} */ (
    jspb.Message.getWrapperField(this, graph_v1_public_messages_pb.NodePosition, 2));
};


/**
 * @param {?proto.dibric.graph.v1.NodePosition|undefined} value
 * @return {!proto.dibric.portal.v1.ModifyPosition} returns this
*/
proto.dibric.portal.v1.ModifyPosition.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.ModifyPosition} returns this
 */
proto.dibric.portal.v1.ModifyPosition.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.ModifyPosition.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dibric.portal.v1.AddNodes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.AddNodes.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.AddNodes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.AddNodes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.AddNodes.toObject = function(includeInstance, msg) {
  var f, obj = {
    nodesList: jspb.Message.toObjectList(msg.getNodesList(),
    proto.dibric.portal.v1.AddNode.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.AddNodes}
 */
proto.dibric.portal.v1.AddNodes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.AddNodes;
  return proto.dibric.portal.v1.AddNodes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.AddNodes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.AddNodes}
 */
proto.dibric.portal.v1.AddNodes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dibric.portal.v1.AddNode;
      reader.readMessage(value,proto.dibric.portal.v1.AddNode.deserializeBinaryFromReader);
      msg.addNodes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.AddNodes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.AddNodes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.AddNodes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.AddNodes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNodesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.dibric.portal.v1.AddNode.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AddNode nodes = 1;
 * @return {!Array<!proto.dibric.portal.v1.AddNode>}
 */
proto.dibric.portal.v1.AddNodes.prototype.getNodesList = function() {
  return /** @type{!Array<!proto.dibric.portal.v1.AddNode>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dibric.portal.v1.AddNode, 1));
};


/**
 * @param {!Array<!proto.dibric.portal.v1.AddNode>} value
 * @return {!proto.dibric.portal.v1.AddNodes} returns this
*/
proto.dibric.portal.v1.AddNodes.prototype.setNodesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.dibric.portal.v1.AddNode=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dibric.portal.v1.AddNode}
 */
proto.dibric.portal.v1.AddNodes.prototype.addNodes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.dibric.portal.v1.AddNode, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dibric.portal.v1.AddNodes} returns this
 */
proto.dibric.portal.v1.AddNodes.prototype.clearNodesList = function() {
  return this.setNodesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.AddNode.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.AddNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.AddNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.AddNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    position: (f = msg.getPosition()) && graph_v1_public_messages_pb.NodePosition.toObject(includeInstance, f),
    sourcePosition: jspb.Message.getFieldWithDefault(msg, 3, 0),
    targetPosition: jspb.Message.getFieldWithDefault(msg, 4, 0),
    name: jspb.Message.getFieldWithDefault(msg, 5, ""),
    description: jspb.Message.getFieldWithDefault(msg, 6, ""),
    taskId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    extensionId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    extensionVersion: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.AddNode}
 */
proto.dibric.portal.v1.AddNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.AddNode;
  return proto.dibric.portal.v1.AddNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.AddNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.AddNode}
 */
proto.dibric.portal.v1.AddNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.dibric.portal.v1.AddNode.NodeType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = new graph_v1_public_messages_pb.NodePosition;
      reader.readMessage(value,graph_v1_public_messages_pb.NodePosition.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    case 3:
      var value = /** @type {!proto.dibric.graph.v1.NodeHandlePosition} */ (reader.readEnum());
      msg.setSourcePosition(value);
      break;
    case 4:
      var value = /** @type {!proto.dibric.graph.v1.NodeHandlePosition} */ (reader.readEnum());
      msg.setTargetPosition(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setExtensionId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setExtensionVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.AddNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.AddNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.AddNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.AddNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      graph_v1_public_messages_pb.NodePosition.serializeBinaryToWriter
    );
  }
  f = message.getSourcePosition();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getTargetPosition();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTaskId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getExtensionId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getExtensionVersion();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.dibric.portal.v1.AddNode.NodeType = {
  UNKNOWN: 0,
  EXTENSIONTASK: 1,
  INPUTTASK: 2
};

/**
 * optional NodeType type = 1;
 * @return {!proto.dibric.portal.v1.AddNode.NodeType}
 */
proto.dibric.portal.v1.AddNode.prototype.getType = function() {
  return /** @type {!proto.dibric.portal.v1.AddNode.NodeType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.dibric.portal.v1.AddNode.NodeType} value
 * @return {!proto.dibric.portal.v1.AddNode} returns this
 */
proto.dibric.portal.v1.AddNode.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional dibric.graph.v1.NodePosition position = 2;
 * @return {?proto.dibric.graph.v1.NodePosition}
 */
proto.dibric.portal.v1.AddNode.prototype.getPosition = function() {
  return /** @type{?proto.dibric.graph.v1.NodePosition} */ (
    jspb.Message.getWrapperField(this, graph_v1_public_messages_pb.NodePosition, 2));
};


/**
 * @param {?proto.dibric.graph.v1.NodePosition|undefined} value
 * @return {!proto.dibric.portal.v1.AddNode} returns this
*/
proto.dibric.portal.v1.AddNode.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.AddNode} returns this
 */
proto.dibric.portal.v1.AddNode.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.AddNode.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional dibric.graph.v1.NodeHandlePosition source_position = 3;
 * @return {!proto.dibric.graph.v1.NodeHandlePosition}
 */
proto.dibric.portal.v1.AddNode.prototype.getSourcePosition = function() {
  return /** @type {!proto.dibric.graph.v1.NodeHandlePosition} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.dibric.graph.v1.NodeHandlePosition} value
 * @return {!proto.dibric.portal.v1.AddNode} returns this
 */
proto.dibric.portal.v1.AddNode.prototype.setSourcePosition = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional dibric.graph.v1.NodeHandlePosition target_position = 4;
 * @return {!proto.dibric.graph.v1.NodeHandlePosition}
 */
proto.dibric.portal.v1.AddNode.prototype.getTargetPosition = function() {
  return /** @type {!proto.dibric.graph.v1.NodeHandlePosition} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.dibric.graph.v1.NodeHandlePosition} value
 * @return {!proto.dibric.portal.v1.AddNode} returns this
 */
proto.dibric.portal.v1.AddNode.prototype.setTargetPosition = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string name = 5;
 * @return {string}
 */
proto.dibric.portal.v1.AddNode.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.AddNode} returns this
 */
proto.dibric.portal.v1.AddNode.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string description = 6;
 * @return {string}
 */
proto.dibric.portal.v1.AddNode.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.AddNode} returns this
 */
proto.dibric.portal.v1.AddNode.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string task_id = 7;
 * @return {string}
 */
proto.dibric.portal.v1.AddNode.prototype.getTaskId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.AddNode} returns this
 */
proto.dibric.portal.v1.AddNode.prototype.setTaskId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string extension_id = 8;
 * @return {string}
 */
proto.dibric.portal.v1.AddNode.prototype.getExtensionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.AddNode} returns this
 */
proto.dibric.portal.v1.AddNode.prototype.setExtensionId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string extension_version = 9;
 * @return {string}
 */
proto.dibric.portal.v1.AddNode.prototype.getExtensionVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.AddNode} returns this
 */
proto.dibric.portal.v1.AddNode.prototype.setExtensionVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


goog.object.extend(exports, proto.dibric.portal.v1);
