import ConstructionIcon from '@mui/icons-material/Construction';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SettingsIcon from '@mui/icons-material/Settings';
import { Breadcrumbs, Button, CircularProgress, LinearProgress, Snackbar, Stack, Typography } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useEdgesState, useNodesState } from 'react-flow-renderer';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Breadcrumb } from '../../breadcrumbs/Breadcrumb';
import { ApplyPatchRequest } from '../../protos/portal/v1/project_service_pb';
import { ExtensionTask } from '../../protos/portal/v1/workspace_service_pb';
import {
  ItemRemovedMessage,
  PatchAppliedMessage,
  ProjectSocket,
  WorkspaceRemovedMessage,
  applyPatch as projectApplyPatch
} from '../../services/project';
import {
  GetItemRequest,
  Node,
  WorkspaceItem,
  getItem
} from '../../services/workspace';
import { WorkspaceContext } from '../WorkspaceWrapper';
import { Editor } from './Editor';
import { EditorTabs } from './EditorTabs';
import { GlobalConfig } from './GlobalConfig';
import { NodeConfigDrawer } from './NodeConfigDrawer';
import { ProjectConfigDrawer } from './ProjectConfigDrawer';
import { TasksDrawer } from './TasksDrawer';
import { ExtensionIdentifier, NodeConfigData, applyPatch, convertToFlow } from './utils';
import ErrorStatus = Node.ErrorStatus;

export function EditorContainer() {
  const { workspaceId, itemId, nodeId } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [nodes, setNodes, onNodeChanges] = useNodesState([]);
  const [edges, setEdges, onEdgeChanges] = useEdgesState([]);

  const [project, setProject] = useState<WorkspaceItem>(new WorkspaceItem());
  const [socket, setSocket] = useState<ProjectSocket | null>(null);
  const [connecting, setConnecting] = useState<boolean>(true);
  const [isLoadingProject, setIsLoadingProject] = useState<boolean>(false);
  const [patchQueue, setPatchQueue] = useState<ApplyPatchRequest[]>([]);
  const [nodeConfig, setNodeConfig] = useState<NodeConfigData>({ type: Node.TaskCase.TASK_NOT_SET, nodeId: '' });
  const [disableStartNode, setDisableStartNode] = useState<boolean>(false);

  const [projectConfig, setProjectConfig] = useState<ExtensionIdentifier | undefined>(undefined);
  const [snapshotCreated, setSnapshotCreated] = useState(false);

  // Initializes the socket and fetches the workspace
  useEffect(
    () => {
      if (!workspaceId) {
        return;
      }
      let subscribed = true;

      const socket = new ProjectSocket(workspaceId);
      setSocket(socket);

      socket.onopen(() => {
        if (subscribed) {
          setConnecting(false);
        }
      });
      socket.onclose(() => {
        if (subscribed) {
          setConnecting(true);
        }
      });

      return () => {
        socket.dispose();
        setSocket(null);
        subscribed = false;
      };
    },
    [workspaceId]
  );

  useEffect(
    () => {
      let subscribed = true;

      async function fetch() {
        if (!workspaceId) {
          return;
        }
        if (!itemId) {
          return;
        }
        try {
          setIsLoadingProject(true);
          const response = await getItem(new GetItemRequest().setId(itemId).setWorkspaceId(workspaceId));
          const newProject = response.getItem() as WorkspaceItem;
          if (subscribed) {
            setProject(newProject);
          }
        } finally {
          if (subscribed) {
            setIsLoadingProject(false);
          }
        }
      }

      fetch();

      return () => {
        subscribed = false;
      };
    },
    [itemId, workspaceId]
  );

  // Listen for changes
  useEffect(
    () => {
      async function handlePatchApplied(patch: PatchAppliedMessage) {
        if (!workspaceId) {
          return;
        }

        if (patch.getId() !== project.getId()) {
          return;
        }
        // TODO: There are more error cases that must be handled within apply patch
        console.log('Project etag', project.getEtag(), 'Previous etag', patch.getPreviousEtag(), 'New etag', patch.getEtag());
        const success = applyPatch(project, patch);
        let newProject: WorkspaceItem;
        if (success) {
          console.log('Patch applied');
          newProject = WorkspaceItem.deserializeBinary(project.serializeBinary());
        } else {
          console.log('Patch application failed');
          const response = await getItem(new GetItemRequest().setId(patch.getId()).setWorkspaceId(workspaceId));
          newProject = response.getItem() as WorkspaceItem;
        }
        setProject(newProject);
      }

      function handleItemRemoved(message: ItemRemovedMessage) {
        // TODO: We need to show a dialog
        const id = message.getId();
        if (id === project.getId()) {
          navigate(`/workspaces/${workspaceId}`);
        }
      }

      function handleWorkspaceRemoved(message: WorkspaceRemovedMessage) {
        // TODO: We need to show a dialog
        if (workspaceId === message.getId()) {
          navigate('/workspaces');
        }
      }

      if (socket) {
        socket.onPatchedApplied(handlePatchApplied);
        socket.onItemRemoved(handleItemRemoved);
        socket.onWorkspaceRemoved(handleWorkspaceRemoved);
      }
    },
    [navigate, socket, project, workspaceId]
  );

  // Apply all the patches fetched from the editor
  useEffect(
    () => {
      let subscribed = true;

      // TODO: Merge the patch queue instead of calling several times
      async function updateLoop() {
        if (!workspaceId) {
          return;
        }
        const currentProject = project;
        while (patchQueue.length !== 0 && project.getId()) {
          const request = patchQueue.pop();
          if (request === undefined) {
            break;
          }
          request.setEtag(currentProject.getEtag());
          request.setProjectId(currentProject.getId());
          request.setWorkspaceId(workspaceId);
          await projectApplyPatch(request);
        }
        if (subscribed) {
          setPatchQueue([]);
        }
      }

      if (patchQueue.length !== 0) {
        updateLoop();
      }

      return () => {
        subscribed = false;
      };
    },
    [patchQueue, workspaceId, project, nodeId]
  );

  // This effect will run when the project changes and set the corresponding nodes and edges
  useEffect(
    () => {
      const [newNodes, newEdges] = convertToFlow(
        project.getNodesList(),
        project.getEdgesList(),
        setNodeConfig,
        false);

      setNodes(newNodes);
      setEdges(newEdges);

      const startDisabled = Boolean(project.getNodesList().find(node =>
        node.getTaskCase() === Node.TaskCase.INPUT));
      setDisableStartNode(startDisabled);
    },
    [navigate, project, nodeId, setNodes, setEdges, itemId, workspaceId]
  );

  const { workspace, loading: workspaceIsLoading, exists: workspaceExists } = useContext(WorkspaceContext);

  function handleSettingsClosed() {
    setNodeConfig({
      type: Node.TaskCase.TASK_NOT_SET,
      nodeId: '',
    });
  }

  function handleApplyPatch(request: ApplyPatchRequest) {
    setPatchQueue(queue => [...queue, request]);
  }

  function handleGlobalSettingsClicked(value: ExtensionIdentifier) {
    setProjectConfig(value);
  }

  function handleProjectConfigClose() {
    setProjectConfig(undefined);
  }

  const missingProjectConfigs = project.getNodesList()
    .filter(item => item.getExtensionTask() &&
      (item.getErrorStatusList().indexOf(ErrorStatus.GRAPH_CONFIG_NOT_FOUND) >= 0))
    .map(
      item => {
        const task = item.getExtensionTask() as ExtensionTask;
        return {
          id: task.getExtensionId(),
          version: task.getExtensionVersion(),
        };
      }
    );
  const loading = workspaceIsLoading || isLoadingProject;

  const breadcrumbs = useMemo(
    () => {
      const crumbs = [
        <Breadcrumb to="/workspaces" key="1">workspaces</Breadcrumb>,
      ];
      if (workspaceExists) {
        crumbs.push(
          <Breadcrumb to={`/workspaces/${workspaceId}`} key="2">{workspace.getName()}</Breadcrumb>,
          <Breadcrumb to={`/workspaces/${workspaceId}/items/${itemId}`} key="3">{project.getName()}</Breadcrumb>,
        );
      }
      return crumbs;
    },
    [workspaceExists, workspace, workspaceId, project]
  );

  const tabHeight = 45;
  return (
    <Stack
      sx={(theme) => ({
        height: `calc(100% - ${theme.mixins.toolbar.minHeight ? (+theme.mixins.toolbar.minHeight + 3) : 3}px)`,
        width: '100%',
      })}
      direction="row"
      alignItems="stretch"
    >
      <TasksDrawer
        disableDrag={connecting}
        disableStart={disableStartNode}
      />
      <Stack sx={{ flex: 1 }}>
        <Stack sx={{ height: tabHeight }} spacing={2} direction="row" alignItems="center">
          <EditorTabs value={pathname}
            onValueChanged={value => navigate(value)}
            tabs={[
              {
                label: project.getName(),
                value: `/workspaces/${workspaceId}/items/${itemId}`,
                icon: <ConstructionIcon />,
              },
              {
                label: 'Settings',
                value: `/workspaces/${workspaceId}/items/${itemId}/settings`,
                icon: <SettingsIcon />,
              },
            ]}
            height={tabHeight}
          />
          <Breadcrumbs
            sx={{ ml: 2, mt: 1 }}
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbs}
          </Breadcrumbs>
        </Stack>
        <div
          style={{
            flex: 1,
            zIndex: 2,
            boxShadow: 'rgb(145 158 171 / 20%) 5px -4px 8px -3px, rgb(145 158 171 / 14%) 6px -5px 5px -3px, rgb(145 158 171 / 12%) 9px -2px 6px 0px',
          }}
        >
          <LinearProgress
            color="primary"
            style={{ height: 3, visibility: loading ? 'visible' : 'hidden' }}
            aria-label="Loading project"
          />
          {!pathname.endsWith('settings') && workspaceId && itemId && (
            <Editor
              nodes={nodes}
              edges={edges}
              workspaceId={workspaceId}
              projectId={itemId}
              onApplyPatch={handleApplyPatch}
              onNodeChanges={onNodeChanges}
              onEdgeChanges={onEdgeChanges}
              onSnapshotCreated={() => setSnapshotCreated(true)}
            />
          )}
          {pathname.endsWith('settings') && itemId && workspaceId && (
            <GlobalConfig
              onLoading={setIsLoadingProject}
              itemId={itemId}
              workspaceId={workspaceId}
              missingConfig={missingProjectConfigs}
              onSettings={handleGlobalSettingsClicked}
            />
          )}
        </div>
        <Snackbar
          autoHideDuration={3000}
          open={snapshotCreated}
          onClose={() => setSnapshotCreated(false)}
          message="Snapshot created"
          action={
            <Button
              aria-label="close"
              onClick={() => setSnapshotCreated(false)}
            >
              OK
            </Button>
          }
        />
      </Stack>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        message={(
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            style={{ minWidth: 300 }}
          >
            <Typography sx={{ mr: 2 }}>
              Connecting...
            </Typography>
            <div style={{ flexGrow: 1 }} />
            <CircularProgress
              aria-label="Loading project"
            />
          </Stack>
        )}
        open={connecting}
      />
      {itemId && workspaceId && (
        <>
          <NodeConfigDrawer
            open={Boolean(nodeConfig.nodeId)}
            onClose={handleSettingsClosed}
            type={nodeConfig.type}
            nodeId={nodeConfig.nodeId}
            workspaceId={workspaceId}
            itemId={itemId}
          />
          <ProjectConfigDrawer
            open={Boolean(projectConfig)}
            onClose={handleProjectConfigClose}
            extension={projectConfig}
            workspaceId={workspaceId}
            itemId={itemId}
          />
        </>
      )}
    </Stack>
  );
}