/**
 * @fileoverview gRPC-Web generated client stub for dibric.portal.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.19.1
// source: portal/v1/clientapp_service.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.dibric = {};
proto.dibric.portal = {};
proto.dibric.portal.v1 = require('./clientapp_service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dibric.portal.v1.ClientAppServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dibric.portal.v1.ClientAppServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dibric.portal.v1.ClientAppConfigurationRequest,
 *   !proto.dibric.portal.v1.ClientAppConfigurationResponse>}
 */
const methodDescriptor_ClientAppService_GetConfiguration = new grpc.web.MethodDescriptor(
  '/dibric.portal.v1.ClientAppService/GetConfiguration',
  grpc.web.MethodType.UNARY,
  proto.dibric.portal.v1.ClientAppConfigurationRequest,
  proto.dibric.portal.v1.ClientAppConfigurationResponse,
  /**
   * @param {!proto.dibric.portal.v1.ClientAppConfigurationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dibric.portal.v1.ClientAppConfigurationResponse.deserializeBinary
);


/**
 * @param {!proto.dibric.portal.v1.ClientAppConfigurationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dibric.portal.v1.ClientAppConfigurationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dibric.portal.v1.ClientAppConfigurationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dibric.portal.v1.ClientAppServiceClient.prototype.getConfiguration =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dibric.portal.v1.ClientAppService/GetConfiguration',
      request,
      metadata || {},
      methodDescriptor_ClientAppService_GetConfiguration,
      callback);
};


/**
 * @param {!proto.dibric.portal.v1.ClientAppConfigurationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dibric.portal.v1.ClientAppConfigurationResponse>}
 *     Promise that resolves to the response
 */
proto.dibric.portal.v1.ClientAppServicePromiseClient.prototype.getConfiguration =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dibric.portal.v1.ClientAppService/GetConfiguration',
      request,
      metadata || {},
      methodDescriptor_ClientAppService_GetConfiguration);
};


module.exports = proto.dibric.portal.v1;

