import InputIcon from '@mui/icons-material/Input';
import { CSSProperties } from 'react';
import { Edge, Handle, Node, Position } from 'react-flow-renderer';
import { ExtensionTaskType } from '../../services/extensions';
import {
  AddNode,
  ItemDescription,
  PatchAppliedMessage,
  TypeDescription,
  ValueType,
  Variable
} from '../../services/project';
import {
  Edge as DibricEdge,
  Node as DibricNode,
  ExtensionTask,
  InputTask,
  NodeHandle,
  WorkspaceItem
} from '../../services/workspace';
import { ExtensionNodeData } from './ExtensionNode';
import { InputNodeData } from './InputNode';

export interface ExtensionIdentifier {
  id: string;
  version: string;
}

export function isTypeCompatible(to: TypeDescription | undefined, from: TypeDescription | undefined): boolean {
  if (!to || !from) {
    return true;
  }

  // TODO: We need to handle all the limitations

  return to.getType() === from.getType();
}

export function variableCompatible(to: Variable, from: Variable): boolean {

  // The variables are compatible if any of the descriptions are compatible
  const toDesc = to.getAccepting();
  const fromDesc = from.getAccepting();
  return isTypeCompatible(toDesc, fromDesc);
}

export function typeToString(type: ValueType) {
  switch (type) {
    case ValueType.STRING:
      return 'String';
    case ValueType.DECIMAL:
      return 'Decimal';
    case ValueType.INTEGER:
      return 'Integer';
    case ValueType.BOOLEAN:
      return 'Boolean';
    case ValueType.DATETIME:
      return 'Datetime';
    default:
      throw new Error('Invalid type');
  }
}

export function getTaskFlowIcon(type: AddNode.NodeType) {
  switch (type) {
    case AddNode.NodeType.UNKNOWN:
    case AddNode.NodeType.EXTENSIONTASK:
      throw new Error('No icon is available');
    case AddNode.NodeType.INPUTTASK:
      return <InputIcon />;
  }
}

export interface TaskDropData {
  name: string;
  description: string;
  type: AddNode.NodeType;
  handleId?: string;
  logo?: string;
  extensionType?: ExtensionTaskType;
  extensionId?: string;
  extensionVersion?: string;
  extensionTaskId?: string;
}

export interface NodeDropData {
  name: string;
  tasks: TaskDropData[];
}

export interface NodeConfigData {
  type: DibricNode.TaskCase;
  nodeId: string;
}

type CustomHandleProps = {
  inputHandle?: NodeHandle;
  outputHandle?: NodeHandle;
  targetPosition?: Position;
  sourcePosition?: Position;
  length: number;
  index: number;
  total: number;
  color: string;
  handleSize?: number;
  type: 'source' | 'target';
}

export function CustomHandle({
  inputHandle,
  outputHandle,
  targetPosition,
  sourcePosition,
  length,
  index,
  total,
  color,
  handleSize,
  type,
}: CustomHandleProps) {
  const position = inputHandle ? targetPosition as Position : sourcePosition as Position;
  const handle = (inputHandle ? inputHandle : outputHandle) as NodeHandle;
  handleSize = handleSize ? handleSize : 9;
  const handleOffset = Math.floor(handleSize / 2) + 2;
  const labelOffset = 2;

  const diff = length / (total + 1);
  let handleStyle = {
    height: handleSize,
    width: handleSize,
    backgroundColor: color,
  } as CSSProperties;

  let labelStyle = {
    fontSize: 5,
    color: 'black',
    whiteSpace: 'nowrap',
    position: 'absolute',
  } as CSSProperties;

  switch (position) {
    case Position.Top:
      handleStyle = {
        ...handleStyle,
        top: -handleOffset,
        left: diff * (index + 1),
      };
      labelStyle = {
        ...labelStyle,
        top: labelOffset,
        left: diff * (index + 1),
        transform: 'translate(-50%)',
      };
      break;
    case Position.Right:
      handleStyle = {
        ...handleStyle,
        top: diff * (index + 1),
        right: handleOffset,
      };
      labelStyle = {
        ...labelStyle,
        top: diff * (index + 1),
        right: labelOffset,
      };
      break;
    case Position.Bottom:
      handleStyle = {
        ...handleStyle,
        bottom: -handleOffset,
        left: diff * (index + 1),
      };
      labelStyle = {
        ...labelStyle,
        bottom: labelOffset,
        left: diff * (index + 1),
        transform: 'translate(-50%)',
      };
      break;
    case Position.Left:
      handleStyle = {
        ...handleStyle,
        top: diff * (index + 1),
        left: -handleOffset,
      };
      labelStyle = {
        ...labelStyle,
        top: diff * (index + 1),
        left: labelOffset,
      };
      break;
  }

  return (
    <>
      <Handle
        type={type}
        position={position}
        style={handleStyle}
        id={handle.getId()}
      />
      <div style={labelStyle}>
        {handle.getLabel()}
      </div>
    </>
  );
}

export function rotate(sourcePosition: Position): CSSProperties {
  switch (sourcePosition) {
    case Position.Left:
      return {
        transform: 'rotate(270deg)',
      };
    case Position.Top:
      return {
        transform: 'rotate(0deg)',
      };
    case Position.Right:
      return {
        transform: 'rotate(90deg)',
      };
    case Position.Bottom:
      return {
        transform: 'rotate(180deg)',
      };
  }
}

export function getEdgeId(source: string, target: string, sourceHandle: string, targetHandle: string) {
  return `${source}-${sourceHandle}-${target}-${targetHandle}`;
}

export function convertToFlow(
  nodes: DibricNode[],
  edges: DibricEdge[],
  onSettings: (data: NodeConfigData) => void,
  readonlyRoot: boolean
): [Node[], Edge[]] {
  const resultNodes = nodes.map((item) => {
    switch (item.getTaskCase()) {
      case DibricNode.TaskCase.EXTENSION_TASK: {
        const extensionTask = item.getExtensionTask() as ExtensionTask;
        return {
          id: item.getId(),
          type: 'extensionTask',
          data: {
            logo: item.getLogo(),
            type: extensionTask.getType(),
            navigation: extensionTask.getNavigationRule(),
            errors: item.getErrorStatusList(),
            name: item.getName(),
            description: item.getDescription(),
            onSettings: onSettings,
            outputHandles: extensionTask.getOutputHandlesList(),
            inputHandles: extensionTask.getInputHandlesList(),
            navigationRule: extensionTask.getNavigationRule(),
            isConfigurable: item.getIsConfigurable(),
          },
          position: {
            x: item.getPosition()?.getX(),
            y: item.getPosition()?.getY(),
          },
        } as Node<ExtensionNodeData>;
      }
      case DibricNode.TaskCase.INPUT: {
        const inputTask = item.getInput() as InputTask;
        return {
          id: item.getId(),
          type: 'inputTask',
          data: {
            errors: item.getErrorStatusList(),
            name: item.getName(),
            description: item.getDescription(),
            onSettings: onSettings,
            outputHandles: inputTask.getOutputHandlesList(),
            readonly: readonlyRoot,
          },
          position: {
            x: item.getPosition()?.getX(),
            y: item.getPosition()?.getY(),
          },
        } as Node<InputNodeData>;
      }
      default:
        throw new Error('The node type is not supported or incorrect');
    }
  });

  const resultEdges = edges.map((item) => ({
    source: item.getSource(),
    target: item.getTarget(),
    sourceHandle: item.getSourceHandle(),
    targetHandle: item.getTargetHandle(),
    id: getEdgeId(
      item.getSource(),
      item.getTarget(),
      item.getSourceHandle(),
      item.getTargetHandle()
    ),
  } as Edge));

  return [resultNodes, resultEdges];
}

// TODO: This requires re-thinking
export function applyPatch(project: WorkspaceItem, patch: PatchAppliedMessage): boolean {
  if (project.getEtag() !== patch.getPreviousEtag()) {
    return false;
  }

  if (patch.hasError()) {
    return false;
  }

  project.setEtag(patch.getEtag());

  // Update item description
  const itemDescription = patch.getItemDescription();
  if (itemDescription) {
    const itemDescription = patch.getItemDescription() as ItemDescription;
    project.setName(itemDescription.getName());
    project.setDescription(itemDescription.getDescription());
  }

  // Update affected items on a graph
  for (const affectedItems of patch.getAffectedItemsList()) {
    const removedNodes = affectedItems.getRemovedNodesList();
    const nodes = project.getNodesList().filter(item => !removedNodes.includes(item.getId()));
    project.setNodesList(nodes);

    const removedEdges = affectedItems.getRemovedEdgesList();
    const edges = project.getEdgesList().filter(item =>
      !removedEdges.find(
        edge => (
          edge.getSource() === item.getSource() &&
          edge.getTarget() === item.getTarget() &&
          edge.getSourceHandle() === item.getSourceHandle() &&
          edge.getTargetHandle() === item.getTargetHandle()
        )
      )
    );
    project.setEdgesList(edges);

    const modifiedNodes = affectedItems.getModifiedNodesList();
    for (const node of modifiedNodes) {
      const index = nodes.findIndex(item => item.getId() === node.getId());
      nodes[index] = node;
    }

    project.setNodesList(nodes);

    affectedItems.getAddedNodesList().forEach(item => nodes.push(item));

    project.setNodesList(nodes);

    affectedItems.getAddedEdgesList().forEach(item => edges.push(item));
    project.setEdgesList(edges);
  }

  return true;
}
