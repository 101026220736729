import { forwardRef, ReactElement, Ref, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<string>;
  },
  ref: Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type ConfirmationDialogProps = {
  open: boolean;
  onClose: (accepted: boolean) => void;
  title?: string;
  contentText?: string;
  cancelButtonText?: string;
  cancelButtonColor?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | undefined;
  cancelButtonVariant?: 'text' | 'outlined' | 'contained';
  acceptButtonText?: string;
  acceptButtonColor?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | undefined;
  acceptButtonVariant?: 'text' | 'outlined' | 'contained';
}
let idCounter = 0;

export function ConfirmationDialog({
  open,
  onClose,
  title,
  contentText,
  cancelButtonText,
  cancelButtonColor,
  cancelButtonVariant,
  acceptButtonText,
  acceptButtonColor,
  acceptButtonVariant,
}: ConfirmationDialogProps) {
  const [id] = useState(() => idCounter++);

  if (!cancelButtonText) {
    cancelButtonText = 'Disagree';
  }
  if (!acceptButtonText) {
    acceptButtonText = 'Agree';
  }
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => onClose(false)}
      aria-describedby={`slide-description-${id}`}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id={`slide-description-${id}`}>
          {contentText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus={true}
          onClick={() => onClose(false)}
          variant={cancelButtonVariant}
          color={cancelButtonColor}>{cancelButtonText}</Button>
        <Button onClick={() => onClose(true)}
          variant={acceptButtonVariant}
          color={acceptButtonColor}>{acceptButtonText}</Button>
      </DialogActions>
    </Dialog>
  );
}
