import { useEffect, useState } from 'react';
import { sandboxRules } from '../../../security/sandbox-rules';
import { getExtensionTaskUrl, GetExtensionTaskUrlRequest, GetExtensionTaskUrlResponse } from '../../../services/project';

type ExtensionSettingsProps = {
  workspaceId: string;
  itemId: string;
  nodeId: string;
}

export function ExtensionSettings({ itemId, nodeId, workspaceId }: ExtensionSettingsProps) {
  const [taskUrl, setTaskUrl] = useState<string>();

  useEffect(() => {
    const signal = new AbortController();
    async function fetchData() {
      const result: GetExtensionTaskUrlResponse = await getExtensionTaskUrl(
        new GetExtensionTaskUrlRequest()
          .setItemId(itemId)
          .setNodeId(nodeId)
          .setWorkspaceId(workspaceId)
      );
      const url = result.getUrl();
      if (!signal.signal.aborted) {
        setTaskUrl(url);
      }
    }

    fetchData();
    return () => signal.abort();
  }, [itemId, nodeId, workspaceId]);

  return <>
    {taskUrl && (
      <iframe
        title="Extension Task settings"
        sandbox={sandboxRules.join(' ')}
        style={{ width: '100%', height: '100%', border: 0 }}
        src={taskUrl}
      />
    )}
  </>;
}
