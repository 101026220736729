import { Add, PhotoCamera } from '@mui/icons-material';
import { Box, Button, CircularProgress, FormControl, List, ListItem, ListItemIcon, ListItemText, Stack, TextField, Typography } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import React, { FormEvent, useEffect, useState } from 'react';
import { ResizableDrawer, ResizableDrawerProps } from '../../drawer/ResizableDrawer';
import { CreateSnapshotRequest, ListSnapshotsRequest, Snapshot } from '../../protos/portal/v1/deployments_service_pb';
import { createSnapshot, listSnapshots } from '../../services/deployments';

export interface CreateSnapshotDrawerProps extends ResizableDrawerProps {
  workspaceId: string;
  projectId: string;
  onSnapshotCreated: () => void;
}

export function CreateSnapshotDrawer({
  workspaceId,
  projectId,
  open,
  onSnapshotCreated,
  ...props
}: CreateSnapshotDrawerProps) {
  const [isLoadingSnapshots, setIsLoadingSnapshots] = useState<boolean>(false);
  const [snapshots, setSnapshots] = useState<Snapshot[]>([]);
  const [snapshotName, setSnapshotName] = useState<string>('');

  useEffect(
    () => {
      if (!open) {
        return;
      }

      const controller = new AbortController();
      async function fetch() {
        setIsLoadingSnapshots(true);
        const request = new ListSnapshotsRequest()
          .setFilterWorkspaceId(workspaceId);
        const response = await listSnapshots(request);
        setIsLoadingSnapshots(false);
        if (controller.signal.aborted) {
          return;
        }
        const items = response.getSnapshotsList();
        setSnapshots(items);
      }
      fetch();

      return () => { controller.abort(); };
    },
    [workspaceId, open]
  );
  async function handleCreateSnapshot(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (snapshotName.length === 0) {
      return;
    }
    const request = new CreateSnapshotRequest()
      .setWorkspaceId(workspaceId)
      .setProjectId(projectId)
      .setName(snapshotName);

    await createSnapshot(request);
    onSnapshotCreated();
    setSnapshotName('');
  }

  return (
    <ResizableDrawer open={open} {...props}>
      <Box sx={{ px: 2 }}>
        <Typography variant="h5">
          Create new Snapshot
        </Typography>

        <Box
          component="form"
          sx={{ my: 2 }}
          onSubmit={(e) => handleCreateSnapshot(e)}
        >
          <FormControl sx={{ display: 'flex' }}>
            <TextField
              label="Name"
              variant="outlined"
              helperText="Give the snapshot a name"
              value={snapshotName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSnapshotName(e.target.value)}
            />
          </FormControl>
          <Typography variant="caption">
            When you click create, a copy of this project will be made available to use in a deployment slot.
            Updates in the project are not reflected in the snapshot.
          </Typography>
          <Stack direction="row" justifyContent="flex-end" sx={{ pb: 2 }}>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              startIcon={<Add />}
              disabled={snapshotName.length === 0}
            >
              Create
            </Button>
          </Stack>
        </Box>
        <Typography variant="h6">
          Snapshots
        </Typography>
        <Typography variant="caption">
          When you create snapshots for this workspace they will appear here
        </Typography>
        {isLoadingSnapshots && (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        )}
        {!isLoadingSnapshots && snapshots.length === 0 && (
          <Typography variant="subtitle2">
            <em>No previous snapshots available</em>
          </Typography>
        )}
        {!isLoadingSnapshots && snapshots.length > 0 && (
          <List sx={{ pb: 2 }}>
            {snapshots.map(item => (
              <ListItem key={item.getId()}>
                <ListItemIcon>
                  <PhotoCamera />
                </ListItemIcon>
                <ListItemText
                  primary={item.getName()}
                  secondary={formatDistanceToNow(new Date(item.getCreated() * 1000), { addSuffix: true })}
                />
              </ListItem>
            ))}
          </List>
        )}
      </Box>
    </ResizableDrawer>
  );
}