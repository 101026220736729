import { RpcError } from 'grpc-web';
import { DeploymentsServicePromiseClient } from '../protos/portal/v1/deployments_service_grpc_web_pb';
import {
  AttachSnapshotRequest,
  AttachSnapshotResponse,
  CreateDeploymentSlotRequest,
  CreateDeploymentSlotResponse,
  CreateSnapshotRequest,
  CreateSnapshotResponse,
  GetDeploymentSlotRequest,
  GetDeploymentSlotResponse,
  ListDeploymentSlotsRequest,
  ListDeploymentSlotsResponse,
  ListSnapshotsRequest,
  ListSnapshotsResponse
} from '../protos/portal/v1/deployments_service_pb';
import { getDefaultMetadata, handleUnauthorized } from './utils';

const client = new DeploymentsServicePromiseClient(`${window.location.protocol}//${window.location.host}`);

export async function createDeploymentSlot(request: CreateDeploymentSlotRequest): Promise<CreateDeploymentSlotResponse> {
  try {
    return await client.createDeploymentSlot(request, getDefaultMetadata());
  } catch (error: unknown) {
    if (error instanceof RpcError && handleUnauthorized(error)) {
      console.log('Unauthorized. Redirecting to login....');
    }
    throw error;
  }
}

export async function listDeploymentSlots(request: ListDeploymentSlotsRequest): Promise<ListDeploymentSlotsResponse> {
  try {
    return await client.listDeploymentSlots(request, getDefaultMetadata());
  } catch (error: unknown) {
    if (error instanceof RpcError && handleUnauthorized(error)) {
      console.log('Unauthorized. Redirecting to login....');
    }
    throw error;
  }
}

export async function createSnapshot(request: CreateSnapshotRequest): Promise<CreateSnapshotResponse> {
  try {
    return await client.createSnapshot(request, getDefaultMetadata());
  } catch (error: unknown) {
    if (error instanceof RpcError && handleUnauthorized(error)) {
      console.log('Unauthorized. Redirecting to login....');
    }
    throw error;
  }
}

export async function getDeploymentSlot(request: GetDeploymentSlotRequest): Promise<GetDeploymentSlotResponse> {
  try {
    return await client.getDeploymentSlot(request, getDefaultMetadata());
  } catch (error: unknown) {
    if (error instanceof RpcError && handleUnauthorized(error)) {
      console.log('Unauthorized. Redirecting to login....');
    }
    throw error;
  }
}

export async function attachSnapshot(request: AttachSnapshotRequest): Promise<AttachSnapshotResponse> {
  try {
    return await client.attachSnapshot(request, getDefaultMetadata());
  } catch (error: unknown) {
    if (error instanceof RpcError && handleUnauthorized(error)) {
      console.log('Unauthorized. Redirecting to login....');
    }
    throw error;
  }
}

export async function listSnapshots(request: ListSnapshotsRequest): Promise<ListSnapshotsResponse> {
  try {
    return await client.listSnapshots(request, getDefaultMetadata());
  } catch (error: unknown) {
    if (error instanceof RpcError && handleUnauthorized(error)) {
      console.log('Unauthorized. Redirecting to login....');
    }
    throw error;
  }
}
