import { RpcError } from 'grpc-web';
import { UserServicePromiseClient } from '../protos/portal/v1/user_service_grpc_web_pb';
import { UserRequest, UserResponse } from '../protos/portal/v1/user_service_pb';
import { handleUnauthorized } from './utils';

const userService = new UserServicePromiseClient(`${window.location.protocol}//${window.location.host}`);

export async function getUser(): Promise<UserResponse> {
  try {
    return await userService.get(new UserRequest(), undefined);
  } catch (error: unknown) {
    if (error instanceof RpcError && handleUnauthorized(error)) {
      console.log('Unauthorized. Redirecting to login....');
    }
    throw error;
  }
}

export {
  UserResponse
};
