import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  Badge,
  Box,
  Container,
  Divider,
  IconButton,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material';
import { CSSProperties, useEffect, useState } from 'react';
import contentIllustration from '../../assets/adjustments.png';
import { ExtensionLogo } from '../../extensions/ExtensionLogo';
import { Extension, ListExtensionRequest, VersionSummary, listExtensions } from '../../services/extensions';
import { ExtensionIdentifier } from './utils';

type GlobalConfigProps = {
  onLoading: (value: boolean) => void;
  itemId: string;
  workspaceId: string;
  onSettings: (value: ExtensionIdentifier) => void;
  missingConfig: ExtensionIdentifier[];
}

type OrderBy = ListExtensionRequest.Order;
type Order = 'asc' | 'desc';

export function GlobalConfig({ onLoading, itemId, workspaceId, onSettings, missingConfig }: GlobalConfigProps) {
  const [extensions, setExtensions] = useState<Extension[]>([]);
  const [orderBy, setOrderBy] = useState<OrderBy>(ListExtensionRequest.Order.NAME);
  const [order, setOrder] = useState<Order>('desc');
  const [page, setPage] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(100);

  const visuallyHidden: CSSProperties = {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  };

  useEffect(() => {
    async function fetch() {
      try {
        onLoading(true);

        // Fetch extensions that have a project-wide config
        // TODO: Restrict this to extensions that actually have a project wide config
        const extensionsResponse = await listExtensions(new ListExtensionRequest()
          .setPageNumber(0)
          .setPageSize(rowsPerPage)
          .setOnlyNonEmpty(true));

        setExtensions(extensionsResponse.getItemsList());
        setTotalCount(extensionsResponse.getTotalMatches());
      } finally {
        onLoading(false);
      }
    }

    fetch();
  }, [workspaceId, itemId, onLoading, rowsPerPage]);

  function handleSort(value: ListExtensionRequest.Order) {
    const isAsc = value === orderBy && order === 'asc';
    setOrderBy(value);
    setOrder(isAsc ? 'desc' : 'asc');
  }

  function handlePageSizeChanged(value: number) {
    setRowsPerPage(value);
    setPage(0);
  }

  function handlePageChanged(value: number) {
    setPage(value);
  }

  function configMissing(identifier: ExtensionIdentifier): boolean {
    return Boolean(missingConfig.find(item =>
      item.version === identifier.version &&
      item.id === identifier.id));
  }

  return (
    <>
      <Container style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <Box sx={{ mb: 2 }}>
          <Stack flexDirection={'row'} alignItems={'center'}>
            <img src={contentIllustration} style={{ maxHeight: 200 }} alt="Develop extension illustration" />
            <Stack flexDirection={'column'} alignItems={'flex-start'}>
              <Typography variant={'h4'}>
                Global settings
              </Typography>
              <Typography>
                Or read our tutorial about the different <Link href="#">settings</Link>
              </Typography>
            </Stack>
          </Stack>
        </Box>
        <Divider flexItem />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: 40 }}>
                </TableCell>
                <TableCell
                  sortDirection={orderBy === ListExtensionRequest.Order.NAME ? order : false}>
                  <TableSortLabel
                    active={orderBy === ListExtensionRequest.Order.NAME}
                    direction={orderBy === ListExtensionRequest.Order.NAME ? order : 'asc'}
                    onClick={() => handleSort(ListExtensionRequest.Order.NAME)}>
                    Name
                    {orderBy === ListExtensionRequest.Order.NAME ? (
                      <span style={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </span>)
                      : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ width: 100 }}>
                  Id
                </TableCell>
                <TableCell style={{ width: 70 }}>
                  Version
                </TableCell>
                <TableCell
                  sortDirection={orderBy === ListExtensionRequest.Order.TENANT ? order : false}
                  style={{ width: 70 }}>
                  <TableSortLabel
                    active={orderBy === ListExtensionRequest.Order.TENANT}
                    direction={orderBy === ListExtensionRequest.Order.TENANT ? order : 'asc'}
                    onClick={() => handleSort(ListExtensionRequest.Order.TENANT)}
                  >
                    Owner
                    {orderBy === ListExtensionRequest.Order.TENANT
                      ? (
                        <span style={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </span>
                      )
                      : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ width: 30 }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {extensions.map(extension => {
                const version = extension.getLatestVersion() ?? new VersionSummary();
                const identifier = {
                  id: extension.getId(),
                  version: version.getVersion(),
                };
                return (
                  <TableRow key={`${extension.getIdentifier()}${version.getVersion()}`}>
                    <TableCell>
                      <ExtensionLogo logo={version.getLogo()} />
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">{version.getLabel()}</Typography>
                      <Typography variant="caption">{version.getDescription()}</Typography>
                    </TableCell>
                    <TableCell>
                      {extension.getId()}
                    </TableCell>
                    <TableCell>
                      {version.getVersion()}
                    </TableCell>
                    <TableCell>
                      {extension.getTenantName()}
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => onSettings(identifier)}>
                        <Badge
                          badgeContent={
                            (<PriorityHighIcon sx={{
                              fontSize: 'small',
                              borderRadius: '50%',
                              backgroundColor: 'secondary.main',
                              color: 'secondary.contrastText',
                            }} />)}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          invisible={!configMissing(identifier)}>
                          <SettingsIcon />
                        </Badge>
                      </IconButton>
                    </TableCell>
                  </TableRow>);
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onRowsPerPageChange={(event) => handlePageSizeChanged(+event.target.value)}
          onPageChange={(_, number) => handlePageChanged(number)}
        />
      </Container>
    </>
  );
}
