import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Badge, IconButton, Paper, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { NodeProps, Position, useUpdateNodeInternals } from 'react-flow-renderer';
import { ExtensionTaskType } from '../../services/extensions';
import { NavigationType, Node, NodeHandle } from '../../services/workspace';
import { ExtensionLogo } from './TasksDrawer';
import { CustomHandle, NodeConfigData } from './utils';

export interface ExtensionNodeData {
  logo: string;
  name: string;
  description: string;
  type: ExtensionTaskType;
  navigation: NavigationType;
  onSettings: (data: NodeConfigData) => void;
  errors: Node.ErrorStatus[];
  outputHandles: NodeHandle[];
  inputHandles: NodeHandle[];
  navigationRule: NavigationType;
  isConfigurable: boolean;
}

export function ExtensionNode({
  id,
  data,
  selected,
  dragging,
  sourcePosition,
  targetPosition,
}: NodeProps<ExtensionNodeData>) {
  const theme = useTheme();

  const updateNodeInternals = useUpdateNodeInternals();
  useEffect(() => {
    updateNodeInternals(id);
  }, [id, data.outputHandles.length, data.inputHandles.length, updateNodeInternals]);


  const isHorizontal = sourcePosition === Position.Right || sourcePosition === Position.Left;
  const lengthDimension = 80;
  const heightDimension = 80;
  const height = isHorizontal ? heightDimension : lengthDimension;
  const length = isHorizontal ? lengthDimension : heightDimension;
  const isDeprecated = Boolean(data.errors.find(item => item === Node.ErrorStatus.EXTENSION_DEPRECATED));
  const isDeleted = Boolean(data.errors.find(item => item === Node.ErrorStatus.EXTENSION_DELETED));

  let border = 'none';
  if (isDeleted) {
    border = `2px solid ${theme.palette.secondary.main}50`;
  } else if (isDeprecated) {
    border = `2px solid ${theme.palette.warning.main}50`;
  } else if (selected) {
    border = `1px solid ${theme.palette.primary.light}50`;
  }

  const content = (
    <Badge
      badgeContent={
        (<PriorityHighIcon sx={{
          fontSize: 'small',
          borderRadius: '50%',
          backgroundColor: 'secondary.main',
          color: 'secondary.contrastText',
        }} />)}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }} invisible={data.errors.length === 0 || (data.errors.length === 1 && isDeprecated)}>
      <ExtensionLogo logo={data.logo} />
    </Badge>
  );

  return (
    <Paper
      style={{
        height: isHorizontal ? height : length,
        width: isHorizontal ? length : height,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        border: border,
      }}
      elevation={selected ? 16 : 1}
    >
      {data.isConfigurable && (
        <IconButton
          size={'small'}
          disabled={dragging || isDeleted}
          sx={[isDeleted && { opacity: 0.2 }]}
          onClick={() => data.onSettings({ type: Node.TaskCase.EXTENSION_TASK, nodeId: id })}
        >
          {content}
        </IconButton>
      )}
      {!data.isConfigurable && content}
      {data.inputHandles.map((handle, index) => (
        <CustomHandle
          key={handle.getId()}
          type={'target'}
          inputHandle={handle}
          sourcePosition={sourcePosition}
          targetPosition={targetPosition}
          color={'rgba(0, 0, 0, 0.2)'}
          index={index}
          total={data.inputHandles.length}
          length={length}
        />
      ))}
      {data.outputHandles.map((handle, index) => (
        <CustomHandle
          key={handle.getId()}
          type={'source'}
          outputHandle={handle}
          sourcePosition={sourcePosition}
          targetPosition={targetPosition}
          color={handle.getType() === NodeHandle.Type.SUCCESS ?
            theme.palette.primary.main :
            theme.palette.secondary.main}
          index={index}
          total={data.outputHandles.length}
          length={length}
        />
      ))}
    </Paper>
  );
}
