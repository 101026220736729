/**
 * @fileoverview gRPC-Web generated client stub for dibric.portal.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.19.1
// source: portal/v1/project_service.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var graph_v1_public_messages_pb = require('../../graph/v1/public_messages_pb.js')

var portal_v1_editor_messages_pb = require('../../portal/v1/editor_messages_pb.js')
const proto = {};
proto.dibric = {};
proto.dibric.portal = {};
proto.dibric.portal.v1 = require('./project_service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dibric.portal.v1.ProjectServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dibric.portal.v1.ProjectServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dibric.portal.v1.NegotiateRequest,
 *   !proto.dibric.portal.v1.NegotiateResponse>}
 */
const methodDescriptor_ProjectService_Negotiate = new grpc.web.MethodDescriptor(
  '/dibric.portal.v1.ProjectService/Negotiate',
  grpc.web.MethodType.UNARY,
  proto.dibric.portal.v1.NegotiateRequest,
  proto.dibric.portal.v1.NegotiateResponse,
  /**
   * @param {!proto.dibric.portal.v1.NegotiateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dibric.portal.v1.NegotiateResponse.deserializeBinary
);


/**
 * @param {!proto.dibric.portal.v1.NegotiateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dibric.portal.v1.NegotiateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dibric.portal.v1.NegotiateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dibric.portal.v1.ProjectServiceClient.prototype.negotiate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dibric.portal.v1.ProjectService/Negotiate',
      request,
      metadata || {},
      methodDescriptor_ProjectService_Negotiate,
      callback);
};


/**
 * @param {!proto.dibric.portal.v1.NegotiateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dibric.portal.v1.NegotiateResponse>}
 *     Promise that resolves to the response
 */
proto.dibric.portal.v1.ProjectServicePromiseClient.prototype.negotiate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dibric.portal.v1.ProjectService/Negotiate',
      request,
      metadata || {},
      methodDescriptor_ProjectService_Negotiate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dibric.portal.v1.ApplyPatchRequest,
 *   !proto.dibric.portal.v1.ApplyPatchResponse>}
 */
const methodDescriptor_ProjectService_ApplyPatch = new grpc.web.MethodDescriptor(
  '/dibric.portal.v1.ProjectService/ApplyPatch',
  grpc.web.MethodType.UNARY,
  proto.dibric.portal.v1.ApplyPatchRequest,
  proto.dibric.portal.v1.ApplyPatchResponse,
  /**
   * @param {!proto.dibric.portal.v1.ApplyPatchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dibric.portal.v1.ApplyPatchResponse.deserializeBinary
);


/**
 * @param {!proto.dibric.portal.v1.ApplyPatchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dibric.portal.v1.ApplyPatchResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dibric.portal.v1.ApplyPatchResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dibric.portal.v1.ProjectServiceClient.prototype.applyPatch =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dibric.portal.v1.ProjectService/ApplyPatch',
      request,
      metadata || {},
      methodDescriptor_ProjectService_ApplyPatch,
      callback);
};


/**
 * @param {!proto.dibric.portal.v1.ApplyPatchRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dibric.portal.v1.ApplyPatchResponse>}
 *     Promise that resolves to the response
 */
proto.dibric.portal.v1.ProjectServicePromiseClient.prototype.applyPatch =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dibric.portal.v1.ProjectService/ApplyPatch',
      request,
      metadata || {},
      methodDescriptor_ProjectService_ApplyPatch);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dibric.portal.v1.GetExtensionProjectUrlRequest,
 *   !proto.dibric.portal.v1.GetExtensionProjectUrlResponse>}
 */
const methodDescriptor_ProjectService_GetExtensionProjectUrl = new grpc.web.MethodDescriptor(
  '/dibric.portal.v1.ProjectService/GetExtensionProjectUrl',
  grpc.web.MethodType.UNARY,
  proto.dibric.portal.v1.GetExtensionProjectUrlRequest,
  proto.dibric.portal.v1.GetExtensionProjectUrlResponse,
  /**
   * @param {!proto.dibric.portal.v1.GetExtensionProjectUrlRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dibric.portal.v1.GetExtensionProjectUrlResponse.deserializeBinary
);


/**
 * @param {!proto.dibric.portal.v1.GetExtensionProjectUrlRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dibric.portal.v1.GetExtensionProjectUrlResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dibric.portal.v1.GetExtensionProjectUrlResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dibric.portal.v1.ProjectServiceClient.prototype.getExtensionProjectUrl =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dibric.portal.v1.ProjectService/GetExtensionProjectUrl',
      request,
      metadata || {},
      methodDescriptor_ProjectService_GetExtensionProjectUrl,
      callback);
};


/**
 * @param {!proto.dibric.portal.v1.GetExtensionProjectUrlRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dibric.portal.v1.GetExtensionProjectUrlResponse>}
 *     Promise that resolves to the response
 */
proto.dibric.portal.v1.ProjectServicePromiseClient.prototype.getExtensionProjectUrl =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dibric.portal.v1.ProjectService/GetExtensionProjectUrl',
      request,
      metadata || {},
      methodDescriptor_ProjectService_GetExtensionProjectUrl);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dibric.portal.v1.GetExtensionTaskUrlRequest,
 *   !proto.dibric.portal.v1.GetExtensionTaskUrlResponse>}
 */
const methodDescriptor_ProjectService_GetExtensionTaskUrl = new grpc.web.MethodDescriptor(
  '/dibric.portal.v1.ProjectService/GetExtensionTaskUrl',
  grpc.web.MethodType.UNARY,
  proto.dibric.portal.v1.GetExtensionTaskUrlRequest,
  proto.dibric.portal.v1.GetExtensionTaskUrlResponse,
  /**
   * @param {!proto.dibric.portal.v1.GetExtensionTaskUrlRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dibric.portal.v1.GetExtensionTaskUrlResponse.deserializeBinary
);


/**
 * @param {!proto.dibric.portal.v1.GetExtensionTaskUrlRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dibric.portal.v1.GetExtensionTaskUrlResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dibric.portal.v1.GetExtensionTaskUrlResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dibric.portal.v1.ProjectServiceClient.prototype.getExtensionTaskUrl =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dibric.portal.v1.ProjectService/GetExtensionTaskUrl',
      request,
      metadata || {},
      methodDescriptor_ProjectService_GetExtensionTaskUrl,
      callback);
};


/**
 * @param {!proto.dibric.portal.v1.GetExtensionTaskUrlRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dibric.portal.v1.GetExtensionTaskUrlResponse>}
 *     Promise that resolves to the response
 */
proto.dibric.portal.v1.ProjectServicePromiseClient.prototype.getExtensionTaskUrl =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dibric.portal.v1.ProjectService/GetExtensionTaskUrl',
      request,
      metadata || {},
      methodDescriptor_ProjectService_GetExtensionTaskUrl);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dibric.portal.v1.GetInputConfigRequest,
 *   !proto.dibric.portal.v1.GetInputConfigResponse>}
 */
const methodDescriptor_ProjectService_GetInputConfig = new grpc.web.MethodDescriptor(
  '/dibric.portal.v1.ProjectService/GetInputConfig',
  grpc.web.MethodType.UNARY,
  proto.dibric.portal.v1.GetInputConfigRequest,
  proto.dibric.portal.v1.GetInputConfigResponse,
  /**
   * @param {!proto.dibric.portal.v1.GetInputConfigRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dibric.portal.v1.GetInputConfigResponse.deserializeBinary
);


/**
 * @param {!proto.dibric.portal.v1.GetInputConfigRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dibric.portal.v1.GetInputConfigResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dibric.portal.v1.GetInputConfigResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dibric.portal.v1.ProjectServiceClient.prototype.getInputConfig =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dibric.portal.v1.ProjectService/GetInputConfig',
      request,
      metadata || {},
      methodDescriptor_ProjectService_GetInputConfig,
      callback);
};


/**
 * @param {!proto.dibric.portal.v1.GetInputConfigRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dibric.portal.v1.GetInputConfigResponse>}
 *     Promise that resolves to the response
 */
proto.dibric.portal.v1.ProjectServicePromiseClient.prototype.getInputConfig =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dibric.portal.v1.ProjectService/GetInputConfig',
      request,
      metadata || {},
      methodDescriptor_ProjectService_GetInputConfig);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dibric.portal.v1.PostInputConfigRequest,
 *   !proto.dibric.portal.v1.PostInputConfigResponse>}
 */
const methodDescriptor_ProjectService_PostInputConfig = new grpc.web.MethodDescriptor(
  '/dibric.portal.v1.ProjectService/PostInputConfig',
  grpc.web.MethodType.UNARY,
  proto.dibric.portal.v1.PostInputConfigRequest,
  proto.dibric.portal.v1.PostInputConfigResponse,
  /**
   * @param {!proto.dibric.portal.v1.PostInputConfigRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dibric.portal.v1.PostInputConfigResponse.deserializeBinary
);


/**
 * @param {!proto.dibric.portal.v1.PostInputConfigRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dibric.portal.v1.PostInputConfigResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dibric.portal.v1.PostInputConfigResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dibric.portal.v1.ProjectServiceClient.prototype.postInputConfig =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dibric.portal.v1.ProjectService/PostInputConfig',
      request,
      metadata || {},
      methodDescriptor_ProjectService_PostInputConfig,
      callback);
};


/**
 * @param {!proto.dibric.portal.v1.PostInputConfigRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dibric.portal.v1.PostInputConfigResponse>}
 *     Promise that resolves to the response
 */
proto.dibric.portal.v1.ProjectServicePromiseClient.prototype.postInputConfig =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dibric.portal.v1.ProjectService/PostInputConfig',
      request,
      metadata || {},
      methodDescriptor_ProjectService_PostInputConfig);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dibric.portal.v1.StartDebugExecutionRequest,
 *   !proto.dibric.portal.v1.StartDebugExecutionResponse>}
 */
const methodDescriptor_ProjectService_StartDebugExecution = new grpc.web.MethodDescriptor(
  '/dibric.portal.v1.ProjectService/StartDebugExecution',
  grpc.web.MethodType.UNARY,
  proto.dibric.portal.v1.StartDebugExecutionRequest,
  proto.dibric.portal.v1.StartDebugExecutionResponse,
  /**
   * @param {!proto.dibric.portal.v1.StartDebugExecutionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dibric.portal.v1.StartDebugExecutionResponse.deserializeBinary
);


/**
 * @param {!proto.dibric.portal.v1.StartDebugExecutionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dibric.portal.v1.StartDebugExecutionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dibric.portal.v1.StartDebugExecutionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dibric.portal.v1.ProjectServiceClient.prototype.startDebugExecution =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dibric.portal.v1.ProjectService/StartDebugExecution',
      request,
      metadata || {},
      methodDescriptor_ProjectService_StartDebugExecution,
      callback);
};


/**
 * @param {!proto.dibric.portal.v1.StartDebugExecutionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dibric.portal.v1.StartDebugExecutionResponse>}
 *     Promise that resolves to the response
 */
proto.dibric.portal.v1.ProjectServicePromiseClient.prototype.startDebugExecution =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dibric.portal.v1.ProjectService/StartDebugExecution',
      request,
      metadata || {},
      methodDescriptor_ProjectService_StartDebugExecution);
};


module.exports = proto.dibric.portal.v1;

