// source: portal/v1/project_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var graph_v1_public_messages_pb = require('../../graph/v1/public_messages_pb.js');
goog.object.extend(proto, graph_v1_public_messages_pb);
var portal_v1_editor_messages_pb = require('../../portal/v1/editor_messages_pb.js');
goog.object.extend(proto, portal_v1_editor_messages_pb);
goog.exportSymbol('proto.dibric.portal.v1.ApplyPatchRequest', null, global);
goog.exportSymbol('proto.dibric.portal.v1.ApplyPatchRequest.PatchCase', null, global);
goog.exportSymbol('proto.dibric.portal.v1.ApplyPatchResponse', null, global);
goog.exportSymbol('proto.dibric.portal.v1.Connection', null, global);
goog.exportSymbol('proto.dibric.portal.v1.GetExtensionProjectUrlRequest', null, global);
goog.exportSymbol('proto.dibric.portal.v1.GetExtensionProjectUrlResponse', null, global);
goog.exportSymbol('proto.dibric.portal.v1.GetExtensionTaskUrlRequest', null, global);
goog.exportSymbol('proto.dibric.portal.v1.GetExtensionTaskUrlResponse', null, global);
goog.exportSymbol('proto.dibric.portal.v1.GetInputConfigRequest', null, global);
goog.exportSymbol('proto.dibric.portal.v1.GetInputConfigResponse', null, global);
goog.exportSymbol('proto.dibric.portal.v1.NegotiateRequest', null, global);
goog.exportSymbol('proto.dibric.portal.v1.NegotiateResponse', null, global);
goog.exportSymbol('proto.dibric.portal.v1.NodeInput', null, global);
goog.exportSymbol('proto.dibric.portal.v1.PostInputConfigRequest', null, global);
goog.exportSymbol('proto.dibric.portal.v1.PostInputConfigResponse', null, global);
goog.exportSymbol('proto.dibric.portal.v1.StartDebugExecutionRequest', null, global);
goog.exportSymbol('proto.dibric.portal.v1.StartDebugExecutionResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.ApplyPatchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.dibric.portal.v1.ApplyPatchRequest.oneofGroups_);
};
goog.inherits(proto.dibric.portal.v1.ApplyPatchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.ApplyPatchRequest.displayName = 'proto.dibric.portal.v1.ApplyPatchRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.ApplyPatchResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.ApplyPatchResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.ApplyPatchResponse.displayName = 'proto.dibric.portal.v1.ApplyPatchResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.GetExtensionProjectUrlRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.GetExtensionProjectUrlRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.GetExtensionProjectUrlRequest.displayName = 'proto.dibric.portal.v1.GetExtensionProjectUrlRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.GetExtensionProjectUrlResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.GetExtensionProjectUrlResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.GetExtensionProjectUrlResponse.displayName = 'proto.dibric.portal.v1.GetExtensionProjectUrlResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.GetExtensionTaskUrlRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.GetExtensionTaskUrlRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.GetExtensionTaskUrlRequest.displayName = 'proto.dibric.portal.v1.GetExtensionTaskUrlRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.GetExtensionTaskUrlResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.GetExtensionTaskUrlResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.GetExtensionTaskUrlResponse.displayName = 'proto.dibric.portal.v1.GetExtensionTaskUrlResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.NodeInput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dibric.portal.v1.NodeInput.repeatedFields_, null);
};
goog.inherits(proto.dibric.portal.v1.NodeInput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.NodeInput.displayName = 'proto.dibric.portal.v1.NodeInput';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.Connection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.Connection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.Connection.displayName = 'proto.dibric.portal.v1.Connection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.GetInputConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.GetInputConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.GetInputConfigRequest.displayName = 'proto.dibric.portal.v1.GetInputConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.GetInputConfigResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dibric.portal.v1.GetInputConfigResponse.repeatedFields_, null);
};
goog.inherits(proto.dibric.portal.v1.GetInputConfigResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.GetInputConfigResponse.displayName = 'proto.dibric.portal.v1.GetInputConfigResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.PostInputConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dibric.portal.v1.PostInputConfigRequest.repeatedFields_, null);
};
goog.inherits(proto.dibric.portal.v1.PostInputConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.PostInputConfigRequest.displayName = 'proto.dibric.portal.v1.PostInputConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.PostInputConfigResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dibric.portal.v1.PostInputConfigResponse.repeatedFields_, null);
};
goog.inherits(proto.dibric.portal.v1.PostInputConfigResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.PostInputConfigResponse.displayName = 'proto.dibric.portal.v1.PostInputConfigResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.NegotiateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.NegotiateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.NegotiateRequest.displayName = 'proto.dibric.portal.v1.NegotiateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.NegotiateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.NegotiateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.NegotiateResponse.displayName = 'proto.dibric.portal.v1.NegotiateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.StartDebugExecutionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.StartDebugExecutionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.StartDebugExecutionRequest.displayName = 'proto.dibric.portal.v1.StartDebugExecutionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.StartDebugExecutionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.StartDebugExecutionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.StartDebugExecutionResponse.displayName = 'proto.dibric.portal.v1.StartDebugExecutionResponse';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.dibric.portal.v1.ApplyPatchRequest.oneofGroups_ = [[4,5,6,7,8]];

/**
 * @enum {number}
 */
proto.dibric.portal.v1.ApplyPatchRequest.PatchCase = {
  PATCH_NOT_SET: 0,
  ADD_NODES: 4,
  ADD_EDGES: 5,
  MODIFY_POSITIONS: 6,
  REMOVE_ELEMENTS: 7,
  ITEM_DESCRIPTION: 8
};

/**
 * @return {proto.dibric.portal.v1.ApplyPatchRequest.PatchCase}
 */
proto.dibric.portal.v1.ApplyPatchRequest.prototype.getPatchCase = function() {
  return /** @type {proto.dibric.portal.v1.ApplyPatchRequest.PatchCase} */(jspb.Message.computeOneofCase(this, proto.dibric.portal.v1.ApplyPatchRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.ApplyPatchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.ApplyPatchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.ApplyPatchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.ApplyPatchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    etag: jspb.Message.getFieldWithDefault(msg, 3, ""),
    addNodes: (f = msg.getAddNodes()) && portal_v1_editor_messages_pb.AddNodes.toObject(includeInstance, f),
    addEdges: (f = msg.getAddEdges()) && portal_v1_editor_messages_pb.AddEdges.toObject(includeInstance, f),
    modifyPositions: (f = msg.getModifyPositions()) && portal_v1_editor_messages_pb.ModifyPositions.toObject(includeInstance, f),
    removeElements: (f = msg.getRemoveElements()) && portal_v1_editor_messages_pb.RemoveElements.toObject(includeInstance, f),
    itemDescription: (f = msg.getItemDescription()) && portal_v1_editor_messages_pb.ItemDescription.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.ApplyPatchRequest}
 */
proto.dibric.portal.v1.ApplyPatchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.ApplyPatchRequest;
  return proto.dibric.portal.v1.ApplyPatchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.ApplyPatchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.ApplyPatchRequest}
 */
proto.dibric.portal.v1.ApplyPatchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEtag(value);
      break;
    case 4:
      var value = new portal_v1_editor_messages_pb.AddNodes;
      reader.readMessage(value,portal_v1_editor_messages_pb.AddNodes.deserializeBinaryFromReader);
      msg.setAddNodes(value);
      break;
    case 5:
      var value = new portal_v1_editor_messages_pb.AddEdges;
      reader.readMessage(value,portal_v1_editor_messages_pb.AddEdges.deserializeBinaryFromReader);
      msg.setAddEdges(value);
      break;
    case 6:
      var value = new portal_v1_editor_messages_pb.ModifyPositions;
      reader.readMessage(value,portal_v1_editor_messages_pb.ModifyPositions.deserializeBinaryFromReader);
      msg.setModifyPositions(value);
      break;
    case 7:
      var value = new portal_v1_editor_messages_pb.RemoveElements;
      reader.readMessage(value,portal_v1_editor_messages_pb.RemoveElements.deserializeBinaryFromReader);
      msg.setRemoveElements(value);
      break;
    case 8:
      var value = new portal_v1_editor_messages_pb.ItemDescription;
      reader.readMessage(value,portal_v1_editor_messages_pb.ItemDescription.deserializeBinaryFromReader);
      msg.setItemDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.ApplyPatchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.ApplyPatchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.ApplyPatchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.ApplyPatchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEtag();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAddNodes();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      portal_v1_editor_messages_pb.AddNodes.serializeBinaryToWriter
    );
  }
  f = message.getAddEdges();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      portal_v1_editor_messages_pb.AddEdges.serializeBinaryToWriter
    );
  }
  f = message.getModifyPositions();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      portal_v1_editor_messages_pb.ModifyPositions.serializeBinaryToWriter
    );
  }
  f = message.getRemoveElements();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      portal_v1_editor_messages_pb.RemoveElements.serializeBinaryToWriter
    );
  }
  f = message.getItemDescription();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      portal_v1_editor_messages_pb.ItemDescription.serializeBinaryToWriter
    );
  }
};


/**
 * optional string project_id = 1;
 * @return {string}
 */
proto.dibric.portal.v1.ApplyPatchRequest.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.ApplyPatchRequest} returns this
 */
proto.dibric.portal.v1.ApplyPatchRequest.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string workspace_id = 2;
 * @return {string}
 */
proto.dibric.portal.v1.ApplyPatchRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.ApplyPatchRequest} returns this
 */
proto.dibric.portal.v1.ApplyPatchRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string etag = 3;
 * @return {string}
 */
proto.dibric.portal.v1.ApplyPatchRequest.prototype.getEtag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.ApplyPatchRequest} returns this
 */
proto.dibric.portal.v1.ApplyPatchRequest.prototype.setEtag = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional AddNodes add_nodes = 4;
 * @return {?proto.dibric.portal.v1.AddNodes}
 */
proto.dibric.portal.v1.ApplyPatchRequest.prototype.getAddNodes = function() {
  return /** @type{?proto.dibric.portal.v1.AddNodes} */ (
    jspb.Message.getWrapperField(this, portal_v1_editor_messages_pb.AddNodes, 4));
};


/**
 * @param {?proto.dibric.portal.v1.AddNodes|undefined} value
 * @return {!proto.dibric.portal.v1.ApplyPatchRequest} returns this
*/
proto.dibric.portal.v1.ApplyPatchRequest.prototype.setAddNodes = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.dibric.portal.v1.ApplyPatchRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.ApplyPatchRequest} returns this
 */
proto.dibric.portal.v1.ApplyPatchRequest.prototype.clearAddNodes = function() {
  return this.setAddNodes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.ApplyPatchRequest.prototype.hasAddNodes = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional AddEdges add_edges = 5;
 * @return {?proto.dibric.portal.v1.AddEdges}
 */
proto.dibric.portal.v1.ApplyPatchRequest.prototype.getAddEdges = function() {
  return /** @type{?proto.dibric.portal.v1.AddEdges} */ (
    jspb.Message.getWrapperField(this, portal_v1_editor_messages_pb.AddEdges, 5));
};


/**
 * @param {?proto.dibric.portal.v1.AddEdges|undefined} value
 * @return {!proto.dibric.portal.v1.ApplyPatchRequest} returns this
*/
proto.dibric.portal.v1.ApplyPatchRequest.prototype.setAddEdges = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.dibric.portal.v1.ApplyPatchRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.ApplyPatchRequest} returns this
 */
proto.dibric.portal.v1.ApplyPatchRequest.prototype.clearAddEdges = function() {
  return this.setAddEdges(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.ApplyPatchRequest.prototype.hasAddEdges = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ModifyPositions modify_positions = 6;
 * @return {?proto.dibric.portal.v1.ModifyPositions}
 */
proto.dibric.portal.v1.ApplyPatchRequest.prototype.getModifyPositions = function() {
  return /** @type{?proto.dibric.portal.v1.ModifyPositions} */ (
    jspb.Message.getWrapperField(this, portal_v1_editor_messages_pb.ModifyPositions, 6));
};


/**
 * @param {?proto.dibric.portal.v1.ModifyPositions|undefined} value
 * @return {!proto.dibric.portal.v1.ApplyPatchRequest} returns this
*/
proto.dibric.portal.v1.ApplyPatchRequest.prototype.setModifyPositions = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.dibric.portal.v1.ApplyPatchRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.ApplyPatchRequest} returns this
 */
proto.dibric.portal.v1.ApplyPatchRequest.prototype.clearModifyPositions = function() {
  return this.setModifyPositions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.ApplyPatchRequest.prototype.hasModifyPositions = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional RemoveElements remove_elements = 7;
 * @return {?proto.dibric.portal.v1.RemoveElements}
 */
proto.dibric.portal.v1.ApplyPatchRequest.prototype.getRemoveElements = function() {
  return /** @type{?proto.dibric.portal.v1.RemoveElements} */ (
    jspb.Message.getWrapperField(this, portal_v1_editor_messages_pb.RemoveElements, 7));
};


/**
 * @param {?proto.dibric.portal.v1.RemoveElements|undefined} value
 * @return {!proto.dibric.portal.v1.ApplyPatchRequest} returns this
*/
proto.dibric.portal.v1.ApplyPatchRequest.prototype.setRemoveElements = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.dibric.portal.v1.ApplyPatchRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.ApplyPatchRequest} returns this
 */
proto.dibric.portal.v1.ApplyPatchRequest.prototype.clearRemoveElements = function() {
  return this.setRemoveElements(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.ApplyPatchRequest.prototype.hasRemoveElements = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional ItemDescription item_description = 8;
 * @return {?proto.dibric.portal.v1.ItemDescription}
 */
proto.dibric.portal.v1.ApplyPatchRequest.prototype.getItemDescription = function() {
  return /** @type{?proto.dibric.portal.v1.ItemDescription} */ (
    jspb.Message.getWrapperField(this, portal_v1_editor_messages_pb.ItemDescription, 8));
};


/**
 * @param {?proto.dibric.portal.v1.ItemDescription|undefined} value
 * @return {!proto.dibric.portal.v1.ApplyPatchRequest} returns this
*/
proto.dibric.portal.v1.ApplyPatchRequest.prototype.setItemDescription = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.dibric.portal.v1.ApplyPatchRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.ApplyPatchRequest} returns this
 */
proto.dibric.portal.v1.ApplyPatchRequest.prototype.clearItemDescription = function() {
  return this.setItemDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.ApplyPatchRequest.prototype.hasItemDescription = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.ApplyPatchResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.ApplyPatchResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.ApplyPatchResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.ApplyPatchResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    patch: (f = msg.getPatch()) && portal_v1_editor_messages_pb.PatchAppliedMessage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.ApplyPatchResponse}
 */
proto.dibric.portal.v1.ApplyPatchResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.ApplyPatchResponse;
  return proto.dibric.portal.v1.ApplyPatchResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.ApplyPatchResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.ApplyPatchResponse}
 */
proto.dibric.portal.v1.ApplyPatchResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new portal_v1_editor_messages_pb.PatchAppliedMessage;
      reader.readMessage(value,portal_v1_editor_messages_pb.PatchAppliedMessage.deserializeBinaryFromReader);
      msg.setPatch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.ApplyPatchResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.ApplyPatchResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.ApplyPatchResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.ApplyPatchResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPatch();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      portal_v1_editor_messages_pb.PatchAppliedMessage.serializeBinaryToWriter
    );
  }
};


/**
 * optional PatchAppliedMessage patch = 1;
 * @return {?proto.dibric.portal.v1.PatchAppliedMessage}
 */
proto.dibric.portal.v1.ApplyPatchResponse.prototype.getPatch = function() {
  return /** @type{?proto.dibric.portal.v1.PatchAppliedMessage} */ (
    jspb.Message.getWrapperField(this, portal_v1_editor_messages_pb.PatchAppliedMessage, 1));
};


/**
 * @param {?proto.dibric.portal.v1.PatchAppliedMessage|undefined} value
 * @return {!proto.dibric.portal.v1.ApplyPatchResponse} returns this
*/
proto.dibric.portal.v1.ApplyPatchResponse.prototype.setPatch = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.ApplyPatchResponse} returns this
 */
proto.dibric.portal.v1.ApplyPatchResponse.prototype.clearPatch = function() {
  return this.setPatch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.ApplyPatchResponse.prototype.hasPatch = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.GetExtensionProjectUrlRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.GetExtensionProjectUrlRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.GetExtensionProjectUrlRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.GetExtensionProjectUrlRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    extensionId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    extensionVersion: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.GetExtensionProjectUrlRequest}
 */
proto.dibric.portal.v1.GetExtensionProjectUrlRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.GetExtensionProjectUrlRequest;
  return proto.dibric.portal.v1.GetExtensionProjectUrlRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.GetExtensionProjectUrlRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.GetExtensionProjectUrlRequest}
 */
proto.dibric.portal.v1.GetExtensionProjectUrlRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setExtensionId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setExtensionVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.GetExtensionProjectUrlRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.GetExtensionProjectUrlRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.GetExtensionProjectUrlRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.GetExtensionProjectUrlRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExtensionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getExtensionVersion();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string item_id = 1;
 * @return {string}
 */
proto.dibric.portal.v1.GetExtensionProjectUrlRequest.prototype.getItemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.GetExtensionProjectUrlRequest} returns this
 */
proto.dibric.portal.v1.GetExtensionProjectUrlRequest.prototype.setItemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string workspace_id = 2;
 * @return {string}
 */
proto.dibric.portal.v1.GetExtensionProjectUrlRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.GetExtensionProjectUrlRequest} returns this
 */
proto.dibric.portal.v1.GetExtensionProjectUrlRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string extension_id = 3;
 * @return {string}
 */
proto.dibric.portal.v1.GetExtensionProjectUrlRequest.prototype.getExtensionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.GetExtensionProjectUrlRequest} returns this
 */
proto.dibric.portal.v1.GetExtensionProjectUrlRequest.prototype.setExtensionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string extension_version = 4;
 * @return {string}
 */
proto.dibric.portal.v1.GetExtensionProjectUrlRequest.prototype.getExtensionVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.GetExtensionProjectUrlRequest} returns this
 */
proto.dibric.portal.v1.GetExtensionProjectUrlRequest.prototype.setExtensionVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.GetExtensionProjectUrlResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.GetExtensionProjectUrlResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.GetExtensionProjectUrlResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.GetExtensionProjectUrlResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.GetExtensionProjectUrlResponse}
 */
proto.dibric.portal.v1.GetExtensionProjectUrlResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.GetExtensionProjectUrlResponse;
  return proto.dibric.portal.v1.GetExtensionProjectUrlResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.GetExtensionProjectUrlResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.GetExtensionProjectUrlResponse}
 */
proto.dibric.portal.v1.GetExtensionProjectUrlResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.GetExtensionProjectUrlResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.GetExtensionProjectUrlResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.GetExtensionProjectUrlResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.GetExtensionProjectUrlResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.dibric.portal.v1.GetExtensionProjectUrlResponse.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.GetExtensionProjectUrlResponse} returns this
 */
proto.dibric.portal.v1.GetExtensionProjectUrlResponse.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.GetExtensionTaskUrlRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.GetExtensionTaskUrlRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.GetExtensionTaskUrlRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.GetExtensionTaskUrlRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    nodeId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.GetExtensionTaskUrlRequest}
 */
proto.dibric.portal.v1.GetExtensionTaskUrlRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.GetExtensionTaskUrlRequest;
  return proto.dibric.portal.v1.GetExtensionTaskUrlRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.GetExtensionTaskUrlRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.GetExtensionTaskUrlRequest}
 */
proto.dibric.portal.v1.GetExtensionTaskUrlRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodeId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.GetExtensionTaskUrlRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.GetExtensionTaskUrlRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.GetExtensionTaskUrlRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.GetExtensionTaskUrlRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNodeId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string item_id = 1;
 * @return {string}
 */
proto.dibric.portal.v1.GetExtensionTaskUrlRequest.prototype.getItemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.GetExtensionTaskUrlRequest} returns this
 */
proto.dibric.portal.v1.GetExtensionTaskUrlRequest.prototype.setItemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string workspace_id = 2;
 * @return {string}
 */
proto.dibric.portal.v1.GetExtensionTaskUrlRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.GetExtensionTaskUrlRequest} returns this
 */
proto.dibric.portal.v1.GetExtensionTaskUrlRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string node_id = 3;
 * @return {string}
 */
proto.dibric.portal.v1.GetExtensionTaskUrlRequest.prototype.getNodeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.GetExtensionTaskUrlRequest} returns this
 */
proto.dibric.portal.v1.GetExtensionTaskUrlRequest.prototype.setNodeId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.GetExtensionTaskUrlResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.GetExtensionTaskUrlResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.GetExtensionTaskUrlResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.GetExtensionTaskUrlResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.GetExtensionTaskUrlResponse}
 */
proto.dibric.portal.v1.GetExtensionTaskUrlResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.GetExtensionTaskUrlResponse;
  return proto.dibric.portal.v1.GetExtensionTaskUrlResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.GetExtensionTaskUrlResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.GetExtensionTaskUrlResponse}
 */
proto.dibric.portal.v1.GetExtensionTaskUrlResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.GetExtensionTaskUrlResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.GetExtensionTaskUrlResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.GetExtensionTaskUrlResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.GetExtensionTaskUrlResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.dibric.portal.v1.GetExtensionTaskUrlResponse.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.GetExtensionTaskUrlResponse} returns this
 */
proto.dibric.portal.v1.GetExtensionTaskUrlResponse.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dibric.portal.v1.NodeInput.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.NodeInput.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.NodeInput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.NodeInput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.NodeInput.toObject = function(includeInstance, msg) {
  var f, obj = {
    nodeId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    nodeLabel: jspb.Message.getFieldWithDefault(msg, 2, ""),
    nodeDescription: jspb.Message.getFieldWithDefault(msg, 3, ""),
    handleId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    variablesList: jspb.Message.toObjectList(msg.getVariablesList(),
    graph_v1_public_messages_pb.Variable.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.NodeInput}
 */
proto.dibric.portal.v1.NodeInput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.NodeInput;
  return proto.dibric.portal.v1.NodeInput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.NodeInput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.NodeInput}
 */
proto.dibric.portal.v1.NodeInput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodeId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodeLabel(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodeDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setHandleId(value);
      break;
    case 5:
      var value = new graph_v1_public_messages_pb.Variable;
      reader.readMessage(value,graph_v1_public_messages_pb.Variable.deserializeBinaryFromReader);
      msg.addVariables(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.NodeInput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.NodeInput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.NodeInput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.NodeInput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNodeId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNodeLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNodeDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getHandleId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getVariablesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      graph_v1_public_messages_pb.Variable.serializeBinaryToWriter
    );
  }
};


/**
 * optional string node_id = 1;
 * @return {string}
 */
proto.dibric.portal.v1.NodeInput.prototype.getNodeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.NodeInput} returns this
 */
proto.dibric.portal.v1.NodeInput.prototype.setNodeId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string node_label = 2;
 * @return {string}
 */
proto.dibric.portal.v1.NodeInput.prototype.getNodeLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.NodeInput} returns this
 */
proto.dibric.portal.v1.NodeInput.prototype.setNodeLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string node_description = 3;
 * @return {string}
 */
proto.dibric.portal.v1.NodeInput.prototype.getNodeDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.NodeInput} returns this
 */
proto.dibric.portal.v1.NodeInput.prototype.setNodeDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string handle_id = 4;
 * @return {string}
 */
proto.dibric.portal.v1.NodeInput.prototype.getHandleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.NodeInput} returns this
 */
proto.dibric.portal.v1.NodeInput.prototype.setHandleId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated dibric.graph.v1.Variable variables = 5;
 * @return {!Array<!proto.dibric.graph.v1.Variable>}
 */
proto.dibric.portal.v1.NodeInput.prototype.getVariablesList = function() {
  return /** @type{!Array<!proto.dibric.graph.v1.Variable>} */ (
    jspb.Message.getRepeatedWrapperField(this, graph_v1_public_messages_pb.Variable, 5));
};


/**
 * @param {!Array<!proto.dibric.graph.v1.Variable>} value
 * @return {!proto.dibric.portal.v1.NodeInput} returns this
*/
proto.dibric.portal.v1.NodeInput.prototype.setVariablesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.dibric.graph.v1.Variable=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dibric.graph.v1.Variable}
 */
proto.dibric.portal.v1.NodeInput.prototype.addVariables = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.dibric.graph.v1.Variable, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dibric.portal.v1.NodeInput} returns this
 */
proto.dibric.portal.v1.NodeInput.prototype.clearVariablesList = function() {
  return this.setVariablesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.Connection.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.Connection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.Connection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.Connection.toObject = function(includeInstance, msg) {
  var f, obj = {
    variableId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    connectedTo: (f = msg.getConnectedTo()) && graph_v1_public_messages_pb.VariableKey.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.Connection}
 */
proto.dibric.portal.v1.Connection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.Connection;
  return proto.dibric.portal.v1.Connection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.Connection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.Connection}
 */
proto.dibric.portal.v1.Connection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVariableId(value);
      break;
    case 2:
      var value = new graph_v1_public_messages_pb.VariableKey;
      reader.readMessage(value,graph_v1_public_messages_pb.VariableKey.deserializeBinaryFromReader);
      msg.setConnectedTo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.Connection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.Connection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.Connection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.Connection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVariableId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getConnectedTo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      graph_v1_public_messages_pb.VariableKey.serializeBinaryToWriter
    );
  }
};


/**
 * optional string variable_id = 1;
 * @return {string}
 */
proto.dibric.portal.v1.Connection.prototype.getVariableId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.Connection} returns this
 */
proto.dibric.portal.v1.Connection.prototype.setVariableId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional dibric.graph.v1.VariableKey connected_to = 2;
 * @return {?proto.dibric.graph.v1.VariableKey}
 */
proto.dibric.portal.v1.Connection.prototype.getConnectedTo = function() {
  return /** @type{?proto.dibric.graph.v1.VariableKey} */ (
    jspb.Message.getWrapperField(this, graph_v1_public_messages_pb.VariableKey, 2));
};


/**
 * @param {?proto.dibric.graph.v1.VariableKey|undefined} value
 * @return {!proto.dibric.portal.v1.Connection} returns this
*/
proto.dibric.portal.v1.Connection.prototype.setConnectedTo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.Connection} returns this
 */
proto.dibric.portal.v1.Connection.prototype.clearConnectedTo = function() {
  return this.setConnectedTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.Connection.prototype.hasConnectedTo = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.GetInputConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.GetInputConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.GetInputConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.GetInputConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    nodeId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.GetInputConfigRequest}
 */
proto.dibric.portal.v1.GetInputConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.GetInputConfigRequest;
  return proto.dibric.portal.v1.GetInputConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.GetInputConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.GetInputConfigRequest}
 */
proto.dibric.portal.v1.GetInputConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodeId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.GetInputConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.GetInputConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.GetInputConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.GetInputConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNodeId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string item_id = 1;
 * @return {string}
 */
proto.dibric.portal.v1.GetInputConfigRequest.prototype.getItemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.GetInputConfigRequest} returns this
 */
proto.dibric.portal.v1.GetInputConfigRequest.prototype.setItemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string workspace_id = 2;
 * @return {string}
 */
proto.dibric.portal.v1.GetInputConfigRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.GetInputConfigRequest} returns this
 */
proto.dibric.portal.v1.GetInputConfigRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string node_id = 3;
 * @return {string}
 */
proto.dibric.portal.v1.GetInputConfigRequest.prototype.getNodeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.GetInputConfigRequest} returns this
 */
proto.dibric.portal.v1.GetInputConfigRequest.prototype.setNodeId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dibric.portal.v1.GetInputConfigResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.GetInputConfigResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.GetInputConfigResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.GetInputConfigResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.GetInputConfigResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    handlesList: jspb.Message.toObjectList(msg.getHandlesList(),
    graph_v1_public_messages_pb.NodeHandle.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.GetInputConfigResponse}
 */
proto.dibric.portal.v1.GetInputConfigResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.GetInputConfigResponse;
  return proto.dibric.portal.v1.GetInputConfigResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.GetInputConfigResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.GetInputConfigResponse}
 */
proto.dibric.portal.v1.GetInputConfigResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new graph_v1_public_messages_pb.NodeHandle;
      reader.readMessage(value,graph_v1_public_messages_pb.NodeHandle.deserializeBinaryFromReader);
      msg.addHandles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.GetInputConfigResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.GetInputConfigResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.GetInputConfigResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.GetInputConfigResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHandlesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      graph_v1_public_messages_pb.NodeHandle.serializeBinaryToWriter
    );
  }
};


/**
 * repeated dibric.graph.v1.NodeHandle handles = 1;
 * @return {!Array<!proto.dibric.graph.v1.NodeHandle>}
 */
proto.dibric.portal.v1.GetInputConfigResponse.prototype.getHandlesList = function() {
  return /** @type{!Array<!proto.dibric.graph.v1.NodeHandle>} */ (
    jspb.Message.getRepeatedWrapperField(this, graph_v1_public_messages_pb.NodeHandle, 1));
};


/**
 * @param {!Array<!proto.dibric.graph.v1.NodeHandle>} value
 * @return {!proto.dibric.portal.v1.GetInputConfigResponse} returns this
*/
proto.dibric.portal.v1.GetInputConfigResponse.prototype.setHandlesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.dibric.graph.v1.NodeHandle=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dibric.graph.v1.NodeHandle}
 */
proto.dibric.portal.v1.GetInputConfigResponse.prototype.addHandles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.dibric.graph.v1.NodeHandle, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dibric.portal.v1.GetInputConfigResponse} returns this
 */
proto.dibric.portal.v1.GetInputConfigResponse.prototype.clearHandlesList = function() {
  return this.setHandlesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dibric.portal.v1.PostInputConfigRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.PostInputConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.PostInputConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.PostInputConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.PostInputConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    nodeId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    handlesList: jspb.Message.toObjectList(msg.getHandlesList(),
    graph_v1_public_messages_pb.NodeHandle.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.PostInputConfigRequest}
 */
proto.dibric.portal.v1.PostInputConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.PostInputConfigRequest;
  return proto.dibric.portal.v1.PostInputConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.PostInputConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.PostInputConfigRequest}
 */
proto.dibric.portal.v1.PostInputConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodeId(value);
      break;
    case 4:
      var value = new graph_v1_public_messages_pb.NodeHandle;
      reader.readMessage(value,graph_v1_public_messages_pb.NodeHandle.deserializeBinaryFromReader);
      msg.addHandles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.PostInputConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.PostInputConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.PostInputConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.PostInputConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNodeId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getHandlesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      graph_v1_public_messages_pb.NodeHandle.serializeBinaryToWriter
    );
  }
};


/**
 * optional string item_id = 1;
 * @return {string}
 */
proto.dibric.portal.v1.PostInputConfigRequest.prototype.getItemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.PostInputConfigRequest} returns this
 */
proto.dibric.portal.v1.PostInputConfigRequest.prototype.setItemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string workspace_id = 2;
 * @return {string}
 */
proto.dibric.portal.v1.PostInputConfigRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.PostInputConfigRequest} returns this
 */
proto.dibric.portal.v1.PostInputConfigRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string node_id = 3;
 * @return {string}
 */
proto.dibric.portal.v1.PostInputConfigRequest.prototype.getNodeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.PostInputConfigRequest} returns this
 */
proto.dibric.portal.v1.PostInputConfigRequest.prototype.setNodeId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated dibric.graph.v1.NodeHandle handles = 4;
 * @return {!Array<!proto.dibric.graph.v1.NodeHandle>}
 */
proto.dibric.portal.v1.PostInputConfigRequest.prototype.getHandlesList = function() {
  return /** @type{!Array<!proto.dibric.graph.v1.NodeHandle>} */ (
    jspb.Message.getRepeatedWrapperField(this, graph_v1_public_messages_pb.NodeHandle, 4));
};


/**
 * @param {!Array<!proto.dibric.graph.v1.NodeHandle>} value
 * @return {!proto.dibric.portal.v1.PostInputConfigRequest} returns this
*/
proto.dibric.portal.v1.PostInputConfigRequest.prototype.setHandlesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.dibric.graph.v1.NodeHandle=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dibric.graph.v1.NodeHandle}
 */
proto.dibric.portal.v1.PostInputConfigRequest.prototype.addHandles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.dibric.graph.v1.NodeHandle, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dibric.portal.v1.PostInputConfigRequest} returns this
 */
proto.dibric.portal.v1.PostInputConfigRequest.prototype.clearHandlesList = function() {
  return this.setHandlesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dibric.portal.v1.PostInputConfigResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.PostInputConfigResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.PostInputConfigResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.PostInputConfigResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.PostInputConfigResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    handlesList: jspb.Message.toObjectList(msg.getHandlesList(),
    graph_v1_public_messages_pb.NodeHandle.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.PostInputConfigResponse}
 */
proto.dibric.portal.v1.PostInputConfigResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.PostInputConfigResponse;
  return proto.dibric.portal.v1.PostInputConfigResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.PostInputConfigResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.PostInputConfigResponse}
 */
proto.dibric.portal.v1.PostInputConfigResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new graph_v1_public_messages_pb.NodeHandle;
      reader.readMessage(value,graph_v1_public_messages_pb.NodeHandle.deserializeBinaryFromReader);
      msg.addHandles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.PostInputConfigResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.PostInputConfigResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.PostInputConfigResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.PostInputConfigResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHandlesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      graph_v1_public_messages_pb.NodeHandle.serializeBinaryToWriter
    );
  }
};


/**
 * repeated dibric.graph.v1.NodeHandle handles = 1;
 * @return {!Array<!proto.dibric.graph.v1.NodeHandle>}
 */
proto.dibric.portal.v1.PostInputConfigResponse.prototype.getHandlesList = function() {
  return /** @type{!Array<!proto.dibric.graph.v1.NodeHandle>} */ (
    jspb.Message.getRepeatedWrapperField(this, graph_v1_public_messages_pb.NodeHandle, 1));
};


/**
 * @param {!Array<!proto.dibric.graph.v1.NodeHandle>} value
 * @return {!proto.dibric.portal.v1.PostInputConfigResponse} returns this
*/
proto.dibric.portal.v1.PostInputConfigResponse.prototype.setHandlesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.dibric.graph.v1.NodeHandle=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dibric.graph.v1.NodeHandle}
 */
proto.dibric.portal.v1.PostInputConfigResponse.prototype.addHandles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.dibric.graph.v1.NodeHandle, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dibric.portal.v1.PostInputConfigResponse} returns this
 */
proto.dibric.portal.v1.PostInputConfigResponse.prototype.clearHandlesList = function() {
  return this.setHandlesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.NegotiateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.NegotiateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.NegotiateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.NegotiateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.NegotiateRequest}
 */
proto.dibric.portal.v1.NegotiateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.NegotiateRequest;
  return proto.dibric.portal.v1.NegotiateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.NegotiateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.NegotiateRequest}
 */
proto.dibric.portal.v1.NegotiateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.NegotiateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.NegotiateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.NegotiateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.NegotiateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string workspace_id = 1;
 * @return {string}
 */
proto.dibric.portal.v1.NegotiateRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.NegotiateRequest} returns this
 */
proto.dibric.portal.v1.NegotiateRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.NegotiateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.NegotiateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.NegotiateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.NegotiateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    uri: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.NegotiateResponse}
 */
proto.dibric.portal.v1.NegotiateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.NegotiateResponse;
  return proto.dibric.portal.v1.NegotiateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.NegotiateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.NegotiateResponse}
 */
proto.dibric.portal.v1.NegotiateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.NegotiateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.NegotiateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.NegotiateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.NegotiateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string uri = 1;
 * @return {string}
 */
proto.dibric.portal.v1.NegotiateResponse.prototype.getUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.NegotiateResponse} returns this
 */
proto.dibric.portal.v1.NegotiateResponse.prototype.setUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.StartDebugExecutionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.StartDebugExecutionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.StartDebugExecutionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.StartDebugExecutionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    handle: jspb.Message.getFieldWithDefault(msg, 3, ""),
    inputMap: (f = msg.getInputMap()) ? f.toObject(includeInstance, proto.dibric.graph.v1.Value.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.StartDebugExecutionRequest}
 */
proto.dibric.portal.v1.StartDebugExecutionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.StartDebugExecutionRequest;
  return proto.dibric.portal.v1.StartDebugExecutionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.StartDebugExecutionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.StartDebugExecutionRequest}
 */
proto.dibric.portal.v1.StartDebugExecutionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setHandle(value);
      break;
    case 4:
      var value = msg.getInputMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.dibric.graph.v1.Value.deserializeBinaryFromReader, "", new proto.dibric.graph.v1.Value());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.StartDebugExecutionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.StartDebugExecutionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.StartDebugExecutionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.StartDebugExecutionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHandle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInputMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.dibric.graph.v1.Value.serializeBinaryToWriter);
  }
};


/**
 * optional string item_id = 1;
 * @return {string}
 */
proto.dibric.portal.v1.StartDebugExecutionRequest.prototype.getItemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.StartDebugExecutionRequest} returns this
 */
proto.dibric.portal.v1.StartDebugExecutionRequest.prototype.setItemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string workspace_id = 2;
 * @return {string}
 */
proto.dibric.portal.v1.StartDebugExecutionRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.StartDebugExecutionRequest} returns this
 */
proto.dibric.portal.v1.StartDebugExecutionRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string handle = 3;
 * @return {string}
 */
proto.dibric.portal.v1.StartDebugExecutionRequest.prototype.getHandle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.StartDebugExecutionRequest} returns this
 */
proto.dibric.portal.v1.StartDebugExecutionRequest.prototype.setHandle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * map<string, dibric.graph.v1.Value> input = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.dibric.graph.v1.Value>}
 */
proto.dibric.portal.v1.StartDebugExecutionRequest.prototype.getInputMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.dibric.graph.v1.Value>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      proto.dibric.graph.v1.Value));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.dibric.portal.v1.StartDebugExecutionRequest} returns this
 */
proto.dibric.portal.v1.StartDebugExecutionRequest.prototype.clearInputMap = function() {
  this.getInputMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.StartDebugExecutionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.StartDebugExecutionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.StartDebugExecutionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.StartDebugExecutionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    processId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    websocketUrl: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.StartDebugExecutionResponse}
 */
proto.dibric.portal.v1.StartDebugExecutionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.StartDebugExecutionResponse;
  return proto.dibric.portal.v1.StartDebugExecutionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.StartDebugExecutionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.StartDebugExecutionResponse}
 */
proto.dibric.portal.v1.StartDebugExecutionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebsocketUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.StartDebugExecutionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.StartDebugExecutionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.StartDebugExecutionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.StartDebugExecutionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcessId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWebsocketUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string process_id = 1;
 * @return {string}
 */
proto.dibric.portal.v1.StartDebugExecutionResponse.prototype.getProcessId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.StartDebugExecutionResponse} returns this
 */
proto.dibric.portal.v1.StartDebugExecutionResponse.prototype.setProcessId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string websocket_url = 2;
 * @return {string}
 */
proto.dibric.portal.v1.StartDebugExecutionResponse.prototype.getWebsocketUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.StartDebugExecutionResponse} returns this
 */
proto.dibric.portal.v1.StartDebugExecutionResponse.prototype.setWebsocketUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


goog.object.extend(exports, proto.dibric.portal.v1);
