// source: portal/v1/deployments_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.dibric.portal.v1.AttachSnapshotRequest', null, global);
goog.exportSymbol('proto.dibric.portal.v1.AttachSnapshotResponse', null, global);
goog.exportSymbol('proto.dibric.portal.v1.CreateDeploymentSlotRequest', null, global);
goog.exportSymbol('proto.dibric.portal.v1.CreateDeploymentSlotResponse', null, global);
goog.exportSymbol('proto.dibric.portal.v1.CreateSnapshotRequest', null, global);
goog.exportSymbol('proto.dibric.portal.v1.CreateSnapshotResponse', null, global);
goog.exportSymbol('proto.dibric.portal.v1.DeploymentSlot', null, global);
goog.exportSymbol('proto.dibric.portal.v1.DeploymentSlotHeader', null, global);
goog.exportSymbol('proto.dibric.portal.v1.GetDeploymentSlotRequest', null, global);
goog.exportSymbol('proto.dibric.portal.v1.GetDeploymentSlotResponse', null, global);
goog.exportSymbol('proto.dibric.portal.v1.ListDeploymentSlotsRequest', null, global);
goog.exportSymbol('proto.dibric.portal.v1.ListDeploymentSlotsResponse', null, global);
goog.exportSymbol('proto.dibric.portal.v1.ListSnapshotsRequest', null, global);
goog.exportSymbol('proto.dibric.portal.v1.ListSnapshotsResponse', null, global);
goog.exportSymbol('proto.dibric.portal.v1.Snapshot', null, global);
goog.exportSymbol('proto.dibric.portal.v1.SnapshotHandle', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.CreateDeploymentSlotRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.CreateDeploymentSlotRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.CreateDeploymentSlotRequest.displayName = 'proto.dibric.portal.v1.CreateDeploymentSlotRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.CreateDeploymentSlotResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.CreateDeploymentSlotResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.CreateDeploymentSlotResponse.displayName = 'proto.dibric.portal.v1.CreateDeploymentSlotResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.CreateSnapshotRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.CreateSnapshotRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.CreateSnapshotRequest.displayName = 'proto.dibric.portal.v1.CreateSnapshotRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.CreateSnapshotResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.CreateSnapshotResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.CreateSnapshotResponse.displayName = 'proto.dibric.portal.v1.CreateSnapshotResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.ListDeploymentSlotsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.ListDeploymentSlotsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.ListDeploymentSlotsRequest.displayName = 'proto.dibric.portal.v1.ListDeploymentSlotsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.ListDeploymentSlotsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dibric.portal.v1.ListDeploymentSlotsResponse.repeatedFields_, null);
};
goog.inherits(proto.dibric.portal.v1.ListDeploymentSlotsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.ListDeploymentSlotsResponse.displayName = 'proto.dibric.portal.v1.ListDeploymentSlotsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.GetDeploymentSlotRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.GetDeploymentSlotRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.GetDeploymentSlotRequest.displayName = 'proto.dibric.portal.v1.GetDeploymentSlotRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.GetDeploymentSlotResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.GetDeploymentSlotResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.GetDeploymentSlotResponse.displayName = 'proto.dibric.portal.v1.GetDeploymentSlotResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.AttachSnapshotRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.AttachSnapshotRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.AttachSnapshotRequest.displayName = 'proto.dibric.portal.v1.AttachSnapshotRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.AttachSnapshotResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.AttachSnapshotResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.AttachSnapshotResponse.displayName = 'proto.dibric.portal.v1.AttachSnapshotResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.ListSnapshotsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.ListSnapshotsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.ListSnapshotsRequest.displayName = 'proto.dibric.portal.v1.ListSnapshotsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.ListSnapshotsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dibric.portal.v1.ListSnapshotsResponse.repeatedFields_, null);
};
goog.inherits(proto.dibric.portal.v1.ListSnapshotsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.ListSnapshotsResponse.displayName = 'proto.dibric.portal.v1.ListSnapshotsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.DeploymentSlotHeader = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.DeploymentSlotHeader, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.DeploymentSlotHeader.displayName = 'proto.dibric.portal.v1.DeploymentSlotHeader';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.DeploymentSlot = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.DeploymentSlot, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.DeploymentSlot.displayName = 'proto.dibric.portal.v1.DeploymentSlot';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.Snapshot = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dibric.portal.v1.Snapshot.repeatedFields_, null);
};
goog.inherits(proto.dibric.portal.v1.Snapshot, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.Snapshot.displayName = 'proto.dibric.portal.v1.Snapshot';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.SnapshotHandle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dibric.portal.v1.SnapshotHandle.repeatedFields_, null);
};
goog.inherits(proto.dibric.portal.v1.SnapshotHandle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.SnapshotHandle.displayName = 'proto.dibric.portal.v1.SnapshotHandle';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.CreateDeploymentSlotRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.CreateDeploymentSlotRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.CreateDeploymentSlotRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.CreateDeploymentSlotRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    snapshotId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    handle: jspb.Message.getFieldWithDefault(msg, 5, ""),
    metadataMap: (f = msg.getMetadataMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.CreateDeploymentSlotRequest}
 */
proto.dibric.portal.v1.CreateDeploymentSlotRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.CreateDeploymentSlotRequest;
  return proto.dibric.portal.v1.CreateDeploymentSlotRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.CreateDeploymentSlotRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.CreateDeploymentSlotRequest}
 */
proto.dibric.portal.v1.CreateDeploymentSlotRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSnapshotId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setHandle(value);
      break;
    case 6:
      var value = msg.getMetadataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.CreateDeploymentSlotRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.CreateDeploymentSlotRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.CreateDeploymentSlotRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.CreateDeploymentSlotRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSnapshotId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getHandle();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMetadataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(6, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.dibric.portal.v1.CreateDeploymentSlotRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.CreateDeploymentSlotRequest} returns this
 */
proto.dibric.portal.v1.CreateDeploymentSlotRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string workspace_id = 2;
 * @return {string}
 */
proto.dibric.portal.v1.CreateDeploymentSlotRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.CreateDeploymentSlotRequest} returns this
 */
proto.dibric.portal.v1.CreateDeploymentSlotRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.dibric.portal.v1.CreateDeploymentSlotRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.CreateDeploymentSlotRequest} returns this
 */
proto.dibric.portal.v1.CreateDeploymentSlotRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string snapshot_id = 4;
 * @return {string}
 */
proto.dibric.portal.v1.CreateDeploymentSlotRequest.prototype.getSnapshotId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.CreateDeploymentSlotRequest} returns this
 */
proto.dibric.portal.v1.CreateDeploymentSlotRequest.prototype.setSnapshotId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string handle = 5;
 * @return {string}
 */
proto.dibric.portal.v1.CreateDeploymentSlotRequest.prototype.getHandle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.CreateDeploymentSlotRequest} returns this
 */
proto.dibric.portal.v1.CreateDeploymentSlotRequest.prototype.setHandle = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * map<string, string> metadata = 6;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.dibric.portal.v1.CreateDeploymentSlotRequest.prototype.getMetadataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 6, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.dibric.portal.v1.CreateDeploymentSlotRequest} returns this
 */
proto.dibric.portal.v1.CreateDeploymentSlotRequest.prototype.clearMetadataMap = function() {
  this.getMetadataMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.CreateDeploymentSlotResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.CreateDeploymentSlotResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.CreateDeploymentSlotResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.CreateDeploymentSlotResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    deploymentSlot: (f = msg.getDeploymentSlot()) && proto.dibric.portal.v1.DeploymentSlot.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.CreateDeploymentSlotResponse}
 */
proto.dibric.portal.v1.CreateDeploymentSlotResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.CreateDeploymentSlotResponse;
  return proto.dibric.portal.v1.CreateDeploymentSlotResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.CreateDeploymentSlotResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.CreateDeploymentSlotResponse}
 */
proto.dibric.portal.v1.CreateDeploymentSlotResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dibric.portal.v1.DeploymentSlot;
      reader.readMessage(value,proto.dibric.portal.v1.DeploymentSlot.deserializeBinaryFromReader);
      msg.setDeploymentSlot(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.CreateDeploymentSlotResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.CreateDeploymentSlotResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.CreateDeploymentSlotResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.CreateDeploymentSlotResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeploymentSlot();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dibric.portal.v1.DeploymentSlot.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeploymentSlot deployment_slot = 1;
 * @return {?proto.dibric.portal.v1.DeploymentSlot}
 */
proto.dibric.portal.v1.CreateDeploymentSlotResponse.prototype.getDeploymentSlot = function() {
  return /** @type{?proto.dibric.portal.v1.DeploymentSlot} */ (
    jspb.Message.getWrapperField(this, proto.dibric.portal.v1.DeploymentSlot, 1));
};


/**
 * @param {?proto.dibric.portal.v1.DeploymentSlot|undefined} value
 * @return {!proto.dibric.portal.v1.CreateDeploymentSlotResponse} returns this
*/
proto.dibric.portal.v1.CreateDeploymentSlotResponse.prototype.setDeploymentSlot = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.CreateDeploymentSlotResponse} returns this
 */
proto.dibric.portal.v1.CreateDeploymentSlotResponse.prototype.clearDeploymentSlot = function() {
  return this.setDeploymentSlot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.CreateDeploymentSlotResponse.prototype.hasDeploymentSlot = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.CreateSnapshotRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.CreateSnapshotRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.CreateSnapshotRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.CreateSnapshotRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    projectId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    metadataMap: (f = msg.getMetadataMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.CreateSnapshotRequest}
 */
proto.dibric.portal.v1.CreateSnapshotRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.CreateSnapshotRequest;
  return proto.dibric.portal.v1.CreateSnapshotRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.CreateSnapshotRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.CreateSnapshotRequest}
 */
proto.dibric.portal.v1.CreateSnapshotRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = msg.getMetadataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.CreateSnapshotRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.CreateSnapshotRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.CreateSnapshotRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.CreateSnapshotRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMetadataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional string workspace_id = 1;
 * @return {string}
 */
proto.dibric.portal.v1.CreateSnapshotRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.CreateSnapshotRequest} returns this
 */
proto.dibric.portal.v1.CreateSnapshotRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string project_id = 2;
 * @return {string}
 */
proto.dibric.portal.v1.CreateSnapshotRequest.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.CreateSnapshotRequest} returns this
 */
proto.dibric.portal.v1.CreateSnapshotRequest.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.dibric.portal.v1.CreateSnapshotRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.CreateSnapshotRequest} returns this
 */
proto.dibric.portal.v1.CreateSnapshotRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.dibric.portal.v1.CreateSnapshotRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.CreateSnapshotRequest} returns this
 */
proto.dibric.portal.v1.CreateSnapshotRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * map<string, string> metadata = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.dibric.portal.v1.CreateSnapshotRequest.prototype.getMetadataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.dibric.portal.v1.CreateSnapshotRequest} returns this
 */
proto.dibric.portal.v1.CreateSnapshotRequest.prototype.clearMetadataMap = function() {
  this.getMetadataMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.CreateSnapshotResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.CreateSnapshotResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.CreateSnapshotResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.CreateSnapshotResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    snapshot: (f = msg.getSnapshot()) && proto.dibric.portal.v1.Snapshot.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.CreateSnapshotResponse}
 */
proto.dibric.portal.v1.CreateSnapshotResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.CreateSnapshotResponse;
  return proto.dibric.portal.v1.CreateSnapshotResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.CreateSnapshotResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.CreateSnapshotResponse}
 */
proto.dibric.portal.v1.CreateSnapshotResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dibric.portal.v1.Snapshot;
      reader.readMessage(value,proto.dibric.portal.v1.Snapshot.deserializeBinaryFromReader);
      msg.setSnapshot(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.CreateSnapshotResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.CreateSnapshotResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.CreateSnapshotResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.CreateSnapshotResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSnapshot();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dibric.portal.v1.Snapshot.serializeBinaryToWriter
    );
  }
};


/**
 * optional Snapshot Snapshot = 1;
 * @return {?proto.dibric.portal.v1.Snapshot}
 */
proto.dibric.portal.v1.CreateSnapshotResponse.prototype.getSnapshot = function() {
  return /** @type{?proto.dibric.portal.v1.Snapshot} */ (
    jspb.Message.getWrapperField(this, proto.dibric.portal.v1.Snapshot, 1));
};


/**
 * @param {?proto.dibric.portal.v1.Snapshot|undefined} value
 * @return {!proto.dibric.portal.v1.CreateSnapshotResponse} returns this
*/
proto.dibric.portal.v1.CreateSnapshotResponse.prototype.setSnapshot = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.CreateSnapshotResponse} returns this
 */
proto.dibric.portal.v1.CreateSnapshotResponse.prototype.clearSnapshot = function() {
  return this.setSnapshot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.CreateSnapshotResponse.prototype.hasSnapshot = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.ListDeploymentSlotsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.ListDeploymentSlotsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.ListDeploymentSlotsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.ListDeploymentSlotsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filterWorkspaceId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.ListDeploymentSlotsRequest}
 */
proto.dibric.portal.v1.ListDeploymentSlotsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.ListDeploymentSlotsRequest;
  return proto.dibric.portal.v1.ListDeploymentSlotsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.ListDeploymentSlotsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.ListDeploymentSlotsRequest}
 */
proto.dibric.portal.v1.ListDeploymentSlotsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilterWorkspaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.ListDeploymentSlotsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.ListDeploymentSlotsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.ListDeploymentSlotsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.ListDeploymentSlotsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilterWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string filter_workspace_id = 1;
 * @return {string}
 */
proto.dibric.portal.v1.ListDeploymentSlotsRequest.prototype.getFilterWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.ListDeploymentSlotsRequest} returns this
 */
proto.dibric.portal.v1.ListDeploymentSlotsRequest.prototype.setFilterWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dibric.portal.v1.ListDeploymentSlotsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.ListDeploymentSlotsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.ListDeploymentSlotsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.ListDeploymentSlotsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.ListDeploymentSlotsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    deploymentSlotsList: jspb.Message.toObjectList(msg.getDeploymentSlotsList(),
    proto.dibric.portal.v1.DeploymentSlot.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.ListDeploymentSlotsResponse}
 */
proto.dibric.portal.v1.ListDeploymentSlotsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.ListDeploymentSlotsResponse;
  return proto.dibric.portal.v1.ListDeploymentSlotsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.ListDeploymentSlotsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.ListDeploymentSlotsResponse}
 */
proto.dibric.portal.v1.ListDeploymentSlotsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dibric.portal.v1.DeploymentSlot;
      reader.readMessage(value,proto.dibric.portal.v1.DeploymentSlot.deserializeBinaryFromReader);
      msg.addDeploymentSlots(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.ListDeploymentSlotsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.ListDeploymentSlotsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.ListDeploymentSlotsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.ListDeploymentSlotsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeploymentSlotsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.dibric.portal.v1.DeploymentSlot.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DeploymentSlot deployment_slots = 1;
 * @return {!Array<!proto.dibric.portal.v1.DeploymentSlot>}
 */
proto.dibric.portal.v1.ListDeploymentSlotsResponse.prototype.getDeploymentSlotsList = function() {
  return /** @type{!Array<!proto.dibric.portal.v1.DeploymentSlot>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dibric.portal.v1.DeploymentSlot, 1));
};


/**
 * @param {!Array<!proto.dibric.portal.v1.DeploymentSlot>} value
 * @return {!proto.dibric.portal.v1.ListDeploymentSlotsResponse} returns this
*/
proto.dibric.portal.v1.ListDeploymentSlotsResponse.prototype.setDeploymentSlotsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.dibric.portal.v1.DeploymentSlot=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dibric.portal.v1.DeploymentSlot}
 */
proto.dibric.portal.v1.ListDeploymentSlotsResponse.prototype.addDeploymentSlots = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.dibric.portal.v1.DeploymentSlot, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dibric.portal.v1.ListDeploymentSlotsResponse} returns this
 */
proto.dibric.portal.v1.ListDeploymentSlotsResponse.prototype.clearDeploymentSlotsList = function() {
  return this.setDeploymentSlotsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.GetDeploymentSlotRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.GetDeploymentSlotRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.GetDeploymentSlotRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.GetDeploymentSlotRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    includeSnapshot: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.GetDeploymentSlotRequest}
 */
proto.dibric.portal.v1.GetDeploymentSlotRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.GetDeploymentSlotRequest;
  return proto.dibric.portal.v1.GetDeploymentSlotRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.GetDeploymentSlotRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.GetDeploymentSlotRequest}
 */
proto.dibric.portal.v1.GetDeploymentSlotRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeSnapshot(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.GetDeploymentSlotRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.GetDeploymentSlotRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.GetDeploymentSlotRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.GetDeploymentSlotRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIncludeSnapshot();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.dibric.portal.v1.GetDeploymentSlotRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.GetDeploymentSlotRequest} returns this
 */
proto.dibric.portal.v1.GetDeploymentSlotRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool include_snapshot = 2;
 * @return {boolean}
 */
proto.dibric.portal.v1.GetDeploymentSlotRequest.prototype.getIncludeSnapshot = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dibric.portal.v1.GetDeploymentSlotRequest} returns this
 */
proto.dibric.portal.v1.GetDeploymentSlotRequest.prototype.setIncludeSnapshot = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.GetDeploymentSlotResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.GetDeploymentSlotResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.GetDeploymentSlotResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.GetDeploymentSlotResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    deploymentSlot: (f = msg.getDeploymentSlot()) && proto.dibric.portal.v1.DeploymentSlot.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.GetDeploymentSlotResponse}
 */
proto.dibric.portal.v1.GetDeploymentSlotResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.GetDeploymentSlotResponse;
  return proto.dibric.portal.v1.GetDeploymentSlotResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.GetDeploymentSlotResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.GetDeploymentSlotResponse}
 */
proto.dibric.portal.v1.GetDeploymentSlotResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dibric.portal.v1.DeploymentSlot;
      reader.readMessage(value,proto.dibric.portal.v1.DeploymentSlot.deserializeBinaryFromReader);
      msg.setDeploymentSlot(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.GetDeploymentSlotResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.GetDeploymentSlotResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.GetDeploymentSlotResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.GetDeploymentSlotResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeploymentSlot();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dibric.portal.v1.DeploymentSlot.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeploymentSlot deployment_slot = 1;
 * @return {?proto.dibric.portal.v1.DeploymentSlot}
 */
proto.dibric.portal.v1.GetDeploymentSlotResponse.prototype.getDeploymentSlot = function() {
  return /** @type{?proto.dibric.portal.v1.DeploymentSlot} */ (
    jspb.Message.getWrapperField(this, proto.dibric.portal.v1.DeploymentSlot, 1));
};


/**
 * @param {?proto.dibric.portal.v1.DeploymentSlot|undefined} value
 * @return {!proto.dibric.portal.v1.GetDeploymentSlotResponse} returns this
*/
proto.dibric.portal.v1.GetDeploymentSlotResponse.prototype.setDeploymentSlot = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.GetDeploymentSlotResponse} returns this
 */
proto.dibric.portal.v1.GetDeploymentSlotResponse.prototype.clearDeploymentSlot = function() {
  return this.setDeploymentSlot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.GetDeploymentSlotResponse.prototype.hasDeploymentSlot = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.AttachSnapshotRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.AttachSnapshotRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.AttachSnapshotRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.AttachSnapshotRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deploymentSlotId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    version: jspb.Message.getFieldWithDefault(msg, 2, 0),
    snapshotId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    handle: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.AttachSnapshotRequest}
 */
proto.dibric.portal.v1.AttachSnapshotRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.AttachSnapshotRequest;
  return proto.dibric.portal.v1.AttachSnapshotRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.AttachSnapshotRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.AttachSnapshotRequest}
 */
proto.dibric.portal.v1.AttachSnapshotRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeploymentSlotId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSnapshotId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setHandle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.AttachSnapshotRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.AttachSnapshotRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.AttachSnapshotRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.AttachSnapshotRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeploymentSlotId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getSnapshotId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getHandle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string deployment_slot_id = 1;
 * @return {string}
 */
proto.dibric.portal.v1.AttachSnapshotRequest.prototype.getDeploymentSlotId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.AttachSnapshotRequest} returns this
 */
proto.dibric.portal.v1.AttachSnapshotRequest.prototype.setDeploymentSlotId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 version = 2;
 * @return {number}
 */
proto.dibric.portal.v1.AttachSnapshotRequest.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.portal.v1.AttachSnapshotRequest} returns this
 */
proto.dibric.portal.v1.AttachSnapshotRequest.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string snapshot_id = 3;
 * @return {string}
 */
proto.dibric.portal.v1.AttachSnapshotRequest.prototype.getSnapshotId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.AttachSnapshotRequest} returns this
 */
proto.dibric.portal.v1.AttachSnapshotRequest.prototype.setSnapshotId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string handle = 4;
 * @return {string}
 */
proto.dibric.portal.v1.AttachSnapshotRequest.prototype.getHandle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.AttachSnapshotRequest} returns this
 */
proto.dibric.portal.v1.AttachSnapshotRequest.prototype.setHandle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.AttachSnapshotResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.AttachSnapshotResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.AttachSnapshotResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.AttachSnapshotResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.AttachSnapshotResponse}
 */
proto.dibric.portal.v1.AttachSnapshotResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.AttachSnapshotResponse;
  return proto.dibric.portal.v1.AttachSnapshotResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.AttachSnapshotResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.AttachSnapshotResponse}
 */
proto.dibric.portal.v1.AttachSnapshotResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.AttachSnapshotResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.AttachSnapshotResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.AttachSnapshotResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.AttachSnapshotResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 version = 1;
 * @return {number}
 */
proto.dibric.portal.v1.AttachSnapshotResponse.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.portal.v1.AttachSnapshotResponse} returns this
 */
proto.dibric.portal.v1.AttachSnapshotResponse.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.ListSnapshotsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.ListSnapshotsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.ListSnapshotsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.ListSnapshotsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filterWorkspaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    filterProjectId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.ListSnapshotsRequest}
 */
proto.dibric.portal.v1.ListSnapshotsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.ListSnapshotsRequest;
  return proto.dibric.portal.v1.ListSnapshotsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.ListSnapshotsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.ListSnapshotsRequest}
 */
proto.dibric.portal.v1.ListSnapshotsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilterWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilterProjectId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.ListSnapshotsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.ListSnapshotsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.ListSnapshotsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.ListSnapshotsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilterWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFilterProjectId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string filter_workspace_id = 1;
 * @return {string}
 */
proto.dibric.portal.v1.ListSnapshotsRequest.prototype.getFilterWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.ListSnapshotsRequest} returns this
 */
proto.dibric.portal.v1.ListSnapshotsRequest.prototype.setFilterWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string filter_project_id = 2;
 * @return {string}
 */
proto.dibric.portal.v1.ListSnapshotsRequest.prototype.getFilterProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.ListSnapshotsRequest} returns this
 */
proto.dibric.portal.v1.ListSnapshotsRequest.prototype.setFilterProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dibric.portal.v1.ListSnapshotsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.ListSnapshotsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.ListSnapshotsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.ListSnapshotsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.ListSnapshotsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    snapshotsList: jspb.Message.toObjectList(msg.getSnapshotsList(),
    proto.dibric.portal.v1.Snapshot.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.ListSnapshotsResponse}
 */
proto.dibric.portal.v1.ListSnapshotsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.ListSnapshotsResponse;
  return proto.dibric.portal.v1.ListSnapshotsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.ListSnapshotsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.ListSnapshotsResponse}
 */
proto.dibric.portal.v1.ListSnapshotsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dibric.portal.v1.Snapshot;
      reader.readMessage(value,proto.dibric.portal.v1.Snapshot.deserializeBinaryFromReader);
      msg.addSnapshots(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.ListSnapshotsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.ListSnapshotsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.ListSnapshotsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.ListSnapshotsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSnapshotsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.dibric.portal.v1.Snapshot.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Snapshot snapshots = 1;
 * @return {!Array<!proto.dibric.portal.v1.Snapshot>}
 */
proto.dibric.portal.v1.ListSnapshotsResponse.prototype.getSnapshotsList = function() {
  return /** @type{!Array<!proto.dibric.portal.v1.Snapshot>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dibric.portal.v1.Snapshot, 1));
};


/**
 * @param {!Array<!proto.dibric.portal.v1.Snapshot>} value
 * @return {!proto.dibric.portal.v1.ListSnapshotsResponse} returns this
*/
proto.dibric.portal.v1.ListSnapshotsResponse.prototype.setSnapshotsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.dibric.portal.v1.Snapshot=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dibric.portal.v1.Snapshot}
 */
proto.dibric.portal.v1.ListSnapshotsResponse.prototype.addSnapshots = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.dibric.portal.v1.Snapshot, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dibric.portal.v1.ListSnapshotsResponse} returns this
 */
proto.dibric.portal.v1.ListSnapshotsResponse.prototype.clearSnapshotsList = function() {
  return this.setSnapshotsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.DeploymentSlotHeader.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.DeploymentSlotHeader.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.DeploymentSlotHeader} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.DeploymentSlotHeader.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    version: jspb.Message.getFieldWithDefault(msg, 2, 0),
    name: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.DeploymentSlotHeader}
 */
proto.dibric.portal.v1.DeploymentSlotHeader.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.DeploymentSlotHeader;
  return proto.dibric.portal.v1.DeploymentSlotHeader.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.DeploymentSlotHeader} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.DeploymentSlotHeader}
 */
proto.dibric.portal.v1.DeploymentSlotHeader.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.DeploymentSlotHeader.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.DeploymentSlotHeader.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.DeploymentSlotHeader} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.DeploymentSlotHeader.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.dibric.portal.v1.DeploymentSlotHeader.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.DeploymentSlotHeader} returns this
 */
proto.dibric.portal.v1.DeploymentSlotHeader.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 version = 2;
 * @return {number}
 */
proto.dibric.portal.v1.DeploymentSlotHeader.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.portal.v1.DeploymentSlotHeader} returns this
 */
proto.dibric.portal.v1.DeploymentSlotHeader.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.dibric.portal.v1.DeploymentSlotHeader.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.DeploymentSlotHeader} returns this
 */
proto.dibric.portal.v1.DeploymentSlotHeader.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.DeploymentSlot.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.DeploymentSlot.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.DeploymentSlot} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.DeploymentSlot.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    version: jspb.Message.getFieldWithDefault(msg, 2, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    snapshotId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    snapshot: (f = msg.getSnapshot()) && proto.dibric.portal.v1.Snapshot.toObject(includeInstance, f),
    handle: jspb.Message.getFieldWithDefault(msg, 8, ""),
    url: jspb.Message.getFieldWithDefault(msg, 9, ""),
    metadataMap: (f = msg.getMetadataMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.DeploymentSlot}
 */
proto.dibric.portal.v1.DeploymentSlot.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.DeploymentSlot;
  return proto.dibric.portal.v1.DeploymentSlot.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.DeploymentSlot} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.DeploymentSlot}
 */
proto.dibric.portal.v1.DeploymentSlot.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSnapshotId(value);
      break;
    case 7:
      var value = new proto.dibric.portal.v1.Snapshot;
      reader.readMessage(value,proto.dibric.portal.v1.Snapshot.deserializeBinaryFromReader);
      msg.setSnapshot(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setHandle(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 10:
      var value = msg.getMetadataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.DeploymentSlot.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.DeploymentSlot.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.DeploymentSlot} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.DeploymentSlot.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSnapshotId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSnapshot();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.dibric.portal.v1.Snapshot.serializeBinaryToWriter
    );
  }
  f = message.getHandle();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getMetadataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(10, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.dibric.portal.v1.DeploymentSlot.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.DeploymentSlot} returns this
 */
proto.dibric.portal.v1.DeploymentSlot.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 version = 2;
 * @return {number}
 */
proto.dibric.portal.v1.DeploymentSlot.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.portal.v1.DeploymentSlot} returns this
 */
proto.dibric.portal.v1.DeploymentSlot.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string workspace_id = 3;
 * @return {string}
 */
proto.dibric.portal.v1.DeploymentSlot.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.DeploymentSlot} returns this
 */
proto.dibric.portal.v1.DeploymentSlot.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.dibric.portal.v1.DeploymentSlot.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.DeploymentSlot} returns this
 */
proto.dibric.portal.v1.DeploymentSlot.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.dibric.portal.v1.DeploymentSlot.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.DeploymentSlot} returns this
 */
proto.dibric.portal.v1.DeploymentSlot.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string snapshot_id = 6;
 * @return {string}
 */
proto.dibric.portal.v1.DeploymentSlot.prototype.getSnapshotId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.DeploymentSlot} returns this
 */
proto.dibric.portal.v1.DeploymentSlot.prototype.setSnapshotId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional Snapshot snapshot = 7;
 * @return {?proto.dibric.portal.v1.Snapshot}
 */
proto.dibric.portal.v1.DeploymentSlot.prototype.getSnapshot = function() {
  return /** @type{?proto.dibric.portal.v1.Snapshot} */ (
    jspb.Message.getWrapperField(this, proto.dibric.portal.v1.Snapshot, 7));
};


/**
 * @param {?proto.dibric.portal.v1.Snapshot|undefined} value
 * @return {!proto.dibric.portal.v1.DeploymentSlot} returns this
*/
proto.dibric.portal.v1.DeploymentSlot.prototype.setSnapshot = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.DeploymentSlot} returns this
 */
proto.dibric.portal.v1.DeploymentSlot.prototype.clearSnapshot = function() {
  return this.setSnapshot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.DeploymentSlot.prototype.hasSnapshot = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string handle = 8;
 * @return {string}
 */
proto.dibric.portal.v1.DeploymentSlot.prototype.getHandle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.DeploymentSlot} returns this
 */
proto.dibric.portal.v1.DeploymentSlot.prototype.setHandle = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string url = 9;
 * @return {string}
 */
proto.dibric.portal.v1.DeploymentSlot.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.DeploymentSlot} returns this
 */
proto.dibric.portal.v1.DeploymentSlot.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * map<string, string> metadata = 10;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.dibric.portal.v1.DeploymentSlot.prototype.getMetadataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 10, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.dibric.portal.v1.DeploymentSlot} returns this
 */
proto.dibric.portal.v1.DeploymentSlot.prototype.clearMetadataMap = function() {
  this.getMetadataMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dibric.portal.v1.Snapshot.repeatedFields_ = [9,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.Snapshot.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.Snapshot.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.Snapshot} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.Snapshot.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    projectId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    projectName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    created: jspb.Message.getFieldWithDefault(msg, 5, 0),
    name: jspb.Message.getFieldWithDefault(msg, 6, ""),
    description: jspb.Message.getFieldWithDefault(msg, 7, ""),
    metadataMap: (f = msg.getMetadataMap()) ? f.toObject(includeInstance, undefined) : [],
    deploymentSlotsList: jspb.Message.toObjectList(msg.getDeploymentSlotsList(),
    proto.dibric.portal.v1.DeploymentSlotHeader.toObject, includeInstance),
    handlesList: jspb.Message.toObjectList(msg.getHandlesList(),
    proto.dibric.portal.v1.SnapshotHandle.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.Snapshot}
 */
proto.dibric.portal.v1.Snapshot.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.Snapshot;
  return proto.dibric.portal.v1.Snapshot.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.Snapshot} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.Snapshot}
 */
proto.dibric.portal.v1.Snapshot.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreated(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 8:
      var value = msg.getMetadataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 9:
      var value = new proto.dibric.portal.v1.DeploymentSlotHeader;
      reader.readMessage(value,proto.dibric.portal.v1.DeploymentSlotHeader.deserializeBinaryFromReader);
      msg.addDeploymentSlots(value);
      break;
    case 10:
      var value = new proto.dibric.portal.v1.SnapshotHandle;
      reader.readMessage(value,proto.dibric.portal.v1.SnapshotHandle.deserializeBinaryFromReader);
      msg.addHandles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.Snapshot.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.Snapshot.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.Snapshot} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.Snapshot.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProjectName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreated();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getMetadataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(8, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getDeploymentSlotsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.dibric.portal.v1.DeploymentSlotHeader.serializeBinaryToWriter
    );
  }
  f = message.getHandlesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.dibric.portal.v1.SnapshotHandle.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.dibric.portal.v1.Snapshot.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.Snapshot} returns this
 */
proto.dibric.portal.v1.Snapshot.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string workspace_id = 2;
 * @return {string}
 */
proto.dibric.portal.v1.Snapshot.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.Snapshot} returns this
 */
proto.dibric.portal.v1.Snapshot.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string project_id = 3;
 * @return {string}
 */
proto.dibric.portal.v1.Snapshot.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.Snapshot} returns this
 */
proto.dibric.portal.v1.Snapshot.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string project_name = 4;
 * @return {string}
 */
proto.dibric.portal.v1.Snapshot.prototype.getProjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.Snapshot} returns this
 */
proto.dibric.portal.v1.Snapshot.prototype.setProjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 created = 5;
 * @return {number}
 */
proto.dibric.portal.v1.Snapshot.prototype.getCreated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.portal.v1.Snapshot} returns this
 */
proto.dibric.portal.v1.Snapshot.prototype.setCreated = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string name = 6;
 * @return {string}
 */
proto.dibric.portal.v1.Snapshot.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.Snapshot} returns this
 */
proto.dibric.portal.v1.Snapshot.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string description = 7;
 * @return {string}
 */
proto.dibric.portal.v1.Snapshot.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.Snapshot} returns this
 */
proto.dibric.portal.v1.Snapshot.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * map<string, string> metadata = 8;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.dibric.portal.v1.Snapshot.prototype.getMetadataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 8, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.dibric.portal.v1.Snapshot} returns this
 */
proto.dibric.portal.v1.Snapshot.prototype.clearMetadataMap = function() {
  this.getMetadataMap().clear();
  return this;};


/**
 * repeated DeploymentSlotHeader deployment_slots = 9;
 * @return {!Array<!proto.dibric.portal.v1.DeploymentSlotHeader>}
 */
proto.dibric.portal.v1.Snapshot.prototype.getDeploymentSlotsList = function() {
  return /** @type{!Array<!proto.dibric.portal.v1.DeploymentSlotHeader>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dibric.portal.v1.DeploymentSlotHeader, 9));
};


/**
 * @param {!Array<!proto.dibric.portal.v1.DeploymentSlotHeader>} value
 * @return {!proto.dibric.portal.v1.Snapshot} returns this
*/
proto.dibric.portal.v1.Snapshot.prototype.setDeploymentSlotsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.dibric.portal.v1.DeploymentSlotHeader=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dibric.portal.v1.DeploymentSlotHeader}
 */
proto.dibric.portal.v1.Snapshot.prototype.addDeploymentSlots = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.dibric.portal.v1.DeploymentSlotHeader, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dibric.portal.v1.Snapshot} returns this
 */
proto.dibric.portal.v1.Snapshot.prototype.clearDeploymentSlotsList = function() {
  return this.setDeploymentSlotsList([]);
};


/**
 * repeated SnapshotHandle handles = 10;
 * @return {!Array<!proto.dibric.portal.v1.SnapshotHandle>}
 */
proto.dibric.portal.v1.Snapshot.prototype.getHandlesList = function() {
  return /** @type{!Array<!proto.dibric.portal.v1.SnapshotHandle>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dibric.portal.v1.SnapshotHandle, 10));
};


/**
 * @param {!Array<!proto.dibric.portal.v1.SnapshotHandle>} value
 * @return {!proto.dibric.portal.v1.Snapshot} returns this
*/
proto.dibric.portal.v1.Snapshot.prototype.setHandlesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.dibric.portal.v1.SnapshotHandle=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dibric.portal.v1.SnapshotHandle}
 */
proto.dibric.portal.v1.Snapshot.prototype.addHandles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.dibric.portal.v1.SnapshotHandle, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dibric.portal.v1.Snapshot} returns this
 */
proto.dibric.portal.v1.Snapshot.prototype.clearHandlesList = function() {
  return this.setHandlesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dibric.portal.v1.SnapshotHandle.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.SnapshotHandle.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.SnapshotHandle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.SnapshotHandle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.SnapshotHandle.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    outputVariablesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.SnapshotHandle}
 */
proto.dibric.portal.v1.SnapshotHandle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.SnapshotHandle;
  return proto.dibric.portal.v1.SnapshotHandle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.SnapshotHandle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.SnapshotHandle}
 */
proto.dibric.portal.v1.SnapshotHandle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addOutputVariables(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.SnapshotHandle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.SnapshotHandle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.SnapshotHandle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.SnapshotHandle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOutputVariablesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.dibric.portal.v1.SnapshotHandle.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.SnapshotHandle} returns this
 */
proto.dibric.portal.v1.SnapshotHandle.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string output_variables = 3;
 * @return {!Array<string>}
 */
proto.dibric.portal.v1.SnapshotHandle.prototype.getOutputVariablesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.dibric.portal.v1.SnapshotHandle} returns this
 */
proto.dibric.portal.v1.SnapshotHandle.prototype.setOutputVariablesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.dibric.portal.v1.SnapshotHandle} returns this
 */
proto.dibric.portal.v1.SnapshotHandle.prototype.addOutputVariables = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dibric.portal.v1.SnapshotHandle} returns this
 */
proto.dibric.portal.v1.SnapshotHandle.prototype.clearOutputVariablesList = function() {
  return this.setOutputVariablesList([]);
};


goog.object.extend(exports, proto.dibric.portal.v1);
