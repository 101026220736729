import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Button,
  ButtonGroup,
  Fab,
  IconButton,
  Paper,
  Popover,
  styled,
  Toolbar,
  Typography,
  useTheme
} from '@mui/material';
import { useState } from 'react';
import logo from './assets/text-logo.png';
import { User, useUser } from './hooks/ProvideUser';

const Logo = styled('img')({
  height: 30,
});

type AccountPopoverProps = {
  id: string;
  open: boolean;
  anchorEl: Element | null;
  onClose: () => void;
  user: User;
}

function AccountPopover({ id, open, anchorEl, onClose, user }: AccountPopoverProps) {
  function handleSignOut() {
    fetch('/api/signout', {
      method: 'POST',
      credentials: 'include',
    })
      .then(res => res.text())
      .then(uri => location.href = uri);
  }

  return (
    <Popover
      id={id}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      anchorEl={anchorEl}>
      <Paper elevation={16} style={{ width: 300 }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: 3,
          pb: 3,
        }}>
          <Box sx={{ marginBottom: 1 }}>
            <Badge badgeContent={(
              <Fab color={'default'}
                sx={{
                  backgroundColor: 'background.default',
                }} size={'small'}>
                <SettingsIcon />
              </Fab>
            )} anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }} overlap={'circular'}>
              <Avatar src={user?.picture}
                sx={{
                  bgcolor: 'primary.main',
                  width: 100,
                  height: 100,
                }}>
                {!user?.picture && user?.givenName.charAt(0)}
              </Avatar>
            </Badge>
          </Box>
          <div>
            <Typography variant={'subtitle2'} align={'center'}>
              {user?.name}
            </Typography>
            <Typography variant={'caption'} align={'center'}>
              {user?.email}
            </Typography>
          </div>
          <Button onClick={handleSignOut} variant="outlined" sx={{ margin: 2 }} color="inherit">
            Sign out
          </Button>
          <ButtonGroup variant="text" aria-label="text button group" color={'inherit'} size={'small'}>
            <Button>
              Privacy Policy
            </Button>
            <Button>
              User Agreement
            </Button>
          </ButtonGroup>
        </Box>
      </Paper>
    </Popover>
  );
}

type TopBarProps =
  {
    open: boolean;
    drawerWidth: number;
    onOpen: () => void;
  }

export function TopBar({ open, drawerWidth, onOpen }: TopBarProps) {
  const theme = useTheme();
  const user = useUser();
  const [userAnchor, setUserAnchor] = useState<Element | null>(null);

  return (
    <AppBar position="fixed" color={'default'} sx={
      {
        backdropFilter: 'blur(8px)',
        backgroundColor: 'rgba(255, 255, 255, 0.72)',
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
          marginLeft: drawerWidth,
          width: `calc(100% - ${drawerWidth}px)`,
          transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }),
      }
    }>
      <Toolbar sx={{
        padding: 1,
      }}>
        <IconButton
          onClick={onOpen}
          aria-label="open drawer"
          edge="start"
          sx={{
            marginRight: 5,
            ...(open && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Logo src={logo} alt="Company logotype" />
        <div style={{ flex: 1 }} />
        <Avatar src={user?.picture}
          onClick={(event) => setUserAnchor(event.currentTarget)}
          sx={{
            bgcolor: 'primary.main',
            ':hover': {
              boxShadow: 10,
              cursor: 'pointer',
            },
          }}>
          {!user?.picture && user?.givenName.charAt(0)}
        </Avatar>
        <AccountPopover id={'user-popover'}
          open={Boolean(userAnchor)}
          user={user}
          anchorEl={userAnchor}
          onClose={() => setUserAnchor(null)} />
      </Toolbar>
    </AppBar>
  );
}
