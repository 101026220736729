import { Card, CardActionArea, CardContent, CardMedia, SxProps, Typography } from '@mui/material';
import { To } from 'react-router';
import { Link } from 'react-router-dom';
import defaultIllustration from '../assets/graph-illustration.jpg';

type WorkspaceCardProps = {
  name: string;
  description: string;
  image?: string;
  to?: To;
  sx: SxProps;
}

export function WorkspaceCard({ name, description, image, sx, to }: WorkspaceCardProps) {
  const content = (
    <>
      <CardMedia
        component="img"
        height={150}
        image={image ? image : defaultIllustration}
        alt="Add illustration"
      />
      <CardContent>
        <Typography style={{ fontWeight: 500, wordBreak: 'break-word' }}>
          {name}
        </Typography>
        <Typography variant={'caption'} style={{ wordBreak: 'break-word' }}>
          {description}
        </Typography>
      </CardContent>
    </>
  );

  return (
    <Card sx={{ ...sx, width: 200, height: 250 }} elevation={10}>
      {to && (
        <CardActionArea component={Link} to={to}>
          {content}
        </CardActionArea>
      )}
      {!to && content}
    </Card>
  );
}
