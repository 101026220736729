import InputIcon from '@mui/icons-material/Input';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Badge, IconButton, Paper, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { NodeProps, useUpdateNodeInternals } from 'react-flow-renderer';
import { Node, NodeHandle } from '../../services/workspace';
import { CustomHandle, NodeConfigData } from './utils';

export interface InputNodeData {
  name: string;
  description: string;
  onSettings: (data: NodeConfigData) => void;
  errors: Node.ErrorStatus[];
  outputHandles: NodeHandle[];
  readonly: boolean;
}

export function InputNode({ id, data, selected, dragging, sourcePosition, targetPosition }: NodeProps<InputNodeData>) {
  const theme = useTheme();

  const updateNodeInternals = useUpdateNodeInternals();
  useEffect(() => {
    updateNodeInternals(id);
  }, [id, updateNodeInternals, data.outputHandles.length]);

  const diameter = 70;
  return (
    <Paper
      style={{
        height: diameter,
        width: diameter,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        border: selected ? `1px solid ${theme.palette.primary.light}50` : 'none',
      }}
      elevation={selected ? 16 : 1}
    >
      <IconButton
        size="small"
        title="Configure input node"
        disabled={dragging || data.readonly}
        onClick={() => data.onSettings({ type: Node.TaskCase.INPUT, nodeId: id })}
      >
        <Badge
          badgeContent={
            (<PriorityHighIcon sx={{
              fontSize: 'small',
              borderRadius: '50%',
              backgroundColor: 'secondary.main',
              color: 'secondary.contrastText',
            }} />)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }} invisible={data.errors.length === 0}>
          <InputIcon />
        </Badge>
      </IconButton>
      {data.outputHandles.map((handle, index) => (
        <CustomHandle
          key={handle.getId()}
          type="source"
          outputHandle={handle}
          sourcePosition={sourcePosition}
          targetPosition={targetPosition}
          color={handle.getType() === NodeHandle.Type.SUCCESS ?
            theme.palette.primary.main :
            theme.palette.secondary.main}
          index={index}
          total={data.outputHandles.length}
          length={diameter}
        />
      ))}
    </Paper>
  );
}

