import { Chip, Stack } from '@mui/material';

export interface TabItem {
  label: string;
  value: string;
  icon?: React.ReactElement<string>;
}

type EditorTabsProps = {
  value: string;
  onValueChanged: (value: string) => void;
  tabs: TabItem[];
  height: number;
}

export function EditorTabs({ value, onValueChanged, tabs, height }: EditorTabsProps) {

  return (
    <Stack
      direction="row"
      sx={{
        '& > .MuiChip-colorPrimary:hover': {
          backgroundColor: 'white !important',
        },
      }}
    >
      {tabs.map((item) => (
        <Chip label={item.label}
          key={item.value}
          variant={'outlined'}
          icon={item.icon}
          sx={[
            {
              backgroundColor: 'background.paper',
              height: height,
              width: 150,
              pr: 2,
              pl: 3,
              border: 0,
              borderRadius: 0,
            },
            value === item.value && {
              zIndex: 4,
              boxShadow: 'rgb(145 158 171 / 20%) 5px -4px 8px -3px, rgb(145 158 171 / 14%) 6px -5px 5px -3px, rgb(145 158 171 / 12%) -7px -7px 6px 0px',
            },
          ]}
          color={value === item.value ? 'primary' : 'default'}
          onClick={() => onValueChanged(item.value)} />
      ))}
    </Stack>
  );
}
