import { useState } from 'react';
import { ResizableDrawer } from '../../drawer/ResizableDrawer';
import { Node } from '../../services/workspace';
import { ExtensionSettings } from './task-settings/ExtensionSettings';
import { InputSettings } from './task-settings/InputSettings';
import { Button, Snackbar } from '@mui/material';

type ConfigDrawerProps = {
  open: boolean;
  type: Node.TaskCase;
  workspaceId: string;
  itemId: string;
  nodeId: string;
  onClose: () => void;
}

const minDrawerWidth = 590;

export function NodeConfigDrawer({ open, type, workspaceId, itemId, nodeId, onClose }: ConfigDrawerProps) {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
  const [isConfirmSnackOpen, setIsConfirmSnackOpen] = useState<boolean>(false);

  function handleClose() {
    if (type === Node.TaskCase.INPUT && hasUnsavedChanges) {
      setIsConfirmSnackOpen(true);
    } else {
      setIsConfirmSnackOpen(false);
      onClose();
    }
  }

  let content = (<></>);
  switch (type) {
    case Node.TaskCase.TASK_NOT_SET:
      break;
    case Node.TaskCase.EXTENSION_TASK:
      content = (
        <ExtensionSettings
          itemId={itemId}
          workspaceId={workspaceId}
          nodeId={nodeId}
        />
      );
      break;
    case Node.TaskCase.INPUT:
      content = (
        <InputSettings
          itemId={itemId}
          workspaceId={workspaceId}
          nodeId={nodeId}
          setHasUnsavedChanges={setHasUnsavedChanges}
          onCancel={handleClose}
        />
      );
      break;
    default:
      throw new Error('Invalid node config');
  }
  return (
    <>
      <Snackbar
        open={isConfirmSnackOpen && hasUnsavedChanges}
        message="You have unsaved changes. Are you sure you want to close?"
        action={[
          <Button
            key="close"
            color="inherit"
            size="small"
            onClick={() => setIsConfirmSnackOpen(false)}
          >
            Cancel
          </Button>,
          <Button
            key="confirm"
            color="secondary"
            size="small"
            onClick={() => {
              setIsConfirmSnackOpen(false);
              onClose();
            }}
          >
            Yes, discard changes
          </Button>,
        ]}
      />
      <ResizableDrawer
        open={open}
        showFoldOptions={true}
        minWidth={minDrawerWidth}
        onClose={handleClose}
      >
        {content}
      </ResizableDrawer>
    </>
  );
}
