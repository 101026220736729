import { AddAPhoto, PlayArrow } from '@mui/icons-material';
import cc from 'classcat';
import { DragEvent, useRef, useState } from 'react';
import ReactFlow, {
  Background,
  BackgroundVariant,
  Connection,
  ControlButton,
  Controls,
  Edge,
  EdgeChange,
  MiniMap,
  Node,
  NodeChange,
  NodePositionChange,
  ReactFlowInstance
} from 'react-flow-renderer';
import { Link } from 'react-router-dom';
import { ApplyPatchRequest } from '../../protos/portal/v1/project_service_pb';
import {
  AddEdges,
  AddNode,
  AddNodes,
  Edge as DibricEdge,
  ModifyPosition,
  ModifyPositions,
  NodeHandlePosition,
  NodePosition,
  RemoveElements
} from '../../services/project';
import { CreateSnapshotDrawer } from '../deployments/CreateSnapshotDrawer';
import { customNodes } from './CustomNodes';
import { TaskDropMenu } from './TaskDropMenu';
import { NodeDropData, TaskDropData } from './utils';

interface TaskMenuData {
  x: number;
  y: number;
  tasks: TaskDropData[];
}

type EditorProps = {
  workspaceId: string;
  projectId: string;
  nodes: Node[],
  edges: Edge[],
  onApplyPatch: (message: ApplyPatchRequest) => void;
  onNodeChanges: (changes: NodeChange[]) => void;
  onEdgeChanges: (changes: EdgeChange[]) => void;
  onSnapshotCreated: () => void;
}

export function Editor({
  workspaceId,
  projectId,
  nodes,
  edges,
  onApplyPatch,
  onNodeChanges,
  onEdgeChanges,
  onSnapshotCreated,
}: EditorProps) {
  const reactFlowWrapper = useRef<HTMLDivElement>(null);
  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance | null>(null);
  const [tasksMenu, setTasksMenu] = useState<TaskMenuData | null>(null); // x, y position
  const [isCreateSnapshotSidePanelOpen, setIsCreateSnapshotSidePanelOpen] = useState(false);

  function handleEdgesDelete(edges: Edge[]) {
    const request = new ApplyPatchRequest()
      .setRemoveElements(new RemoveElements()
        .setEdgesList(
          edges.map(flowEdge => new DibricEdge()
            .setSource(flowEdge.source as string)
            .setTarget(flowEdge.target as string)
            .setSourceHandle(flowEdge.sourceHandle as string)
            .setTargetHandle(flowEdge.targetHandle as string))));
    onApplyPatch(request);
  }

  function handleNodesDelete(nodes: Node[]) {
    if (nodes.length === 0) {
      return;
    }
    const request = new ApplyPatchRequest()
      .setRemoveElements(new RemoveElements()
        .setNodesList(nodes.map(node => node.id)));
    onApplyPatch(request);
  }

  function handleConnect(connection: Connection) {
    if (!reactFlowInstance) {
      return;
    }

    const request = new ApplyPatchRequest()
      .setAddEdges(new AddEdges().setEdgesList([
        new DibricEdge().setSource(connection.source as string)
          .setTarget(connection.target as string)
          .setSourceHandle(connection.sourceHandle as string)
          .setTargetHandle(connection.targetHandle as string),
      ]));
    onApplyPatch(request);
  }

  function handleEdgesChange(changes: EdgeChange[]) {
    onEdgeChanges(changes);
  }

  function handleDragOver(event: DragEvent<HTMLDivElement>) {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }

  function handlePaneClick() {
    setTasksMenu(null);
  }

  function handleTaskClicked(task: TaskDropData) {
    if (!reactFlowInstance) {
      return;
    }

    setTasksMenu(null);

    const instance = reactFlowInstance as ReactFlowInstance;
    const menu = tasksMenu as TaskMenuData;

    const projected = instance.project({ x: menu.x, y: menu.y });
    const addNode = new AddNode()
      .setPosition(new NodePosition().setX(projected.x).setY(projected.y))
      .setSourcePosition(NodeHandlePosition.RIGHT)
      .setTargetPosition(NodeHandlePosition.LEFT)
      .setType(task.type)
      .setName(task.name)
      .setDescription(task.description ? task.description : '');

    if (task.extensionType) {
      addNode.setTaskId(task.extensionTaskId as string)
        .setExtensionId(task.extensionId as string)
        .setExtensionVersion(task.extensionVersion as string);
    }

    const request = new ApplyPatchRequest().setAddNodes(new AddNodes()
      .setNodesList([addNode]));
    onApplyPatch(request);
  }

  function handleDrop(event: DragEvent<HTMLDivElement>) {
    event.preventDefault();
    if (!reactFlowWrapper?.current) {
      console.log('could not find bounds');
      return;
    }
    const instance = reactFlowInstance as ReactFlowInstance;
    const bounds = reactFlowWrapper.current.getBoundingClientRect();
    const position = instance.project({
      x: event.clientX - bounds.left,
      y: event.clientY - bounds.top,
    });

    const serializedData = event.dataTransfer.getData('application/dibric-graph-editor');
    if (typeof serializedData === 'undefined' || !serializedData) {
      return;
    }
    const dropData = JSON.parse(serializedData) as NodeDropData;
    if (dropData.tasks.length === 0) {
      throw new Error('The drop data must contains at least one task to be added to the graph');
    }

    if (dropData.tasks && dropData.tasks.length > 1) {
      setTasksMenu({
        x: event.clientX - bounds.left,
        y: event.clientY - bounds.top,
        tasks: dropData.tasks,
      });
      return;
    }

    const task = dropData.tasks[0] as TaskDropData;

    const addNode = new AddNode()
      .setPosition(new NodePosition().setX(position.x).setY(position.y))
      .setSourcePosition(NodeHandlePosition.RIGHT)
      .setTargetPosition(NodeHandlePosition.LEFT)
      .setType(task.type)
      .setName(task.name)
      .setDescription(task.description);

    // In case we only have a single task, we can add it immediately
    if (task.extensionType) {
      const task = dropData.tasks[0];
      addNode.setTaskId(task.extensionTaskId as string)
        .setExtensionId(task.extensionId as string)
        .setExtensionVersion(task.extensionVersion as string);
    }

    const request = new ApplyPatchRequest()
      .setAddNodes(new AddNodes()
        .setNodesList([addNode]));
    onApplyPatch(request);
  }

  function handleNodesChange(changes: NodeChange[]) {
    onNodeChanges(changes);

    if (changes.length === 0) {
      return;
    }

    if (!reactFlowInstance) {
      return;
    }

    const positions: ModifyPosition[] = changes.filter(change => {
      if (change.type !== 'position') {
        return false;
      }
      const positionChange = change as NodePositionChange;
      return !positionChange.dragging;
    }).map((change) => {
      const positionChange = change as NodePositionChange;
      const node = reactFlowInstance.getNode(positionChange.id) as Node;
      return new ModifyPosition()
        .setId(positionChange.id)
        .setPosition(new NodePosition()
          .setX(node.position.x)
          .setY(node.position.y));
    });
    if (positions.length === 0) {
      return;
    }

    const request = new ApplyPatchRequest();
    request.setModifyPositions(new ModifyPositions().setPositionsList(positions));
    onApplyPatch(request);
  }

  function handleSnapshotCreated() {
    setIsCreateSnapshotSidePanelOpen(false);
    onSnapshotCreated();
  }

  return (
    <>
      <div ref={reactFlowWrapper} style={{ height: '100%', width: '100%' }}>
        <ReactFlow
          minZoom={0.1}
          maxZoom={3}
          nodes={nodes}
          edges={edges}
          nodeTypes={customNodes}
          onPaneClick={handlePaneClick}
          onDragOver={handleDragOver}
          onNodesDelete={handleNodesDelete}
          onEdgesDelete={handleEdgesDelete}
          onNodesChange={handleNodesChange}
          onEdgesChange={handleEdgesChange}
          onConnect={handleConnect}
          onDrop={handleDrop}
          onInit={setReactFlowInstance}
          fitView
        >
          <MiniMap />
          <Background
            variant={BackgroundVariant.Dots}
            gap={8}
            color={'#a7b0b8'} />
          <Controls>
            <Link
              className={cc(['react-flow__controls-button'])}
              title="Play"
              to={{ pathname: 'run' }}
              target="_blank"
            >
              <PlayArrow />
            </Link>
            <ControlButton
              onClick={() => setIsCreateSnapshotSidePanelOpen(true)}
              title="Create Snapshot"
            >
              <AddAPhoto />
            </ControlButton>
          </Controls>
          {tasksMenu && (
            <TaskDropMenu
              x={tasksMenu.x}
              y={tasksMenu.y}
              tasks={tasksMenu.tasks}
              onClick={handleTaskClicked}
            />
          )}
          <CreateSnapshotDrawer
            workspaceId={workspaceId}
            projectId={projectId}
            open={isCreateSnapshotSidePanelOpen}
            onSnapshotCreated={handleSnapshotCreated}
            onClose={() => setIsCreateSnapshotSidePanelOpen(false)}
          />
        </ReactFlow>
      </div>
    </>
  );
}