import CloseIcon from '@mui/icons-material/Close';
import ImageIcon from '@mui/icons-material/Image';
import {
  Backdrop,
  Button,
  Chip,
  CircularProgress,
  Fade,
  IconButton,
  LinearProgress,
  Modal,
  Paper,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { RpcError } from 'grpc-web';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UpdateWorkspaceRequest, Workspace, updateWorkspace } from '../services/workspace';
import { WorkspaceCard } from './WorkspaceCard';

type UpdateWorkspaceDialogProps = {
  workspaceId: string;
  defaultName: string;
  defaultDescription: string;
  defaultImage: string;
  currentVersion: string;
  open: boolean;
  onClose: (item: Workspace | null) => void;
}

export function UpdateWorkspaceDialog({
  workspaceId,
  defaultName,
  defaultDescription,
  defaultImage,
  currentVersion,
  open,
  onClose,
}: UpdateWorkspaceDialogProps) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [image, setImage] = useState<string>('');
  const [nameError, setNameError] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    setName(defaultName);
    setDescription(defaultDescription);
    setImage(defaultImage);
  }, [defaultImage, defaultDescription, defaultName]);

  async function handleFileUpload(files: FileList | null) {
    if (!files || !files[0]) {
      return;
    }

    try {
      const file = files[0];
      const newImage = await new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          if (!reader.result) {
            reject('There\'s no content');
            return;
          }
          resolve(reader.result.toString());
        };
        reader.onerror = error => reject(error);
      });

      setImage(newImage);
    } catch (error: unknown) {
      setError('Could not use image. Please try again with another image');
      console.error(error);
    }
  }

  function handleNameChanged(newValue: string) {
    setNameError('');
    setName(newValue.substring(0, 40));
  }

  function handleDescriptionChanged(newValue: string) {
    setDescription(newValue.substring(0, 80));
  }

  async function handleUpdate() {
    if (!name) {
      setNameError('Workspace name is required');
      return;
    }
    const request = new UpdateWorkspaceRequest()
      .setOptions(
        new UpdateWorkspaceRequest.ResponseOptions().setIncludeItemResponse(true)
      )
      .setName(name)
      .setDescription(description)
      .setId(workspaceId)
      .setImage(image)
      .setEtag(currentVersion);
    try {
      setLoading(true);
      const response = await updateWorkspace(request);
      handleClose(response.getItem());
    } catch (error: unknown) {
      if (error instanceof RpcError && error.code === 5) {
        navigate('/workspaces');
        return;
      }
      console.log(error);
      setError('Could not update the workspace. Please try again later');
      throw error;
    } finally {
      setLoading(false);
    }
  }

  function handleClose(item?: Workspace) {
    setName(defaultName);
    setDescription(defaultDescription);
    setNameError('');
    setImage(defaultImage);
    if (item) {
      onClose(item);
    } else {
      onClose(null);
    }
  }

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      open={open}
      onClose={() => handleClose()}
      closeAfterTransition
      slots={{
        backdrop: Backdrop,
      }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Paper
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
          }}
          elevation={20}
        >
          <LinearProgress
            color="primary"
            style={{ width: '100%', height: 3, visibility: loading ? 'visible' : 'hidden' }}
            aria-label="Updating workspaces"
          />
          <IconButton style={{ float: 'right' }} onClick={() => handleClose()}>
            <CloseIcon />
          </IconButton>
          <Stack direction="column" spacing={3} sx={{ margin: 4 }}>
            <WorkspaceCard name={name} description={description} image={image}
              sx={{ alignSelf: 'center' }} />
            <Typography id="modal-title" variant="h6" component="h2">
              Update workspace
            </Typography>
            {error && (
              <Typography color="secondary">
                {error}
              </Typography>
            )}
            <TextField
              autoFocus={true}
              required
              disabled={loading}
              value={name}
              onChange={(event) => handleNameChanged(event.target.value)}
              id="extension-id"
              label="Name"
              error={Boolean(nameError)}
              helperText={nameError ? nameError : 'The workspace name'}
              variant="standard" />
            <TextField
              label="Description"
              variant="standard"
              rows={3}
              value={description}
              onChange={(event) => handleDescriptionChanged(event.target.value)}
              helperText="The workspace short description"
              disabled={loading}
              multiline
            />
            <Stack direction="row" spacing={2} alignItems="center">
              {image && (
                <Chip label="Logo" onDelete={() => setImage('')} />)}
              <Button
                style={{ flex: 1 }}
                disabled={loading}
                size={'large'}
                variant={'contained'}
                component={'label'}
                startIcon={<ImageIcon />}
                color={'primary'}>
                Logo
                <input
                  type="file"
                  onChange={event => handleFileUpload(event.target.files)}
                  value=""
                  accept="image/*"
                  hidden
                />
              </Button>
            </Stack>
            <Stack direction={'row'} sx={{ mt: 4 }}>
              <Button disabled={loading} onClick={() => handleClose()} color={'inherit'}>
                Cancel
              </Button>
              <div style={{ flex: 1 }} />
              <Button variant="contained" disabled={loading} onClick={handleUpdate}>
                {loading
                  ? <CircularProgress
                    color="primary"
                    size={30}
                    aria-label="Updating workspace"
                  />
                  : 'Update'
                }
              </Button>
            </Stack>
          </Stack>
        </Paper>
      </Fade>
    </Modal>
  );
}
