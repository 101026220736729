// source: portal/v1/extension_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.dibric.portal.v1.Author', null, global);
goog.exportSymbol('proto.dibric.portal.v1.Bugs', null, global);
goog.exportSymbol('proto.dibric.portal.v1.CreateExtensionRequest', null, global);
goog.exportSymbol('proto.dibric.portal.v1.CreateExtensionRequest.ResponseOptions', null, global);
goog.exportSymbol('proto.dibric.portal.v1.CreateExtensionResponse', null, global);
goog.exportSymbol('proto.dibric.portal.v1.CreateVersionRequest', null, global);
goog.exportSymbol('proto.dibric.portal.v1.CreateVersionRequest.ResponseOptions', null, global);
goog.exportSymbol('proto.dibric.portal.v1.CreateVersionResponse', null, global);
goog.exportSymbol('proto.dibric.portal.v1.DeleteExtensionProgress', null, global);
goog.exportSymbol('proto.dibric.portal.v1.DeleteExtensionRequest', null, global);
goog.exportSymbol('proto.dibric.portal.v1.DeleteExtensionResponse', null, global);
goog.exportSymbol('proto.dibric.portal.v1.DeleteVersionProgress', null, global);
goog.exportSymbol('proto.dibric.portal.v1.DeleteVersionRequest', null, global);
goog.exportSymbol('proto.dibric.portal.v1.DeleteVersionResponse', null, global);
goog.exportSymbol('proto.dibric.portal.v1.DeprecateProgress', null, global);
goog.exportSymbol('proto.dibric.portal.v1.DeprecateRequest', null, global);
goog.exportSymbol('proto.dibric.portal.v1.DeprecateRequest.ResponseOptions', null, global);
goog.exportSymbol('proto.dibric.portal.v1.DeprecateResponse', null, global);
goog.exportSymbol('proto.dibric.portal.v1.Deprecation', null, global);
goog.exportSymbol('proto.dibric.portal.v1.Extension', null, global);
goog.exportSymbol('proto.dibric.portal.v1.ExtensionTaskType', null, global);
goog.exportSymbol('proto.dibric.portal.v1.ExtensionVersion', null, global);
goog.exportSymbol('proto.dibric.portal.v1.GetExtensionRequest', null, global);
goog.exportSymbol('proto.dibric.portal.v1.GetExtensionRequest.ResponseOptions', null, global);
goog.exportSymbol('proto.dibric.portal.v1.GetExtensionResponse', null, global);
goog.exportSymbol('proto.dibric.portal.v1.GetVersionRequest', null, global);
goog.exportSymbol('proto.dibric.portal.v1.GetVersionRequest.ResponseOptions', null, global);
goog.exportSymbol('proto.dibric.portal.v1.GetVersionResponse', null, global);
goog.exportSymbol('proto.dibric.portal.v1.InstallExtensionRequest', null, global);
goog.exportSymbol('proto.dibric.portal.v1.InstallExtensionResponse', null, global);
goog.exportSymbol('proto.dibric.portal.v1.ListExtensionRequest', null, global);
goog.exportSymbol('proto.dibric.portal.v1.ListExtensionRequest.Filter', null, global);
goog.exportSymbol('proto.dibric.portal.v1.ListExtensionRequest.Order', null, global);
goog.exportSymbol('proto.dibric.portal.v1.ListExtensionRequest.ResponseOptions', null, global);
goog.exportSymbol('proto.dibric.portal.v1.ListExtensionResponse', null, global);
goog.exportSymbol('proto.dibric.portal.v1.ListVersionsRequest', null, global);
goog.exportSymbol('proto.dibric.portal.v1.ListVersionsRequest.Order', null, global);
goog.exportSymbol('proto.dibric.portal.v1.ListVersionsRequest.ResponseOptions', null, global);
goog.exportSymbol('proto.dibric.portal.v1.ListVersionsResponse', null, global);
goog.exportSymbol('proto.dibric.portal.v1.ProgressStatus', null, global);
goog.exportSymbol('proto.dibric.portal.v1.Repository', null, global);
goog.exportSymbol('proto.dibric.portal.v1.Repository.Type', null, global);
goog.exportSymbol('proto.dibric.portal.v1.TaskSummary', null, global);
goog.exportSymbol('proto.dibric.portal.v1.UninstallExtensionRequest', null, global);
goog.exportSymbol('proto.dibric.portal.v1.UninstallExtensionResponse', null, global);
goog.exportSymbol('proto.dibric.portal.v1.VersionSummary', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.DeleteVersionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.DeleteVersionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.DeleteVersionRequest.displayName = 'proto.dibric.portal.v1.DeleteVersionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.DeleteExtensionProgress = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.DeleteExtensionProgress, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.DeleteExtensionProgress.displayName = 'proto.dibric.portal.v1.DeleteExtensionProgress';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.DeleteVersionProgress = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.DeleteVersionProgress, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.DeleteVersionProgress.displayName = 'proto.dibric.portal.v1.DeleteVersionProgress';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.DeleteVersionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.DeleteVersionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.DeleteVersionResponse.displayName = 'proto.dibric.portal.v1.DeleteVersionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.DeprecateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.DeprecateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.DeprecateRequest.displayName = 'proto.dibric.portal.v1.DeprecateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.DeprecateRequest.ResponseOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.DeprecateRequest.ResponseOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.DeprecateRequest.ResponseOptions.displayName = 'proto.dibric.portal.v1.DeprecateRequest.ResponseOptions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.DeprecateProgress = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.DeprecateProgress, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.DeprecateProgress.displayName = 'proto.dibric.portal.v1.DeprecateProgress';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.DeprecateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.DeprecateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.DeprecateResponse.displayName = 'proto.dibric.portal.v1.DeprecateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.CreateVersionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.CreateVersionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.CreateVersionRequest.displayName = 'proto.dibric.portal.v1.CreateVersionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.CreateVersionRequest.ResponseOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.CreateVersionRequest.ResponseOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.CreateVersionRequest.ResponseOptions.displayName = 'proto.dibric.portal.v1.CreateVersionRequest.ResponseOptions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.CreateVersionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.CreateVersionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.CreateVersionResponse.displayName = 'proto.dibric.portal.v1.CreateVersionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.GetVersionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.GetVersionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.GetVersionRequest.displayName = 'proto.dibric.portal.v1.GetVersionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.GetVersionRequest.ResponseOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.GetVersionRequest.ResponseOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.GetVersionRequest.ResponseOptions.displayName = 'proto.dibric.portal.v1.GetVersionRequest.ResponseOptions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.GetVersionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.GetVersionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.GetVersionResponse.displayName = 'proto.dibric.portal.v1.GetVersionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.ListExtensionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dibric.portal.v1.ListExtensionRequest.repeatedFields_, null);
};
goog.inherits(proto.dibric.portal.v1.ListExtensionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.ListExtensionRequest.displayName = 'proto.dibric.portal.v1.ListExtensionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.ListExtensionRequest.ResponseOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.ListExtensionRequest.ResponseOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.ListExtensionRequest.ResponseOptions.displayName = 'proto.dibric.portal.v1.ListExtensionRequest.ResponseOptions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.ListExtensionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dibric.portal.v1.ListExtensionResponse.repeatedFields_, null);
};
goog.inherits(proto.dibric.portal.v1.ListExtensionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.ListExtensionResponse.displayName = 'proto.dibric.portal.v1.ListExtensionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.ListVersionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.ListVersionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.ListVersionsRequest.displayName = 'proto.dibric.portal.v1.ListVersionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.ListVersionsRequest.ResponseOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.ListVersionsRequest.ResponseOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.ListVersionsRequest.ResponseOptions.displayName = 'proto.dibric.portal.v1.ListVersionsRequest.ResponseOptions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.ListVersionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dibric.portal.v1.ListVersionsResponse.repeatedFields_, null);
};
goog.inherits(proto.dibric.portal.v1.ListVersionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.ListVersionsResponse.displayName = 'proto.dibric.portal.v1.ListVersionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.GetExtensionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.GetExtensionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.GetExtensionRequest.displayName = 'proto.dibric.portal.v1.GetExtensionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.GetExtensionRequest.ResponseOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.GetExtensionRequest.ResponseOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.GetExtensionRequest.ResponseOptions.displayName = 'proto.dibric.portal.v1.GetExtensionRequest.ResponseOptions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.GetExtensionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.GetExtensionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.GetExtensionResponse.displayName = 'proto.dibric.portal.v1.GetExtensionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.DeleteExtensionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.DeleteExtensionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.DeleteExtensionRequest.displayName = 'proto.dibric.portal.v1.DeleteExtensionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.DeleteExtensionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.DeleteExtensionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.DeleteExtensionResponse.displayName = 'proto.dibric.portal.v1.DeleteExtensionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.CreateExtensionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.CreateExtensionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.CreateExtensionRequest.displayName = 'proto.dibric.portal.v1.CreateExtensionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.CreateExtensionRequest.ResponseOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.CreateExtensionRequest.ResponseOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.CreateExtensionRequest.ResponseOptions.displayName = 'proto.dibric.portal.v1.CreateExtensionRequest.ResponseOptions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.CreateExtensionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.CreateExtensionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.CreateExtensionResponse.displayName = 'proto.dibric.portal.v1.CreateExtensionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.InstallExtensionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.InstallExtensionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.InstallExtensionRequest.displayName = 'proto.dibric.portal.v1.InstallExtensionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.InstallExtensionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.InstallExtensionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.InstallExtensionResponse.displayName = 'proto.dibric.portal.v1.InstallExtensionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.UninstallExtensionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.UninstallExtensionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.UninstallExtensionRequest.displayName = 'proto.dibric.portal.v1.UninstallExtensionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.UninstallExtensionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.UninstallExtensionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.UninstallExtensionResponse.displayName = 'proto.dibric.portal.v1.UninstallExtensionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.Extension = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dibric.portal.v1.Extension.repeatedFields_, null);
};
goog.inherits(proto.dibric.portal.v1.Extension, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.Extension.displayName = 'proto.dibric.portal.v1.Extension';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.ExtensionVersion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dibric.portal.v1.ExtensionVersion.repeatedFields_, null);
};
goog.inherits(proto.dibric.portal.v1.ExtensionVersion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.ExtensionVersion.displayName = 'proto.dibric.portal.v1.ExtensionVersion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.VersionSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dibric.portal.v1.VersionSummary.repeatedFields_, null);
};
goog.inherits(proto.dibric.portal.v1.VersionSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.VersionSummary.displayName = 'proto.dibric.portal.v1.VersionSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.TaskSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.TaskSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.TaskSummary.displayName = 'proto.dibric.portal.v1.TaskSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.Repository = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.Repository, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.Repository.displayName = 'proto.dibric.portal.v1.Repository';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.Bugs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.Bugs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.Bugs.displayName = 'proto.dibric.portal.v1.Bugs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.Author = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.Author, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.Author.displayName = 'proto.dibric.portal.v1.Author';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.Deprecation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.Deprecation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.Deprecation.displayName = 'proto.dibric.portal.v1.Deprecation';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.DeleteVersionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.DeleteVersionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.DeleteVersionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.DeleteVersionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    version: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.DeleteVersionRequest}
 */
proto.dibric.portal.v1.DeleteVersionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.DeleteVersionRequest;
  return proto.dibric.portal.v1.DeleteVersionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.DeleteVersionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.DeleteVersionRequest}
 */
proto.dibric.portal.v1.DeleteVersionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.DeleteVersionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.DeleteVersionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.DeleteVersionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.DeleteVersionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.dibric.portal.v1.DeleteVersionRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.DeleteVersionRequest} returns this
 */
proto.dibric.portal.v1.DeleteVersionRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string version = 2;
 * @return {string}
 */
proto.dibric.portal.v1.DeleteVersionRequest.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.DeleteVersionRequest} returns this
 */
proto.dibric.portal.v1.DeleteVersionRequest.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.DeleteExtensionProgress.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.DeleteExtensionProgress.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.DeleteExtensionProgress} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.DeleteExtensionProgress.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalOperations: jspb.Message.getFieldWithDefault(msg, 1, 0),
    currentOperation: jspb.Message.getFieldWithDefault(msg, 2, 0),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.DeleteExtensionProgress}
 */
proto.dibric.portal.v1.DeleteExtensionProgress.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.DeleteExtensionProgress;
  return proto.dibric.portal.v1.DeleteExtensionProgress.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.DeleteExtensionProgress} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.DeleteExtensionProgress}
 */
proto.dibric.portal.v1.DeleteExtensionProgress.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalOperations(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentOperation(value);
      break;
    case 3:
      var value = /** @type {!proto.dibric.portal.v1.ProgressStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.DeleteExtensionProgress.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.DeleteExtensionProgress.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.DeleteExtensionProgress} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.DeleteExtensionProgress.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalOperations();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCurrentOperation();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional int32 total_operations = 1;
 * @return {number}
 */
proto.dibric.portal.v1.DeleteExtensionProgress.prototype.getTotalOperations = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.portal.v1.DeleteExtensionProgress} returns this
 */
proto.dibric.portal.v1.DeleteExtensionProgress.prototype.setTotalOperations = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 current_operation = 2;
 * @return {number}
 */
proto.dibric.portal.v1.DeleteExtensionProgress.prototype.getCurrentOperation = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.portal.v1.DeleteExtensionProgress} returns this
 */
proto.dibric.portal.v1.DeleteExtensionProgress.prototype.setCurrentOperation = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional ProgressStatus status = 3;
 * @return {!proto.dibric.portal.v1.ProgressStatus}
 */
proto.dibric.portal.v1.DeleteExtensionProgress.prototype.getStatus = function() {
  return /** @type {!proto.dibric.portal.v1.ProgressStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.dibric.portal.v1.ProgressStatus} value
 * @return {!proto.dibric.portal.v1.DeleteExtensionProgress} returns this
 */
proto.dibric.portal.v1.DeleteExtensionProgress.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.DeleteVersionProgress.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.DeleteVersionProgress.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.DeleteVersionProgress} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.DeleteVersionProgress.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalOperations: jspb.Message.getFieldWithDefault(msg, 1, 0),
    currentOperation: jspb.Message.getFieldWithDefault(msg, 2, 0),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.DeleteVersionProgress}
 */
proto.dibric.portal.v1.DeleteVersionProgress.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.DeleteVersionProgress;
  return proto.dibric.portal.v1.DeleteVersionProgress.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.DeleteVersionProgress} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.DeleteVersionProgress}
 */
proto.dibric.portal.v1.DeleteVersionProgress.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalOperations(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentOperation(value);
      break;
    case 3:
      var value = /** @type {!proto.dibric.portal.v1.ProgressStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.DeleteVersionProgress.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.DeleteVersionProgress.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.DeleteVersionProgress} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.DeleteVersionProgress.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalOperations();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCurrentOperation();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional int32 total_operations = 1;
 * @return {number}
 */
proto.dibric.portal.v1.DeleteVersionProgress.prototype.getTotalOperations = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.portal.v1.DeleteVersionProgress} returns this
 */
proto.dibric.portal.v1.DeleteVersionProgress.prototype.setTotalOperations = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 current_operation = 2;
 * @return {number}
 */
proto.dibric.portal.v1.DeleteVersionProgress.prototype.getCurrentOperation = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.portal.v1.DeleteVersionProgress} returns this
 */
proto.dibric.portal.v1.DeleteVersionProgress.prototype.setCurrentOperation = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional ProgressStatus status = 3;
 * @return {!proto.dibric.portal.v1.ProgressStatus}
 */
proto.dibric.portal.v1.DeleteVersionProgress.prototype.getStatus = function() {
  return /** @type {!proto.dibric.portal.v1.ProgressStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.dibric.portal.v1.ProgressStatus} value
 * @return {!proto.dibric.portal.v1.DeleteVersionProgress} returns this
 */
proto.dibric.portal.v1.DeleteVersionProgress.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.DeleteVersionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.DeleteVersionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.DeleteVersionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.DeleteVersionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.DeleteVersionResponse}
 */
proto.dibric.portal.v1.DeleteVersionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.DeleteVersionResponse;
  return proto.dibric.portal.v1.DeleteVersionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.DeleteVersionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.DeleteVersionResponse}
 */
proto.dibric.portal.v1.DeleteVersionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.DeleteVersionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.DeleteVersionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.DeleteVersionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.DeleteVersionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.DeprecateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.DeprecateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.DeprecateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.DeprecateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    extensionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    version: jspb.Message.getFieldWithDefault(msg, 2, ""),
    deprecation: (f = msg.getDeprecation()) && proto.dibric.portal.v1.Deprecation.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto.dibric.portal.v1.DeprecateRequest.ResponseOptions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.DeprecateRequest}
 */
proto.dibric.portal.v1.DeprecateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.DeprecateRequest;
  return proto.dibric.portal.v1.DeprecateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.DeprecateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.DeprecateRequest}
 */
proto.dibric.portal.v1.DeprecateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExtensionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 3:
      var value = new proto.dibric.portal.v1.Deprecation;
      reader.readMessage(value,proto.dibric.portal.v1.Deprecation.deserializeBinaryFromReader);
      msg.setDeprecation(value);
      break;
    case 4:
      var value = new proto.dibric.portal.v1.DeprecateRequest.ResponseOptions;
      reader.readMessage(value,proto.dibric.portal.v1.DeprecateRequest.ResponseOptions.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.DeprecateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.DeprecateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.DeprecateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.DeprecateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExtensionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDeprecation();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.dibric.portal.v1.Deprecation.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.dibric.portal.v1.DeprecateRequest.ResponseOptions.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.DeprecateRequest.ResponseOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.DeprecateRequest.ResponseOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.DeprecateRequest.ResponseOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.DeprecateRequest.ResponseOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    includeItemResponse: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.DeprecateRequest.ResponseOptions}
 */
proto.dibric.portal.v1.DeprecateRequest.ResponseOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.DeprecateRequest.ResponseOptions;
  return proto.dibric.portal.v1.DeprecateRequest.ResponseOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.DeprecateRequest.ResponseOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.DeprecateRequest.ResponseOptions}
 */
proto.dibric.portal.v1.DeprecateRequest.ResponseOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeItemResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.DeprecateRequest.ResponseOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.DeprecateRequest.ResponseOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.DeprecateRequest.ResponseOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.DeprecateRequest.ResponseOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIncludeItemResponse();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool include_item_response = 1;
 * @return {boolean}
 */
proto.dibric.portal.v1.DeprecateRequest.ResponseOptions.prototype.getIncludeItemResponse = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dibric.portal.v1.DeprecateRequest.ResponseOptions} returns this
 */
proto.dibric.portal.v1.DeprecateRequest.ResponseOptions.prototype.setIncludeItemResponse = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string extension_id = 1;
 * @return {string}
 */
proto.dibric.portal.v1.DeprecateRequest.prototype.getExtensionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.DeprecateRequest} returns this
 */
proto.dibric.portal.v1.DeprecateRequest.prototype.setExtensionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string version = 2;
 * @return {string}
 */
proto.dibric.portal.v1.DeprecateRequest.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.DeprecateRequest} returns this
 */
proto.dibric.portal.v1.DeprecateRequest.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Deprecation deprecation = 3;
 * @return {?proto.dibric.portal.v1.Deprecation}
 */
proto.dibric.portal.v1.DeprecateRequest.prototype.getDeprecation = function() {
  return /** @type{?proto.dibric.portal.v1.Deprecation} */ (
    jspb.Message.getWrapperField(this, proto.dibric.portal.v1.Deprecation, 3));
};


/**
 * @param {?proto.dibric.portal.v1.Deprecation|undefined} value
 * @return {!proto.dibric.portal.v1.DeprecateRequest} returns this
*/
proto.dibric.portal.v1.DeprecateRequest.prototype.setDeprecation = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.DeprecateRequest} returns this
 */
proto.dibric.portal.v1.DeprecateRequest.prototype.clearDeprecation = function() {
  return this.setDeprecation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.DeprecateRequest.prototype.hasDeprecation = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ResponseOptions options = 4;
 * @return {?proto.dibric.portal.v1.DeprecateRequest.ResponseOptions}
 */
proto.dibric.portal.v1.DeprecateRequest.prototype.getOptions = function() {
  return /** @type{?proto.dibric.portal.v1.DeprecateRequest.ResponseOptions} */ (
    jspb.Message.getWrapperField(this, proto.dibric.portal.v1.DeprecateRequest.ResponseOptions, 4));
};


/**
 * @param {?proto.dibric.portal.v1.DeprecateRequest.ResponseOptions|undefined} value
 * @return {!proto.dibric.portal.v1.DeprecateRequest} returns this
*/
proto.dibric.portal.v1.DeprecateRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.DeprecateRequest} returns this
 */
proto.dibric.portal.v1.DeprecateRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.DeprecateRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.DeprecateProgress.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.DeprecateProgress.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.DeprecateProgress} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.DeprecateProgress.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalOperations: jspb.Message.getFieldWithDefault(msg, 1, 0),
    currentOperation: jspb.Message.getFieldWithDefault(msg, 2, 0),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.DeprecateProgress}
 */
proto.dibric.portal.v1.DeprecateProgress.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.DeprecateProgress;
  return proto.dibric.portal.v1.DeprecateProgress.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.DeprecateProgress} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.DeprecateProgress}
 */
proto.dibric.portal.v1.DeprecateProgress.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalOperations(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentOperation(value);
      break;
    case 3:
      var value = /** @type {!proto.dibric.portal.v1.ProgressStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.DeprecateProgress.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.DeprecateProgress.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.DeprecateProgress} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.DeprecateProgress.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalOperations();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCurrentOperation();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional int32 total_operations = 1;
 * @return {number}
 */
proto.dibric.portal.v1.DeprecateProgress.prototype.getTotalOperations = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.portal.v1.DeprecateProgress} returns this
 */
proto.dibric.portal.v1.DeprecateProgress.prototype.setTotalOperations = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 current_operation = 2;
 * @return {number}
 */
proto.dibric.portal.v1.DeprecateProgress.prototype.getCurrentOperation = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.portal.v1.DeprecateProgress} returns this
 */
proto.dibric.portal.v1.DeprecateProgress.prototype.setCurrentOperation = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional ProgressStatus status = 3;
 * @return {!proto.dibric.portal.v1.ProgressStatus}
 */
proto.dibric.portal.v1.DeprecateProgress.prototype.getStatus = function() {
  return /** @type {!proto.dibric.portal.v1.ProgressStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.dibric.portal.v1.ProgressStatus} value
 * @return {!proto.dibric.portal.v1.DeprecateProgress} returns this
 */
proto.dibric.portal.v1.DeprecateProgress.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.DeprecateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.DeprecateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.DeprecateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.DeprecateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.DeprecateResponse}
 */
proto.dibric.portal.v1.DeprecateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.DeprecateResponse;
  return proto.dibric.portal.v1.DeprecateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.DeprecateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.DeprecateResponse}
 */
proto.dibric.portal.v1.DeprecateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.DeprecateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.DeprecateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.DeprecateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.DeprecateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.CreateVersionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.CreateVersionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.CreateVersionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.CreateVersionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_package: msg.getPackage_asB64(),
    targetId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    options: (f = msg.getOptions()) && proto.dibric.portal.v1.CreateVersionRequest.ResponseOptions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.CreateVersionRequest}
 */
proto.dibric.portal.v1.CreateVersionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.CreateVersionRequest;
  return proto.dibric.portal.v1.CreateVersionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.CreateVersionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.CreateVersionRequest}
 */
proto.dibric.portal.v1.CreateVersionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPackage(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetId(value);
      break;
    case 3:
      var value = new proto.dibric.portal.v1.CreateVersionRequest.ResponseOptions;
      reader.readMessage(value,proto.dibric.portal.v1.CreateVersionRequest.ResponseOptions.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.CreateVersionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.CreateVersionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.CreateVersionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.CreateVersionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPackage_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getTargetId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.dibric.portal.v1.CreateVersionRequest.ResponseOptions.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.CreateVersionRequest.ResponseOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.CreateVersionRequest.ResponseOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.CreateVersionRequest.ResponseOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.CreateVersionRequest.ResponseOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    includeItemResponse: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.CreateVersionRequest.ResponseOptions}
 */
proto.dibric.portal.v1.CreateVersionRequest.ResponseOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.CreateVersionRequest.ResponseOptions;
  return proto.dibric.portal.v1.CreateVersionRequest.ResponseOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.CreateVersionRequest.ResponseOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.CreateVersionRequest.ResponseOptions}
 */
proto.dibric.portal.v1.CreateVersionRequest.ResponseOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeItemResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.CreateVersionRequest.ResponseOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.CreateVersionRequest.ResponseOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.CreateVersionRequest.ResponseOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.CreateVersionRequest.ResponseOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIncludeItemResponse();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool include_item_response = 1;
 * @return {boolean}
 */
proto.dibric.portal.v1.CreateVersionRequest.ResponseOptions.prototype.getIncludeItemResponse = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dibric.portal.v1.CreateVersionRequest.ResponseOptions} returns this
 */
proto.dibric.portal.v1.CreateVersionRequest.ResponseOptions.prototype.setIncludeItemResponse = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bytes package = 1;
 * @return {string}
 */
proto.dibric.portal.v1.CreateVersionRequest.prototype.getPackage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes package = 1;
 * This is a type-conversion wrapper around `getPackage()`
 * @return {string}
 */
proto.dibric.portal.v1.CreateVersionRequest.prototype.getPackage_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPackage()));
};


/**
 * optional bytes package = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPackage()`
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.CreateVersionRequest.prototype.getPackage_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPackage()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.dibric.portal.v1.CreateVersionRequest} returns this
 */
proto.dibric.portal.v1.CreateVersionRequest.prototype.setPackage = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional string target_id = 2;
 * @return {string}
 */
proto.dibric.portal.v1.CreateVersionRequest.prototype.getTargetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.CreateVersionRequest} returns this
 */
proto.dibric.portal.v1.CreateVersionRequest.prototype.setTargetId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ResponseOptions options = 3;
 * @return {?proto.dibric.portal.v1.CreateVersionRequest.ResponseOptions}
 */
proto.dibric.portal.v1.CreateVersionRequest.prototype.getOptions = function() {
  return /** @type{?proto.dibric.portal.v1.CreateVersionRequest.ResponseOptions} */ (
    jspb.Message.getWrapperField(this, proto.dibric.portal.v1.CreateVersionRequest.ResponseOptions, 3));
};


/**
 * @param {?proto.dibric.portal.v1.CreateVersionRequest.ResponseOptions|undefined} value
 * @return {!proto.dibric.portal.v1.CreateVersionRequest} returns this
*/
proto.dibric.portal.v1.CreateVersionRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.CreateVersionRequest} returns this
 */
proto.dibric.portal.v1.CreateVersionRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.CreateVersionRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.CreateVersionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.CreateVersionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.CreateVersionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.CreateVersionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    item: (f = msg.getItem()) && proto.dibric.portal.v1.ExtensionVersion.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.CreateVersionResponse}
 */
proto.dibric.portal.v1.CreateVersionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.CreateVersionResponse;
  return proto.dibric.portal.v1.CreateVersionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.CreateVersionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.CreateVersionResponse}
 */
proto.dibric.portal.v1.CreateVersionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dibric.portal.v1.ExtensionVersion;
      reader.readMessage(value,proto.dibric.portal.v1.ExtensionVersion.deserializeBinaryFromReader);
      msg.setItem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.CreateVersionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.CreateVersionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.CreateVersionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.CreateVersionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dibric.portal.v1.ExtensionVersion.serializeBinaryToWriter
    );
  }
};


/**
 * optional ExtensionVersion item = 1;
 * @return {?proto.dibric.portal.v1.ExtensionVersion}
 */
proto.dibric.portal.v1.CreateVersionResponse.prototype.getItem = function() {
  return /** @type{?proto.dibric.portal.v1.ExtensionVersion} */ (
    jspb.Message.getWrapperField(this, proto.dibric.portal.v1.ExtensionVersion, 1));
};


/**
 * @param {?proto.dibric.portal.v1.ExtensionVersion|undefined} value
 * @return {!proto.dibric.portal.v1.CreateVersionResponse} returns this
*/
proto.dibric.portal.v1.CreateVersionResponse.prototype.setItem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.CreateVersionResponse} returns this
 */
proto.dibric.portal.v1.CreateVersionResponse.prototype.clearItem = function() {
  return this.setItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.CreateVersionResponse.prototype.hasItem = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.GetVersionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.GetVersionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.GetVersionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.GetVersionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    extensionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    version: jspb.Message.getFieldWithDefault(msg, 2, ""),
    options: (f = msg.getOptions()) && proto.dibric.portal.v1.GetVersionRequest.ResponseOptions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.GetVersionRequest}
 */
proto.dibric.portal.v1.GetVersionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.GetVersionRequest;
  return proto.dibric.portal.v1.GetVersionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.GetVersionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.GetVersionRequest}
 */
proto.dibric.portal.v1.GetVersionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExtensionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 3:
      var value = new proto.dibric.portal.v1.GetVersionRequest.ResponseOptions;
      reader.readMessage(value,proto.dibric.portal.v1.GetVersionRequest.ResponseOptions.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.GetVersionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.GetVersionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.GetVersionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.GetVersionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExtensionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.dibric.portal.v1.GetVersionRequest.ResponseOptions.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.GetVersionRequest.ResponseOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.GetVersionRequest.ResponseOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.GetVersionRequest.ResponseOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.GetVersionRequest.ResponseOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    includeItemResponse: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.GetVersionRequest.ResponseOptions}
 */
proto.dibric.portal.v1.GetVersionRequest.ResponseOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.GetVersionRequest.ResponseOptions;
  return proto.dibric.portal.v1.GetVersionRequest.ResponseOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.GetVersionRequest.ResponseOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.GetVersionRequest.ResponseOptions}
 */
proto.dibric.portal.v1.GetVersionRequest.ResponseOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeItemResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.GetVersionRequest.ResponseOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.GetVersionRequest.ResponseOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.GetVersionRequest.ResponseOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.GetVersionRequest.ResponseOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIncludeItemResponse();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool include_item_response = 1;
 * @return {boolean}
 */
proto.dibric.portal.v1.GetVersionRequest.ResponseOptions.prototype.getIncludeItemResponse = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dibric.portal.v1.GetVersionRequest.ResponseOptions} returns this
 */
proto.dibric.portal.v1.GetVersionRequest.ResponseOptions.prototype.setIncludeItemResponse = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string extension_id = 1;
 * @return {string}
 */
proto.dibric.portal.v1.GetVersionRequest.prototype.getExtensionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.GetVersionRequest} returns this
 */
proto.dibric.portal.v1.GetVersionRequest.prototype.setExtensionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string version = 2;
 * @return {string}
 */
proto.dibric.portal.v1.GetVersionRequest.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.GetVersionRequest} returns this
 */
proto.dibric.portal.v1.GetVersionRequest.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ResponseOptions options = 3;
 * @return {?proto.dibric.portal.v1.GetVersionRequest.ResponseOptions}
 */
proto.dibric.portal.v1.GetVersionRequest.prototype.getOptions = function() {
  return /** @type{?proto.dibric.portal.v1.GetVersionRequest.ResponseOptions} */ (
    jspb.Message.getWrapperField(this, proto.dibric.portal.v1.GetVersionRequest.ResponseOptions, 3));
};


/**
 * @param {?proto.dibric.portal.v1.GetVersionRequest.ResponseOptions|undefined} value
 * @return {!proto.dibric.portal.v1.GetVersionRequest} returns this
*/
proto.dibric.portal.v1.GetVersionRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.GetVersionRequest} returns this
 */
proto.dibric.portal.v1.GetVersionRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.GetVersionRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.GetVersionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.GetVersionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.GetVersionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.GetVersionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    item: (f = msg.getItem()) && proto.dibric.portal.v1.ExtensionVersion.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.GetVersionResponse}
 */
proto.dibric.portal.v1.GetVersionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.GetVersionResponse;
  return proto.dibric.portal.v1.GetVersionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.GetVersionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.GetVersionResponse}
 */
proto.dibric.portal.v1.GetVersionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dibric.portal.v1.ExtensionVersion;
      reader.readMessage(value,proto.dibric.portal.v1.ExtensionVersion.deserializeBinaryFromReader);
      msg.setItem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.GetVersionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.GetVersionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.GetVersionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.GetVersionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dibric.portal.v1.ExtensionVersion.serializeBinaryToWriter
    );
  }
};


/**
 * optional ExtensionVersion item = 1;
 * @return {?proto.dibric.portal.v1.ExtensionVersion}
 */
proto.dibric.portal.v1.GetVersionResponse.prototype.getItem = function() {
  return /** @type{?proto.dibric.portal.v1.ExtensionVersion} */ (
    jspb.Message.getWrapperField(this, proto.dibric.portal.v1.ExtensionVersion, 1));
};


/**
 * @param {?proto.dibric.portal.v1.ExtensionVersion|undefined} value
 * @return {!proto.dibric.portal.v1.GetVersionResponse} returns this
*/
proto.dibric.portal.v1.GetVersionResponse.prototype.setItem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.GetVersionResponse} returns this
 */
proto.dibric.portal.v1.GetVersionResponse.prototype.clearItem = function() {
  return this.setItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.GetVersionResponse.prototype.hasItem = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dibric.portal.v1.ListExtensionRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.ListExtensionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.ListExtensionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.ListExtensionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.ListExtensionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageNumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    tenantId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    query: jspb.Message.getFieldWithDefault(msg, 4, ""),
    hasIdentifiersList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    orderBy: jspb.Message.getFieldWithDefault(msg, 6, 0),
    onlyStable: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    onlyNonEmpty: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    onlyCertified: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    orderByDescending: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    filter: jspb.Message.getFieldWithDefault(msg, 11, 0),
    options: (f = msg.getOptions()) && proto.dibric.portal.v1.ListExtensionRequest.ResponseOptions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.ListExtensionRequest}
 */
proto.dibric.portal.v1.ListExtensionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.ListExtensionRequest;
  return proto.dibric.portal.v1.ListExtensionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.ListExtensionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.ListExtensionRequest}
 */
proto.dibric.portal.v1.ListExtensionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addHasIdentifiers(value);
      break;
    case 6:
      var value = /** @type {!proto.dibric.portal.v1.ListExtensionRequest.Order} */ (reader.readEnum());
      msg.setOrderBy(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnlyStable(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnlyNonEmpty(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnlyCertified(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOrderByDescending(value);
      break;
    case 11:
      var value = /** @type {!proto.dibric.portal.v1.ListExtensionRequest.Filter} */ (reader.readEnum());
      msg.setFilter(value);
      break;
    case 12:
      var value = new proto.dibric.portal.v1.ListExtensionRequest.ResponseOptions;
      reader.readMessage(value,proto.dibric.portal.v1.ListExtensionRequest.ResponseOptions.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.ListExtensionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.ListExtensionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.ListExtensionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.ListExtensionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageNumber();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getHasIdentifiersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getOrderBy();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getOnlyStable();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getOnlyNonEmpty();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getOnlyCertified();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getOrderByDescending();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getFilter();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.dibric.portal.v1.ListExtensionRequest.ResponseOptions.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.dibric.portal.v1.ListExtensionRequest.Order = {
  NAME: 0,
  TENANT: 1
};

/**
 * @enum {number}
 */
proto.dibric.portal.v1.ListExtensionRequest.Filter = {
  ALL: 0,
  OWNER: 1,
  INSTALLED: 2
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.ListExtensionRequest.ResponseOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.ListExtensionRequest.ResponseOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.ListExtensionRequest.ResponseOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.ListExtensionRequest.ResponseOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    includeTasks: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    includeAllVersions: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.ListExtensionRequest.ResponseOptions}
 */
proto.dibric.portal.v1.ListExtensionRequest.ResponseOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.ListExtensionRequest.ResponseOptions;
  return proto.dibric.portal.v1.ListExtensionRequest.ResponseOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.ListExtensionRequest.ResponseOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.ListExtensionRequest.ResponseOptions}
 */
proto.dibric.portal.v1.ListExtensionRequest.ResponseOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeTasks(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeAllVersions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.ListExtensionRequest.ResponseOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.ListExtensionRequest.ResponseOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.ListExtensionRequest.ResponseOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.ListExtensionRequest.ResponseOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIncludeTasks();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getIncludeAllVersions();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bool include_tasks = 1;
 * @return {boolean}
 */
proto.dibric.portal.v1.ListExtensionRequest.ResponseOptions.prototype.getIncludeTasks = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dibric.portal.v1.ListExtensionRequest.ResponseOptions} returns this
 */
proto.dibric.portal.v1.ListExtensionRequest.ResponseOptions.prototype.setIncludeTasks = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool include_all_versions = 2;
 * @return {boolean}
 */
proto.dibric.portal.v1.ListExtensionRequest.ResponseOptions.prototype.getIncludeAllVersions = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dibric.portal.v1.ListExtensionRequest.ResponseOptions} returns this
 */
proto.dibric.portal.v1.ListExtensionRequest.ResponseOptions.prototype.setIncludeAllVersions = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.dibric.portal.v1.ListExtensionRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.portal.v1.ListExtensionRequest} returns this
 */
proto.dibric.portal.v1.ListExtensionRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 page_number = 2;
 * @return {number}
 */
proto.dibric.portal.v1.ListExtensionRequest.prototype.getPageNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.portal.v1.ListExtensionRequest} returns this
 */
proto.dibric.portal.v1.ListExtensionRequest.prototype.setPageNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string tenant_id = 3;
 * @return {string}
 */
proto.dibric.portal.v1.ListExtensionRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.ListExtensionRequest} returns this
 */
proto.dibric.portal.v1.ListExtensionRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string query = 4;
 * @return {string}
 */
proto.dibric.portal.v1.ListExtensionRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.ListExtensionRequest} returns this
 */
proto.dibric.portal.v1.ListExtensionRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated string has_identifiers = 5;
 * @return {!Array<string>}
 */
proto.dibric.portal.v1.ListExtensionRequest.prototype.getHasIdentifiersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.dibric.portal.v1.ListExtensionRequest} returns this
 */
proto.dibric.portal.v1.ListExtensionRequest.prototype.setHasIdentifiersList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.dibric.portal.v1.ListExtensionRequest} returns this
 */
proto.dibric.portal.v1.ListExtensionRequest.prototype.addHasIdentifiers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dibric.portal.v1.ListExtensionRequest} returns this
 */
proto.dibric.portal.v1.ListExtensionRequest.prototype.clearHasIdentifiersList = function() {
  return this.setHasIdentifiersList([]);
};


/**
 * optional Order order_by = 6;
 * @return {!proto.dibric.portal.v1.ListExtensionRequest.Order}
 */
proto.dibric.portal.v1.ListExtensionRequest.prototype.getOrderBy = function() {
  return /** @type {!proto.dibric.portal.v1.ListExtensionRequest.Order} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.dibric.portal.v1.ListExtensionRequest.Order} value
 * @return {!proto.dibric.portal.v1.ListExtensionRequest} returns this
 */
proto.dibric.portal.v1.ListExtensionRequest.prototype.setOrderBy = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional bool only_stable = 7;
 * @return {boolean}
 */
proto.dibric.portal.v1.ListExtensionRequest.prototype.getOnlyStable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dibric.portal.v1.ListExtensionRequest} returns this
 */
proto.dibric.portal.v1.ListExtensionRequest.prototype.setOnlyStable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool only_non_empty = 8;
 * @return {boolean}
 */
proto.dibric.portal.v1.ListExtensionRequest.prototype.getOnlyNonEmpty = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dibric.portal.v1.ListExtensionRequest} returns this
 */
proto.dibric.portal.v1.ListExtensionRequest.prototype.setOnlyNonEmpty = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool only_certified = 9;
 * @return {boolean}
 */
proto.dibric.portal.v1.ListExtensionRequest.prototype.getOnlyCertified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dibric.portal.v1.ListExtensionRequest} returns this
 */
proto.dibric.portal.v1.ListExtensionRequest.prototype.setOnlyCertified = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool order_by_descending = 10;
 * @return {boolean}
 */
proto.dibric.portal.v1.ListExtensionRequest.prototype.getOrderByDescending = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dibric.portal.v1.ListExtensionRequest} returns this
 */
proto.dibric.portal.v1.ListExtensionRequest.prototype.setOrderByDescending = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional Filter filter = 11;
 * @return {!proto.dibric.portal.v1.ListExtensionRequest.Filter}
 */
proto.dibric.portal.v1.ListExtensionRequest.prototype.getFilter = function() {
  return /** @type {!proto.dibric.portal.v1.ListExtensionRequest.Filter} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.dibric.portal.v1.ListExtensionRequest.Filter} value
 * @return {!proto.dibric.portal.v1.ListExtensionRequest} returns this
 */
proto.dibric.portal.v1.ListExtensionRequest.prototype.setFilter = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional ResponseOptions options = 12;
 * @return {?proto.dibric.portal.v1.ListExtensionRequest.ResponseOptions}
 */
proto.dibric.portal.v1.ListExtensionRequest.prototype.getOptions = function() {
  return /** @type{?proto.dibric.portal.v1.ListExtensionRequest.ResponseOptions} */ (
    jspb.Message.getWrapperField(this, proto.dibric.portal.v1.ListExtensionRequest.ResponseOptions, 12));
};


/**
 * @param {?proto.dibric.portal.v1.ListExtensionRequest.ResponseOptions|undefined} value
 * @return {!proto.dibric.portal.v1.ListExtensionRequest} returns this
*/
proto.dibric.portal.v1.ListExtensionRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.ListExtensionRequest} returns this
 */
proto.dibric.portal.v1.ListExtensionRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.ListExtensionRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 12) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dibric.portal.v1.ListExtensionResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.ListExtensionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.ListExtensionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.ListExtensionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.ListExtensionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageNumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalMatches: jspb.Message.getFieldWithDefault(msg, 3, 0),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.dibric.portal.v1.Extension.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.ListExtensionResponse}
 */
proto.dibric.portal.v1.ListExtensionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.ListExtensionResponse;
  return proto.dibric.portal.v1.ListExtensionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.ListExtensionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.ListExtensionResponse}
 */
proto.dibric.portal.v1.ListExtensionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageNumber(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalMatches(value);
      break;
    case 4:
      var value = new proto.dibric.portal.v1.Extension;
      reader.readMessage(value,proto.dibric.portal.v1.Extension.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.ListExtensionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.ListExtensionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.ListExtensionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.ListExtensionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageNumber();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTotalMatches();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.dibric.portal.v1.Extension.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.dibric.portal.v1.ListExtensionResponse.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.portal.v1.ListExtensionResponse} returns this
 */
proto.dibric.portal.v1.ListExtensionResponse.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 page_number = 2;
 * @return {number}
 */
proto.dibric.portal.v1.ListExtensionResponse.prototype.getPageNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.portal.v1.ListExtensionResponse} returns this
 */
proto.dibric.portal.v1.ListExtensionResponse.prototype.setPageNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 total_matches = 3;
 * @return {number}
 */
proto.dibric.portal.v1.ListExtensionResponse.prototype.getTotalMatches = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.portal.v1.ListExtensionResponse} returns this
 */
proto.dibric.portal.v1.ListExtensionResponse.prototype.setTotalMatches = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated Extension items = 4;
 * @return {!Array<!proto.dibric.portal.v1.Extension>}
 */
proto.dibric.portal.v1.ListExtensionResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.dibric.portal.v1.Extension>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dibric.portal.v1.Extension, 4));
};


/**
 * @param {!Array<!proto.dibric.portal.v1.Extension>} value
 * @return {!proto.dibric.portal.v1.ListExtensionResponse} returns this
*/
proto.dibric.portal.v1.ListExtensionResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.dibric.portal.v1.Extension=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dibric.portal.v1.Extension}
 */
proto.dibric.portal.v1.ListExtensionResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.dibric.portal.v1.Extension, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dibric.portal.v1.ListExtensionResponse} returns this
 */
proto.dibric.portal.v1.ListExtensionResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.ListVersionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.ListVersionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.ListVersionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.ListVersionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageNumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    extensionId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    orderBy: jspb.Message.getFieldWithDefault(msg, 4, 0),
    orderByDescending: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    options: (f = msg.getOptions()) && proto.dibric.portal.v1.ListVersionsRequest.ResponseOptions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.ListVersionsRequest}
 */
proto.dibric.portal.v1.ListVersionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.ListVersionsRequest;
  return proto.dibric.portal.v1.ListVersionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.ListVersionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.ListVersionsRequest}
 */
proto.dibric.portal.v1.ListVersionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setExtensionId(value);
      break;
    case 4:
      var value = /** @type {!proto.dibric.portal.v1.ListVersionsRequest.Order} */ (reader.readEnum());
      msg.setOrderBy(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOrderByDescending(value);
      break;
    case 6:
      var value = new proto.dibric.portal.v1.ListVersionsRequest.ResponseOptions;
      reader.readMessage(value,proto.dibric.portal.v1.ListVersionsRequest.ResponseOptions.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.ListVersionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.ListVersionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.ListVersionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.ListVersionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageNumber();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getExtensionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOrderBy();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getOrderByDescending();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.dibric.portal.v1.ListVersionsRequest.ResponseOptions.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.dibric.portal.v1.ListVersionsRequest.Order = {
  VERSION: 0,
  LAST_PUBLISHED: 1
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.ListVersionsRequest.ResponseOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.ListVersionsRequest.ResponseOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.ListVersionsRequest.ResponseOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.ListVersionsRequest.ResponseOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    includeTasks: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.ListVersionsRequest.ResponseOptions}
 */
proto.dibric.portal.v1.ListVersionsRequest.ResponseOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.ListVersionsRequest.ResponseOptions;
  return proto.dibric.portal.v1.ListVersionsRequest.ResponseOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.ListVersionsRequest.ResponseOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.ListVersionsRequest.ResponseOptions}
 */
proto.dibric.portal.v1.ListVersionsRequest.ResponseOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeTasks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.ListVersionsRequest.ResponseOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.ListVersionsRequest.ResponseOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.ListVersionsRequest.ResponseOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.ListVersionsRequest.ResponseOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIncludeTasks();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool include_tasks = 1;
 * @return {boolean}
 */
proto.dibric.portal.v1.ListVersionsRequest.ResponseOptions.prototype.getIncludeTasks = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dibric.portal.v1.ListVersionsRequest.ResponseOptions} returns this
 */
proto.dibric.portal.v1.ListVersionsRequest.ResponseOptions.prototype.setIncludeTasks = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.dibric.portal.v1.ListVersionsRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.portal.v1.ListVersionsRequest} returns this
 */
proto.dibric.portal.v1.ListVersionsRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 page_number = 2;
 * @return {number}
 */
proto.dibric.portal.v1.ListVersionsRequest.prototype.getPageNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.portal.v1.ListVersionsRequest} returns this
 */
proto.dibric.portal.v1.ListVersionsRequest.prototype.setPageNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string extension_id = 3;
 * @return {string}
 */
proto.dibric.portal.v1.ListVersionsRequest.prototype.getExtensionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.ListVersionsRequest} returns this
 */
proto.dibric.portal.v1.ListVersionsRequest.prototype.setExtensionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Order order_by = 4;
 * @return {!proto.dibric.portal.v1.ListVersionsRequest.Order}
 */
proto.dibric.portal.v1.ListVersionsRequest.prototype.getOrderBy = function() {
  return /** @type {!proto.dibric.portal.v1.ListVersionsRequest.Order} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.dibric.portal.v1.ListVersionsRequest.Order} value
 * @return {!proto.dibric.portal.v1.ListVersionsRequest} returns this
 */
proto.dibric.portal.v1.ListVersionsRequest.prototype.setOrderBy = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional bool order_by_descending = 5;
 * @return {boolean}
 */
proto.dibric.portal.v1.ListVersionsRequest.prototype.getOrderByDescending = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dibric.portal.v1.ListVersionsRequest} returns this
 */
proto.dibric.portal.v1.ListVersionsRequest.prototype.setOrderByDescending = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional ResponseOptions options = 6;
 * @return {?proto.dibric.portal.v1.ListVersionsRequest.ResponseOptions}
 */
proto.dibric.portal.v1.ListVersionsRequest.prototype.getOptions = function() {
  return /** @type{?proto.dibric.portal.v1.ListVersionsRequest.ResponseOptions} */ (
    jspb.Message.getWrapperField(this, proto.dibric.portal.v1.ListVersionsRequest.ResponseOptions, 6));
};


/**
 * @param {?proto.dibric.portal.v1.ListVersionsRequest.ResponseOptions|undefined} value
 * @return {!proto.dibric.portal.v1.ListVersionsRequest} returns this
*/
proto.dibric.portal.v1.ListVersionsRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.ListVersionsRequest} returns this
 */
proto.dibric.portal.v1.ListVersionsRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.ListVersionsRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dibric.portal.v1.ListVersionsResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.ListVersionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.ListVersionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.ListVersionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.ListVersionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageNumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalMatches: jspb.Message.getFieldWithDefault(msg, 3, 0),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.dibric.portal.v1.VersionSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.ListVersionsResponse}
 */
proto.dibric.portal.v1.ListVersionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.ListVersionsResponse;
  return proto.dibric.portal.v1.ListVersionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.ListVersionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.ListVersionsResponse}
 */
proto.dibric.portal.v1.ListVersionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageNumber(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalMatches(value);
      break;
    case 4:
      var value = new proto.dibric.portal.v1.VersionSummary;
      reader.readMessage(value,proto.dibric.portal.v1.VersionSummary.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.ListVersionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.ListVersionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.ListVersionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.ListVersionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageNumber();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTotalMatches();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.dibric.portal.v1.VersionSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.dibric.portal.v1.ListVersionsResponse.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.portal.v1.ListVersionsResponse} returns this
 */
proto.dibric.portal.v1.ListVersionsResponse.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 page_number = 2;
 * @return {number}
 */
proto.dibric.portal.v1.ListVersionsResponse.prototype.getPageNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.portal.v1.ListVersionsResponse} returns this
 */
proto.dibric.portal.v1.ListVersionsResponse.prototype.setPageNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 total_matches = 3;
 * @return {number}
 */
proto.dibric.portal.v1.ListVersionsResponse.prototype.getTotalMatches = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.portal.v1.ListVersionsResponse} returns this
 */
proto.dibric.portal.v1.ListVersionsResponse.prototype.setTotalMatches = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated VersionSummary items = 4;
 * @return {!Array<!proto.dibric.portal.v1.VersionSummary>}
 */
proto.dibric.portal.v1.ListVersionsResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.dibric.portal.v1.VersionSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dibric.portal.v1.VersionSummary, 4));
};


/**
 * @param {!Array<!proto.dibric.portal.v1.VersionSummary>} value
 * @return {!proto.dibric.portal.v1.ListVersionsResponse} returns this
*/
proto.dibric.portal.v1.ListVersionsResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.dibric.portal.v1.VersionSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dibric.portal.v1.VersionSummary}
 */
proto.dibric.portal.v1.ListVersionsResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.dibric.portal.v1.VersionSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dibric.portal.v1.ListVersionsResponse} returns this
 */
proto.dibric.portal.v1.ListVersionsResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.GetExtensionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.GetExtensionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.GetExtensionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.GetExtensionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    latestStable: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    options: (f = msg.getOptions()) && proto.dibric.portal.v1.GetExtensionRequest.ResponseOptions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.GetExtensionRequest}
 */
proto.dibric.portal.v1.GetExtensionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.GetExtensionRequest;
  return proto.dibric.portal.v1.GetExtensionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.GetExtensionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.GetExtensionRequest}
 */
proto.dibric.portal.v1.GetExtensionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLatestStable(value);
      break;
    case 3:
      var value = new proto.dibric.portal.v1.GetExtensionRequest.ResponseOptions;
      reader.readMessage(value,proto.dibric.portal.v1.GetExtensionRequest.ResponseOptions.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.GetExtensionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.GetExtensionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.GetExtensionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.GetExtensionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLatestStable();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.dibric.portal.v1.GetExtensionRequest.ResponseOptions.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.GetExtensionRequest.ResponseOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.GetExtensionRequest.ResponseOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.GetExtensionRequest.ResponseOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.GetExtensionRequest.ResponseOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    includeItemResponse: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    includeTasks: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    includeAllVersions: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.GetExtensionRequest.ResponseOptions}
 */
proto.dibric.portal.v1.GetExtensionRequest.ResponseOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.GetExtensionRequest.ResponseOptions;
  return proto.dibric.portal.v1.GetExtensionRequest.ResponseOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.GetExtensionRequest.ResponseOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.GetExtensionRequest.ResponseOptions}
 */
proto.dibric.portal.v1.GetExtensionRequest.ResponseOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeItemResponse(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeTasks(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeAllVersions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.GetExtensionRequest.ResponseOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.GetExtensionRequest.ResponseOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.GetExtensionRequest.ResponseOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.GetExtensionRequest.ResponseOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIncludeItemResponse();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getIncludeTasks();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getIncludeAllVersions();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional bool include_item_response = 1;
 * @return {boolean}
 */
proto.dibric.portal.v1.GetExtensionRequest.ResponseOptions.prototype.getIncludeItemResponse = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dibric.portal.v1.GetExtensionRequest.ResponseOptions} returns this
 */
proto.dibric.portal.v1.GetExtensionRequest.ResponseOptions.prototype.setIncludeItemResponse = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool include_tasks = 2;
 * @return {boolean}
 */
proto.dibric.portal.v1.GetExtensionRequest.ResponseOptions.prototype.getIncludeTasks = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dibric.portal.v1.GetExtensionRequest.ResponseOptions} returns this
 */
proto.dibric.portal.v1.GetExtensionRequest.ResponseOptions.prototype.setIncludeTasks = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool include_all_versions = 3;
 * @return {boolean}
 */
proto.dibric.portal.v1.GetExtensionRequest.ResponseOptions.prototype.getIncludeAllVersions = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dibric.portal.v1.GetExtensionRequest.ResponseOptions} returns this
 */
proto.dibric.portal.v1.GetExtensionRequest.ResponseOptions.prototype.setIncludeAllVersions = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.dibric.portal.v1.GetExtensionRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.GetExtensionRequest} returns this
 */
proto.dibric.portal.v1.GetExtensionRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool latest_stable = 2;
 * @return {boolean}
 */
proto.dibric.portal.v1.GetExtensionRequest.prototype.getLatestStable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dibric.portal.v1.GetExtensionRequest} returns this
 */
proto.dibric.portal.v1.GetExtensionRequest.prototype.setLatestStable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional ResponseOptions options = 3;
 * @return {?proto.dibric.portal.v1.GetExtensionRequest.ResponseOptions}
 */
proto.dibric.portal.v1.GetExtensionRequest.prototype.getOptions = function() {
  return /** @type{?proto.dibric.portal.v1.GetExtensionRequest.ResponseOptions} */ (
    jspb.Message.getWrapperField(this, proto.dibric.portal.v1.GetExtensionRequest.ResponseOptions, 3));
};


/**
 * @param {?proto.dibric.portal.v1.GetExtensionRequest.ResponseOptions|undefined} value
 * @return {!proto.dibric.portal.v1.GetExtensionRequest} returns this
*/
proto.dibric.portal.v1.GetExtensionRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.GetExtensionRequest} returns this
 */
proto.dibric.portal.v1.GetExtensionRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.GetExtensionRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.GetExtensionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.GetExtensionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.GetExtensionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.GetExtensionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    item: (f = msg.getItem()) && proto.dibric.portal.v1.Extension.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.GetExtensionResponse}
 */
proto.dibric.portal.v1.GetExtensionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.GetExtensionResponse;
  return proto.dibric.portal.v1.GetExtensionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.GetExtensionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.GetExtensionResponse}
 */
proto.dibric.portal.v1.GetExtensionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dibric.portal.v1.Extension;
      reader.readMessage(value,proto.dibric.portal.v1.Extension.deserializeBinaryFromReader);
      msg.setItem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.GetExtensionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.GetExtensionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.GetExtensionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.GetExtensionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dibric.portal.v1.Extension.serializeBinaryToWriter
    );
  }
};


/**
 * optional Extension item = 1;
 * @return {?proto.dibric.portal.v1.Extension}
 */
proto.dibric.portal.v1.GetExtensionResponse.prototype.getItem = function() {
  return /** @type{?proto.dibric.portal.v1.Extension} */ (
    jspb.Message.getWrapperField(this, proto.dibric.portal.v1.Extension, 1));
};


/**
 * @param {?proto.dibric.portal.v1.Extension|undefined} value
 * @return {!proto.dibric.portal.v1.GetExtensionResponse} returns this
*/
proto.dibric.portal.v1.GetExtensionResponse.prototype.setItem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.GetExtensionResponse} returns this
 */
proto.dibric.portal.v1.GetExtensionResponse.prototype.clearItem = function() {
  return this.setItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.GetExtensionResponse.prototype.hasItem = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.DeleteExtensionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.DeleteExtensionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.DeleteExtensionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.DeleteExtensionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.DeleteExtensionRequest}
 */
proto.dibric.portal.v1.DeleteExtensionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.DeleteExtensionRequest;
  return proto.dibric.portal.v1.DeleteExtensionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.DeleteExtensionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.DeleteExtensionRequest}
 */
proto.dibric.portal.v1.DeleteExtensionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.DeleteExtensionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.DeleteExtensionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.DeleteExtensionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.DeleteExtensionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.dibric.portal.v1.DeleteExtensionRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.DeleteExtensionRequest} returns this
 */
proto.dibric.portal.v1.DeleteExtensionRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.DeleteExtensionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.DeleteExtensionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.DeleteExtensionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.DeleteExtensionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.DeleteExtensionResponse}
 */
proto.dibric.portal.v1.DeleteExtensionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.DeleteExtensionResponse;
  return proto.dibric.portal.v1.DeleteExtensionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.DeleteExtensionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.DeleteExtensionResponse}
 */
proto.dibric.portal.v1.DeleteExtensionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.DeleteExtensionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.DeleteExtensionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.DeleteExtensionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.DeleteExtensionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.CreateExtensionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.CreateExtensionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.CreateExtensionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.CreateExtensionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    options: (f = msg.getOptions()) && proto.dibric.portal.v1.CreateExtensionRequest.ResponseOptions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.CreateExtensionRequest}
 */
proto.dibric.portal.v1.CreateExtensionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.CreateExtensionRequest;
  return proto.dibric.portal.v1.CreateExtensionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.CreateExtensionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.CreateExtensionRequest}
 */
proto.dibric.portal.v1.CreateExtensionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.dibric.portal.v1.CreateExtensionRequest.ResponseOptions;
      reader.readMessage(value,proto.dibric.portal.v1.CreateExtensionRequest.ResponseOptions.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.CreateExtensionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.CreateExtensionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.CreateExtensionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.CreateExtensionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.dibric.portal.v1.CreateExtensionRequest.ResponseOptions.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.CreateExtensionRequest.ResponseOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.CreateExtensionRequest.ResponseOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.CreateExtensionRequest.ResponseOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.CreateExtensionRequest.ResponseOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    includeItemResponse: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.CreateExtensionRequest.ResponseOptions}
 */
proto.dibric.portal.v1.CreateExtensionRequest.ResponseOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.CreateExtensionRequest.ResponseOptions;
  return proto.dibric.portal.v1.CreateExtensionRequest.ResponseOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.CreateExtensionRequest.ResponseOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.CreateExtensionRequest.ResponseOptions}
 */
proto.dibric.portal.v1.CreateExtensionRequest.ResponseOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeItemResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.CreateExtensionRequest.ResponseOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.CreateExtensionRequest.ResponseOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.CreateExtensionRequest.ResponseOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.CreateExtensionRequest.ResponseOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIncludeItemResponse();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool include_item_response = 1;
 * @return {boolean}
 */
proto.dibric.portal.v1.CreateExtensionRequest.ResponseOptions.prototype.getIncludeItemResponse = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dibric.portal.v1.CreateExtensionRequest.ResponseOptions} returns this
 */
proto.dibric.portal.v1.CreateExtensionRequest.ResponseOptions.prototype.setIncludeItemResponse = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.dibric.portal.v1.CreateExtensionRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.CreateExtensionRequest} returns this
 */
proto.dibric.portal.v1.CreateExtensionRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ResponseOptions options = 2;
 * @return {?proto.dibric.portal.v1.CreateExtensionRequest.ResponseOptions}
 */
proto.dibric.portal.v1.CreateExtensionRequest.prototype.getOptions = function() {
  return /** @type{?proto.dibric.portal.v1.CreateExtensionRequest.ResponseOptions} */ (
    jspb.Message.getWrapperField(this, proto.dibric.portal.v1.CreateExtensionRequest.ResponseOptions, 2));
};


/**
 * @param {?proto.dibric.portal.v1.CreateExtensionRequest.ResponseOptions|undefined} value
 * @return {!proto.dibric.portal.v1.CreateExtensionRequest} returns this
*/
proto.dibric.portal.v1.CreateExtensionRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.CreateExtensionRequest} returns this
 */
proto.dibric.portal.v1.CreateExtensionRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.CreateExtensionRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.CreateExtensionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.CreateExtensionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.CreateExtensionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.CreateExtensionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    item: (f = msg.getItem()) && proto.dibric.portal.v1.Extension.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.CreateExtensionResponse}
 */
proto.dibric.portal.v1.CreateExtensionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.CreateExtensionResponse;
  return proto.dibric.portal.v1.CreateExtensionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.CreateExtensionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.CreateExtensionResponse}
 */
proto.dibric.portal.v1.CreateExtensionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dibric.portal.v1.Extension;
      reader.readMessage(value,proto.dibric.portal.v1.Extension.deserializeBinaryFromReader);
      msg.setItem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.CreateExtensionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.CreateExtensionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.CreateExtensionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.CreateExtensionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dibric.portal.v1.Extension.serializeBinaryToWriter
    );
  }
};


/**
 * optional Extension item = 1;
 * @return {?proto.dibric.portal.v1.Extension}
 */
proto.dibric.portal.v1.CreateExtensionResponse.prototype.getItem = function() {
  return /** @type{?proto.dibric.portal.v1.Extension} */ (
    jspb.Message.getWrapperField(this, proto.dibric.portal.v1.Extension, 1));
};


/**
 * @param {?proto.dibric.portal.v1.Extension|undefined} value
 * @return {!proto.dibric.portal.v1.CreateExtensionResponse} returns this
*/
proto.dibric.portal.v1.CreateExtensionResponse.prototype.setItem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.CreateExtensionResponse} returns this
 */
proto.dibric.portal.v1.CreateExtensionResponse.prototype.clearItem = function() {
  return this.setItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.CreateExtensionResponse.prototype.hasItem = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.InstallExtensionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.InstallExtensionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.InstallExtensionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.InstallExtensionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.InstallExtensionRequest}
 */
proto.dibric.portal.v1.InstallExtensionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.InstallExtensionRequest;
  return proto.dibric.portal.v1.InstallExtensionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.InstallExtensionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.InstallExtensionRequest}
 */
proto.dibric.portal.v1.InstallExtensionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.InstallExtensionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.InstallExtensionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.InstallExtensionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.InstallExtensionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.dibric.portal.v1.InstallExtensionRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.InstallExtensionRequest} returns this
 */
proto.dibric.portal.v1.InstallExtensionRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.InstallExtensionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.InstallExtensionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.InstallExtensionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.InstallExtensionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    item: (f = msg.getItem()) && proto.dibric.portal.v1.Extension.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.InstallExtensionResponse}
 */
proto.dibric.portal.v1.InstallExtensionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.InstallExtensionResponse;
  return proto.dibric.portal.v1.InstallExtensionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.InstallExtensionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.InstallExtensionResponse}
 */
proto.dibric.portal.v1.InstallExtensionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dibric.portal.v1.Extension;
      reader.readMessage(value,proto.dibric.portal.v1.Extension.deserializeBinaryFromReader);
      msg.setItem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.InstallExtensionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.InstallExtensionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.InstallExtensionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.InstallExtensionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dibric.portal.v1.Extension.serializeBinaryToWriter
    );
  }
};


/**
 * optional Extension item = 1;
 * @return {?proto.dibric.portal.v1.Extension}
 */
proto.dibric.portal.v1.InstallExtensionResponse.prototype.getItem = function() {
  return /** @type{?proto.dibric.portal.v1.Extension} */ (
    jspb.Message.getWrapperField(this, proto.dibric.portal.v1.Extension, 1));
};


/**
 * @param {?proto.dibric.portal.v1.Extension|undefined} value
 * @return {!proto.dibric.portal.v1.InstallExtensionResponse} returns this
*/
proto.dibric.portal.v1.InstallExtensionResponse.prototype.setItem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.InstallExtensionResponse} returns this
 */
proto.dibric.portal.v1.InstallExtensionResponse.prototype.clearItem = function() {
  return this.setItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.InstallExtensionResponse.prototype.hasItem = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.UninstallExtensionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.UninstallExtensionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.UninstallExtensionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.UninstallExtensionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.UninstallExtensionRequest}
 */
proto.dibric.portal.v1.UninstallExtensionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.UninstallExtensionRequest;
  return proto.dibric.portal.v1.UninstallExtensionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.UninstallExtensionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.UninstallExtensionRequest}
 */
proto.dibric.portal.v1.UninstallExtensionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.UninstallExtensionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.UninstallExtensionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.UninstallExtensionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.UninstallExtensionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.dibric.portal.v1.UninstallExtensionRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.UninstallExtensionRequest} returns this
 */
proto.dibric.portal.v1.UninstallExtensionRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.UninstallExtensionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.UninstallExtensionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.UninstallExtensionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.UninstallExtensionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.UninstallExtensionResponse}
 */
proto.dibric.portal.v1.UninstallExtensionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.UninstallExtensionResponse;
  return proto.dibric.portal.v1.UninstallExtensionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.UninstallExtensionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.UninstallExtensionResponse}
 */
proto.dibric.portal.v1.UninstallExtensionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.UninstallExtensionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.UninstallExtensionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.UninstallExtensionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.UninstallExtensionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dibric.portal.v1.Extension.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.Extension.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.Extension.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.Extension} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.Extension.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    identifier: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tenantId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    tenantName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    installCount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    created: jspb.Message.getFieldWithDefault(msg, 6, 0),
    lastModified: jspb.Message.getFieldWithDefault(msg, 7, 0),
    latestVersion: (f = msg.getLatestVersion()) && proto.dibric.portal.v1.VersionSummary.toObject(includeInstance, f),
    allVersionsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.Extension}
 */
proto.dibric.portal.v1.Extension.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.Extension;
  return proto.dibric.portal.v1.Extension.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.Extension} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.Extension}
 */
proto.dibric.portal.v1.Extension.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentifier(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInstallCount(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreated(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastModified(value);
      break;
    case 8:
      var value = new proto.dibric.portal.v1.VersionSummary;
      reader.readMessage(value,proto.dibric.portal.v1.VersionSummary.deserializeBinaryFromReader);
      msg.setLatestVersion(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addAllVersions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.Extension.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.Extension.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.Extension} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.Extension.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIdentifier();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTenantName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getInstallCount();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getCreated();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getLastModified();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getLatestVersion();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.dibric.portal.v1.VersionSummary.serializeBinaryToWriter
    );
  }
  f = message.getAllVersionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.dibric.portal.v1.Extension.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.Extension} returns this
 */
proto.dibric.portal.v1.Extension.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string identifier = 2;
 * @return {string}
 */
proto.dibric.portal.v1.Extension.prototype.getIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.Extension} returns this
 */
proto.dibric.portal.v1.Extension.prototype.setIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string tenant_id = 3;
 * @return {string}
 */
proto.dibric.portal.v1.Extension.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.Extension} returns this
 */
proto.dibric.portal.v1.Extension.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string tenant_name = 4;
 * @return {string}
 */
proto.dibric.portal.v1.Extension.prototype.getTenantName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.Extension} returns this
 */
proto.dibric.portal.v1.Extension.prototype.setTenantName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 install_count = 5;
 * @return {number}
 */
proto.dibric.portal.v1.Extension.prototype.getInstallCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.portal.v1.Extension} returns this
 */
proto.dibric.portal.v1.Extension.prototype.setInstallCount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 created = 6;
 * @return {number}
 */
proto.dibric.portal.v1.Extension.prototype.getCreated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.portal.v1.Extension} returns this
 */
proto.dibric.portal.v1.Extension.prototype.setCreated = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 last_modified = 7;
 * @return {number}
 */
proto.dibric.portal.v1.Extension.prototype.getLastModified = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.portal.v1.Extension} returns this
 */
proto.dibric.portal.v1.Extension.prototype.setLastModified = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional VersionSummary latest_version = 8;
 * @return {?proto.dibric.portal.v1.VersionSummary}
 */
proto.dibric.portal.v1.Extension.prototype.getLatestVersion = function() {
  return /** @type{?proto.dibric.portal.v1.VersionSummary} */ (
    jspb.Message.getWrapperField(this, proto.dibric.portal.v1.VersionSummary, 8));
};


/**
 * @param {?proto.dibric.portal.v1.VersionSummary|undefined} value
 * @return {!proto.dibric.portal.v1.Extension} returns this
*/
proto.dibric.portal.v1.Extension.prototype.setLatestVersion = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.Extension} returns this
 */
proto.dibric.portal.v1.Extension.prototype.clearLatestVersion = function() {
  return this.setLatestVersion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.Extension.prototype.hasLatestVersion = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated string all_versions = 9;
 * @return {!Array<string>}
 */
proto.dibric.portal.v1.Extension.prototype.getAllVersionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.dibric.portal.v1.Extension} returns this
 */
proto.dibric.portal.v1.Extension.prototype.setAllVersionsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.dibric.portal.v1.Extension} returns this
 */
proto.dibric.portal.v1.Extension.prototype.addAllVersions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dibric.portal.v1.Extension} returns this
 */
proto.dibric.portal.v1.Extension.prototype.clearAllVersionsList = function() {
  return this.setAllVersionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dibric.portal.v1.ExtensionVersion.repeatedFields_ = [17,23];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.ExtensionVersion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.ExtensionVersion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.ExtensionVersion.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    logo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    created: jspb.Message.getFieldWithDefault(msg, 5, 0),
    lastModified: jspb.Message.getFieldWithDefault(msg, 6, 0),
    readme: jspb.Message.getFieldWithDefault(msg, 7, ""),
    completeLicense: jspb.Message.getFieldWithDefault(msg, 8, ""),
    license: jspb.Message.getFieldWithDefault(msg, 9, ""),
    product: jspb.Message.getFieldWithDefault(msg, 10, ""),
    author: (f = msg.getAuthor()) && proto.dibric.portal.v1.Author.toObject(includeInstance, f),
    company: jspb.Message.getFieldWithDefault(msg, 12, ""),
    homepage: jspb.Message.getFieldWithDefault(msg, 13, ""),
    repository: (f = msg.getRepository()) && proto.dibric.portal.v1.Repository.toObject(includeInstance, f),
    bugs: (f = msg.getBugs()) && proto.dibric.portal.v1.Bugs.toObject(includeInstance, f),
    releaseNotes: jspb.Message.getFieldWithDefault(msg, 16, ""),
    keywordsList: (f = jspb.Message.getRepeatedField(msg, 17)) == null ? undefined : f,
    certified: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    deprecated: (f = msg.getDeprecated()) && proto.dibric.portal.v1.Deprecation.toObject(includeInstance, f),
    extensionId: jspb.Message.getFieldWithDefault(msg, 20, ""),
    tenantId: jspb.Message.getFieldWithDefault(msg, 21, ""),
    tenantName: jspb.Message.getFieldWithDefault(msg, 22, ""),
    tasksList: jspb.Message.toObjectList(msg.getTasksList(),
    proto.dibric.portal.v1.TaskSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.ExtensionVersion}
 */
proto.dibric.portal.v1.ExtensionVersion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.ExtensionVersion;
  return proto.dibric.portal.v1.ExtensionVersion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.ExtensionVersion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.ExtensionVersion}
 */
proto.dibric.portal.v1.ExtensionVersion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogo(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreated(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastModified(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setReadme(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompleteLicense(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLicense(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setProduct(value);
      break;
    case 11:
      var value = new proto.dibric.portal.v1.Author;
      reader.readMessage(value,proto.dibric.portal.v1.Author.deserializeBinaryFromReader);
      msg.setAuthor(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompany(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setHomepage(value);
      break;
    case 14:
      var value = new proto.dibric.portal.v1.Repository;
      reader.readMessage(value,proto.dibric.portal.v1.Repository.deserializeBinaryFromReader);
      msg.setRepository(value);
      break;
    case 15:
      var value = new proto.dibric.portal.v1.Bugs;
      reader.readMessage(value,proto.dibric.portal.v1.Bugs.deserializeBinaryFromReader);
      msg.setBugs(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setReleaseNotes(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.addKeywords(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCertified(value);
      break;
    case 19:
      var value = new proto.dibric.portal.v1.Deprecation;
      reader.readMessage(value,proto.dibric.portal.v1.Deprecation.deserializeBinaryFromReader);
      msg.setDeprecated(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setExtensionId(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantName(value);
      break;
    case 23:
      var value = new proto.dibric.portal.v1.TaskSummary;
      reader.readMessage(value,proto.dibric.portal.v1.TaskSummary.deserializeBinaryFromReader);
      msg.addTasks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.ExtensionVersion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.ExtensionVersion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.ExtensionVersion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLogo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreated();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getLastModified();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getReadme();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCompleteLicense();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getLicense();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getProduct();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getAuthor();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.dibric.portal.v1.Author.serializeBinaryToWriter
    );
  }
  f = message.getCompany();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getHomepage();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getRepository();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.dibric.portal.v1.Repository.serializeBinaryToWriter
    );
  }
  f = message.getBugs();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.dibric.portal.v1.Bugs.serializeBinaryToWriter
    );
  }
  f = message.getReleaseNotes();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getKeywordsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      17,
      f
    );
  }
  f = message.getCertified();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getDeprecated();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.dibric.portal.v1.Deprecation.serializeBinaryToWriter
    );
  }
  f = message.getExtensionId();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getTenantName();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getTasksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      23,
      f,
      proto.dibric.portal.v1.TaskSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional string version = 1;
 * @return {string}
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.ExtensionVersion} returns this
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.ExtensionVersion} returns this
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.ExtensionVersion} returns this
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string logo = 4;
 * @return {string}
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.getLogo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.ExtensionVersion} returns this
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.setLogo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 created = 5;
 * @return {number}
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.getCreated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.portal.v1.ExtensionVersion} returns this
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.setCreated = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 last_modified = 6;
 * @return {number}
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.getLastModified = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.portal.v1.ExtensionVersion} returns this
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.setLastModified = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string readme = 7;
 * @return {string}
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.getReadme = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.ExtensionVersion} returns this
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.setReadme = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string complete_license = 8;
 * @return {string}
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.getCompleteLicense = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.ExtensionVersion} returns this
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.setCompleteLicense = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string license = 9;
 * @return {string}
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.getLicense = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.ExtensionVersion} returns this
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.setLicense = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string product = 10;
 * @return {string}
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.getProduct = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.ExtensionVersion} returns this
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.setProduct = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional Author author = 11;
 * @return {?proto.dibric.portal.v1.Author}
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.getAuthor = function() {
  return /** @type{?proto.dibric.portal.v1.Author} */ (
    jspb.Message.getWrapperField(this, proto.dibric.portal.v1.Author, 11));
};


/**
 * @param {?proto.dibric.portal.v1.Author|undefined} value
 * @return {!proto.dibric.portal.v1.ExtensionVersion} returns this
*/
proto.dibric.portal.v1.ExtensionVersion.prototype.setAuthor = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.ExtensionVersion} returns this
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.clearAuthor = function() {
  return this.setAuthor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.hasAuthor = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string company = 12;
 * @return {string}
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.getCompany = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.ExtensionVersion} returns this
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.setCompany = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string homepage = 13;
 * @return {string}
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.getHomepage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.ExtensionVersion} returns this
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.setHomepage = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional Repository repository = 14;
 * @return {?proto.dibric.portal.v1.Repository}
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.getRepository = function() {
  return /** @type{?proto.dibric.portal.v1.Repository} */ (
    jspb.Message.getWrapperField(this, proto.dibric.portal.v1.Repository, 14));
};


/**
 * @param {?proto.dibric.portal.v1.Repository|undefined} value
 * @return {!proto.dibric.portal.v1.ExtensionVersion} returns this
*/
proto.dibric.portal.v1.ExtensionVersion.prototype.setRepository = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.ExtensionVersion} returns this
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.clearRepository = function() {
  return this.setRepository(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.hasRepository = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional Bugs bugs = 15;
 * @return {?proto.dibric.portal.v1.Bugs}
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.getBugs = function() {
  return /** @type{?proto.dibric.portal.v1.Bugs} */ (
    jspb.Message.getWrapperField(this, proto.dibric.portal.v1.Bugs, 15));
};


/**
 * @param {?proto.dibric.portal.v1.Bugs|undefined} value
 * @return {!proto.dibric.portal.v1.ExtensionVersion} returns this
*/
proto.dibric.portal.v1.ExtensionVersion.prototype.setBugs = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.ExtensionVersion} returns this
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.clearBugs = function() {
  return this.setBugs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.hasBugs = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional string release_notes = 16;
 * @return {string}
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.getReleaseNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.ExtensionVersion} returns this
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.setReleaseNotes = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * repeated string keywords = 17;
 * @return {!Array<string>}
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.getKeywordsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 17));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.dibric.portal.v1.ExtensionVersion} returns this
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.setKeywordsList = function(value) {
  return jspb.Message.setField(this, 17, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.dibric.portal.v1.ExtensionVersion} returns this
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.addKeywords = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 17, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dibric.portal.v1.ExtensionVersion} returns this
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.clearKeywordsList = function() {
  return this.setKeywordsList([]);
};


/**
 * optional bool certified = 18;
 * @return {boolean}
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.getCertified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dibric.portal.v1.ExtensionVersion} returns this
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.setCertified = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional Deprecation deprecated = 19;
 * @return {?proto.dibric.portal.v1.Deprecation}
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.getDeprecated = function() {
  return /** @type{?proto.dibric.portal.v1.Deprecation} */ (
    jspb.Message.getWrapperField(this, proto.dibric.portal.v1.Deprecation, 19));
};


/**
 * @param {?proto.dibric.portal.v1.Deprecation|undefined} value
 * @return {!proto.dibric.portal.v1.ExtensionVersion} returns this
*/
proto.dibric.portal.v1.ExtensionVersion.prototype.setDeprecated = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.ExtensionVersion} returns this
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.clearDeprecated = function() {
  return this.setDeprecated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.hasDeprecated = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional string extension_id = 20;
 * @return {string}
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.getExtensionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.ExtensionVersion} returns this
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.setExtensionId = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string tenant_id = 21;
 * @return {string}
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.ExtensionVersion} returns this
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string tenant_name = 22;
 * @return {string}
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.getTenantName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.ExtensionVersion} returns this
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.setTenantName = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * repeated TaskSummary tasks = 23;
 * @return {!Array<!proto.dibric.portal.v1.TaskSummary>}
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.getTasksList = function() {
  return /** @type{!Array<!proto.dibric.portal.v1.TaskSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dibric.portal.v1.TaskSummary, 23));
};


/**
 * @param {!Array<!proto.dibric.portal.v1.TaskSummary>} value
 * @return {!proto.dibric.portal.v1.ExtensionVersion} returns this
*/
proto.dibric.portal.v1.ExtensionVersion.prototype.setTasksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 23, value);
};


/**
 * @param {!proto.dibric.portal.v1.TaskSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dibric.portal.v1.TaskSummary}
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.addTasks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 23, opt_value, proto.dibric.portal.v1.TaskSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dibric.portal.v1.ExtensionVersion} returns this
 */
proto.dibric.portal.v1.ExtensionVersion.prototype.clearTasksList = function() {
  return this.setTasksList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dibric.portal.v1.VersionSummary.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.VersionSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.VersionSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.VersionSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.VersionSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    logo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    certified: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    deprecated: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    created: jspb.Message.getFieldWithDefault(msg, 7, 0),
    lastModified: jspb.Message.getFieldWithDefault(msg, 8, 0),
    releaseNotes: jspb.Message.getFieldWithDefault(msg, 9, ""),
    tasksList: jspb.Message.toObjectList(msg.getTasksList(),
    proto.dibric.portal.v1.TaskSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.VersionSummary}
 */
proto.dibric.portal.v1.VersionSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.VersionSummary;
  return proto.dibric.portal.v1.VersionSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.VersionSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.VersionSummary}
 */
proto.dibric.portal.v1.VersionSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogo(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCertified(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeprecated(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreated(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastModified(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setReleaseNotes(value);
      break;
    case 10:
      var value = new proto.dibric.portal.v1.TaskSummary;
      reader.readMessage(value,proto.dibric.portal.v1.TaskSummary.deserializeBinaryFromReader);
      msg.addTasks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.VersionSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.VersionSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.VersionSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.VersionSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLogo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCertified();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getDeprecated();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getCreated();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getLastModified();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getReleaseNotes();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getTasksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.dibric.portal.v1.TaskSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional string version = 1;
 * @return {string}
 */
proto.dibric.portal.v1.VersionSummary.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.VersionSummary} returns this
 */
proto.dibric.portal.v1.VersionSummary.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.dibric.portal.v1.VersionSummary.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.VersionSummary} returns this
 */
proto.dibric.portal.v1.VersionSummary.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.dibric.portal.v1.VersionSummary.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.VersionSummary} returns this
 */
proto.dibric.portal.v1.VersionSummary.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string logo = 4;
 * @return {string}
 */
proto.dibric.portal.v1.VersionSummary.prototype.getLogo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.VersionSummary} returns this
 */
proto.dibric.portal.v1.VersionSummary.prototype.setLogo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool certified = 5;
 * @return {boolean}
 */
proto.dibric.portal.v1.VersionSummary.prototype.getCertified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dibric.portal.v1.VersionSummary} returns this
 */
proto.dibric.portal.v1.VersionSummary.prototype.setCertified = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool deprecated = 6;
 * @return {boolean}
 */
proto.dibric.portal.v1.VersionSummary.prototype.getDeprecated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dibric.portal.v1.VersionSummary} returns this
 */
proto.dibric.portal.v1.VersionSummary.prototype.setDeprecated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional int64 created = 7;
 * @return {number}
 */
proto.dibric.portal.v1.VersionSummary.prototype.getCreated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.portal.v1.VersionSummary} returns this
 */
proto.dibric.portal.v1.VersionSummary.prototype.setCreated = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 last_modified = 8;
 * @return {number}
 */
proto.dibric.portal.v1.VersionSummary.prototype.getLastModified = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.portal.v1.VersionSummary} returns this
 */
proto.dibric.portal.v1.VersionSummary.prototype.setLastModified = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string release_notes = 9;
 * @return {string}
 */
proto.dibric.portal.v1.VersionSummary.prototype.getReleaseNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.VersionSummary} returns this
 */
proto.dibric.portal.v1.VersionSummary.prototype.setReleaseNotes = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * repeated TaskSummary tasks = 10;
 * @return {!Array<!proto.dibric.portal.v1.TaskSummary>}
 */
proto.dibric.portal.v1.VersionSummary.prototype.getTasksList = function() {
  return /** @type{!Array<!proto.dibric.portal.v1.TaskSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dibric.portal.v1.TaskSummary, 10));
};


/**
 * @param {!Array<!proto.dibric.portal.v1.TaskSummary>} value
 * @return {!proto.dibric.portal.v1.VersionSummary} returns this
*/
proto.dibric.portal.v1.VersionSummary.prototype.setTasksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.dibric.portal.v1.TaskSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dibric.portal.v1.TaskSummary}
 */
proto.dibric.portal.v1.VersionSummary.prototype.addTasks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.dibric.portal.v1.TaskSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dibric.portal.v1.VersionSummary} returns this
 */
proto.dibric.portal.v1.VersionSummary.prototype.clearTasksList = function() {
  return this.setTasksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.TaskSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.TaskSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.TaskSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.TaskSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    logo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    type: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.TaskSummary}
 */
proto.dibric.portal.v1.TaskSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.TaskSummary;
  return proto.dibric.portal.v1.TaskSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.TaskSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.TaskSummary}
 */
proto.dibric.portal.v1.TaskSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogo(value);
      break;
    case 5:
      var value = /** @type {!proto.dibric.portal.v1.ExtensionTaskType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.TaskSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.TaskSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.TaskSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.TaskSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLogo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.dibric.portal.v1.TaskSummary.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.TaskSummary} returns this
 */
proto.dibric.portal.v1.TaskSummary.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.dibric.portal.v1.TaskSummary.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.TaskSummary} returns this
 */
proto.dibric.portal.v1.TaskSummary.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.dibric.portal.v1.TaskSummary.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.TaskSummary} returns this
 */
proto.dibric.portal.v1.TaskSummary.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string logo = 4;
 * @return {string}
 */
proto.dibric.portal.v1.TaskSummary.prototype.getLogo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.TaskSummary} returns this
 */
proto.dibric.portal.v1.TaskSummary.prototype.setLogo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional ExtensionTaskType type = 5;
 * @return {!proto.dibric.portal.v1.ExtensionTaskType}
 */
proto.dibric.portal.v1.TaskSummary.prototype.getType = function() {
  return /** @type {!proto.dibric.portal.v1.ExtensionTaskType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.dibric.portal.v1.ExtensionTaskType} value
 * @return {!proto.dibric.portal.v1.TaskSummary} returns this
 */
proto.dibric.portal.v1.TaskSummary.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.Repository.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.Repository.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.Repository} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.Repository.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    url: jspb.Message.getFieldWithDefault(msg, 2, ""),
    directory: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.Repository}
 */
proto.dibric.portal.v1.Repository.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.Repository;
  return proto.dibric.portal.v1.Repository.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.Repository} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.Repository}
 */
proto.dibric.portal.v1.Repository.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.dibric.portal.v1.Repository.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDirectory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.Repository.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.Repository.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.Repository} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.Repository.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDirectory();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.dibric.portal.v1.Repository.Type = {
  GITHUB: 0,
  GIST: 1,
  BITBUCKET: 2,
  GITLAB: 3
};

/**
 * optional Type type = 1;
 * @return {!proto.dibric.portal.v1.Repository.Type}
 */
proto.dibric.portal.v1.Repository.prototype.getType = function() {
  return /** @type {!proto.dibric.portal.v1.Repository.Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.dibric.portal.v1.Repository.Type} value
 * @return {!proto.dibric.portal.v1.Repository} returns this
 */
proto.dibric.portal.v1.Repository.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string url = 2;
 * @return {string}
 */
proto.dibric.portal.v1.Repository.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.Repository} returns this
 */
proto.dibric.portal.v1.Repository.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string directory = 3;
 * @return {string}
 */
proto.dibric.portal.v1.Repository.prototype.getDirectory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.Repository} returns this
 */
proto.dibric.portal.v1.Repository.prototype.setDirectory = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.Bugs.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.Bugs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.Bugs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.Bugs.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    url: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.Bugs}
 */
proto.dibric.portal.v1.Bugs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.Bugs;
  return proto.dibric.portal.v1.Bugs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.Bugs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.Bugs}
 */
proto.dibric.portal.v1.Bugs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.Bugs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.Bugs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.Bugs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.Bugs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.dibric.portal.v1.Bugs.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.Bugs} returns this
 */
proto.dibric.portal.v1.Bugs.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string url = 2;
 * @return {string}
 */
proto.dibric.portal.v1.Bugs.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.Bugs} returns this
 */
proto.dibric.portal.v1.Bugs.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.Author.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.Author.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.Author} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.Author.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    url: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.Author}
 */
proto.dibric.portal.v1.Author.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.Author;
  return proto.dibric.portal.v1.Author.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.Author} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.Author}
 */
proto.dibric.portal.v1.Author.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.Author.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.Author.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.Author} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.Author.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.dibric.portal.v1.Author.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.Author} returns this
 */
proto.dibric.portal.v1.Author.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.dibric.portal.v1.Author.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.Author} returns this
 */
proto.dibric.portal.v1.Author.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string url = 3;
 * @return {string}
 */
proto.dibric.portal.v1.Author.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.Author} returns this
 */
proto.dibric.portal.v1.Author.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.Deprecation.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.Deprecation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.Deprecation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.Deprecation.toObject = function(includeInstance, msg) {
  var f, obj = {
    endOfLife: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.Deprecation}
 */
proto.dibric.portal.v1.Deprecation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.Deprecation;
  return proto.dibric.portal.v1.Deprecation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.Deprecation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.Deprecation}
 */
proto.dibric.portal.v1.Deprecation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndOfLife(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.Deprecation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.Deprecation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.Deprecation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.Deprecation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEndOfLife();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 end_of_life = 1;
 * @return {number}
 */
proto.dibric.portal.v1.Deprecation.prototype.getEndOfLife = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.portal.v1.Deprecation} returns this
 */
proto.dibric.portal.v1.Deprecation.prototype.setEndOfLife = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.dibric.portal.v1.Deprecation.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.Deprecation} returns this
 */
proto.dibric.portal.v1.Deprecation.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.dibric.portal.v1.ProgressStatus = {
  UNKNOWN: 0,
  INITIALIZING: 1,
  RUNNING: 2,
  DONE: 3
};

/**
 * @enum {number}
 */
proto.dibric.portal.v1.ExtensionTaskType = {
  UNKNOWN_TASK: 0,
  FUNCTION: 1,
  WIDGET: 2
};

goog.object.extend(exports, proto.dibric.portal.v1);
