import { RpcError } from 'grpc-web';
import { ClientAppServicePromiseClient } from '../protos/portal/v1/clientapp_service_grpc_web_pb';
import { ClientAppConfigurationRequest, ClientAppConfigurationResponse } from '../protos/portal/v1/clientapp_service_pb';
import { handleUnauthorized } from './utils';

const service = new ClientAppServicePromiseClient(`${window.location.protocol}//${window.location.host}`);

export async function getConfiguration(): Promise<ClientAppConfigurationResponse> {
  try {
    return await service.getConfiguration(new ClientAppConfigurationRequest(), undefined);
  } catch (error: unknown) {
    if (error instanceof RpcError && handleUnauthorized(error)) {
      console.log('Unauthorized. Redirecting to login....');
    }
    throw error;
  }
}

export {
  ClientAppConfigurationResponse
};

