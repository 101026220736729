import { Metadata, RpcError } from 'grpc-web';

export function getAntiForgeryCookie(cookieName = 'XSRF-TOKEN') {
  const allCookies = decodeURIComponent(document.cookie).split('; ');
  for (const cookie of allCookies) {
    if (cookie.startsWith(cookieName + '=')) {
      return cookie.substring(cookieName.length + 1);
    }
  }
  return '';
}

export function getDefaultMetadata(): Metadata {
  const metadata: Metadata = {};
  metadata['X-XSRF-TOKEN'] = getAntiForgeryCookie();
  return metadata;
}

export function handleUnauthorized(error: RpcError): boolean {
  if (error.code === 16) {
    window.location.replace('/signin');
    return true;
  }
  return false;
}
