import {
  Construction,
  DeleteForever,
  Edit,
  Image,
  MoreHoriz,
  NavigateNext,
  Web
} from '@mui/icons-material';
import {
  Breadcrumbs,
  Container,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tabs,
  Typography,
  styled
} from '@mui/material';
import { RpcError } from 'grpc-web';
import { useContext, useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import defaultIllustration from '../assets/graph-illustration.jpg';
import notFoundIllustration from '../assets/page-not-found-illustration.png';
import { Breadcrumb } from '../breadcrumbs/Breadcrumb';
import {
  DeleteWorkspaceRequest,
  deleteWorkspace
} from '../services/workspace';
import { ConfirmationDialog } from '../shared/ConfirmationDialog';
import { SomethingWrongDialog } from '../shared/SomethingWrongDialog';
import { fuzzyDate } from '../shared/utils';
import { LinkTab } from '../tabs/LinkTab';
import { UpdateWorkspaceDialog } from './UpdateWorkspaceDialog';
import { WorkspaceContext } from './WorkspaceWrapper';

const WorkspaceImage = styled('img')({ maxHeight: 150 });

export function WorkspaceDetails() {
  const navigate = useNavigate();
  const { workspaceId } = useParams();
  const { pathname } = useLocation();
  const slug = !workspaceId || pathname.endsWith(workspaceId)
    ? 'projects'
    : pathname.split('/').at(-1);

  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [deleteWorkspaceConfirm, setDeleteWorkspaceConfirm] = useState<boolean>(false);
  const [updateOpen, setUpdateOpen] = useState<boolean>(false);
  const [wrongText, setWrongText] = useState<string>('');

  const {
    workspace,
    loading: workspaceIsLoading,
    exists: workspaceExists,
    setWorkspace,
  } = useContext(WorkspaceContext);

  async function handleDeleteWorkspaceConfirm(accept: boolean) {
    if (!workspaceId) {
      return;
    }
    setDeleteWorkspaceConfirm(false);
    if (!accept) {
      return;
    }

    try {
      setIsDeleting(true);
      const request = new DeleteWorkspaceRequest().setId(workspaceId);
      await deleteWorkspace(request);
      navigate('/workspaces');
    } catch (error: unknown) {
      if (error instanceof RpcError && error.code === 5) {
        navigate('/workspaces');
      } else {
        setWrongText('Something went wrong while deleting the extension. Try again later');
        throw error;
      }
    } finally {
      setIsDeleting(false);
    }
  }
  const loading = workspaceIsLoading || isDeleting;

  const breadcrumbs = useMemo(
    () => {
      const crumbs = [
        <Breadcrumb to="/workspaces" key="1">workspaces</Breadcrumb>,
      ];
      if (workspaceExists) {
        crumbs.push(
          <Breadcrumb to={`/workspaces/${workspaceId}`} key="2">{workspace.getName()}</Breadcrumb>,
          <Breadcrumb to={`/workspaces/${workspaceId}/${slug}`} key="3">{slug}</Breadcrumb>,
        );
      }
      return crumbs;
    },
    [workspaceExists, workspace, workspaceId, slug]
  );

  const menuOpen = Boolean(menuAnchor);
  return (
    <Stack flexDirection="column" alignItems="stretch">
      <LinearProgress
        color="primary"
        style={{ height: 5, visibility: loading ? 'visible' : 'hidden' }}
        aria-label="Loading workspace"
      />
      <Stack flexDirection="row" sx={{ p: 2 }}>
        <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
        <div style={{ flex: 1 }} />
        <IconButton
          id="menu-button"
          aria-controls={menuOpen ? 'top-menu' : undefined}
          aria-haspopup="true"
          onClick={(event) => setMenuAnchor(event.currentTarget)}
          aria-expanded={menuOpen ? 'true' : undefined}
        >
          <MoreHoriz />
        </IconButton>
      </Stack>
      {!workspaceExists && (<>
        <img style={{ alignSelf: 'center' }} src={notFoundIllustration} alt="Not found illustration" />
        <Stack flexDirection="column" alignItems="flex-start" sx={{ alignSelf: 'center' }}>
          <Typography variant="h4">
            We could not find the workspace
          </Typography>
          <Typography>
            Try going back and search what you&apos;re looking for <Link
              component="button"
              onClick={() => navigate('/workspaces')}
              fontSize="inherit">here</Link>
          </Typography>
        </Stack>
      </>)}
      {workspaceExists && (
        <Container>
          <Stack direction="row" alignItems="center" sx={{ mb: 4, mt: 4 }} spacing={4}>
            <WorkspaceImage
              src={workspace.getImage() || defaultIllustration}
              alt="Extension illustration"
            />
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h5">
                  {workspace.getName()}
                  <IconButton
                    onClick={() => setUpdateOpen(true)}
                    sx={{ color: 'text.secondary' }}
                    size="small"
                  >
                    <Edit />
                  </IconButton>
                </Typography>
                <Typography variant="caption">
                  {workspace.getDescription()}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Stack>
                  <Typography variant="caption">
                    Created: {fuzzyDate(workspace.getCreated())}
                  </Typography>
                  <Typography variant="caption">
                    Last modified: {fuzzyDate(workspace.getLastChanged())}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
          <Tabs
            value={slug}
            aria-label="workspace tabs"
            role="navigation"
          >
            <LinkTab
              icon={<Construction />}
              label="Projects"
              value="projects"
            />
            <LinkTab
              icon={<Web />}
              label="Deployments"
              value="deployments"
            />
            <LinkTab
              icon={<Image />}
              label="Snapshots"
              value="snapshots"
            />
          </Tabs>
          <Outlet />
        </Container>
      )}
      {workspaceId && workspace && (
        <UpdateWorkspaceDialog
          workspaceId={workspaceId}
          defaultName={workspace.getName()}
          defaultDescription={workspace.getDescription()}
          currentVersion={workspace.getEtag()}
          defaultImage={workspace.getImage()}
          open={updateOpen}
          onClose={(item) => {
            setUpdateOpen(false);
            if (item) {
              setWorkspace(item);
            }
          }}
        />
      )}
      <Menu
        id="top-menu"
        aria-labelledby="menu-button"
        anchorEl={menuAnchor}
        open={menuOpen}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <MenuItem
          onClick={() => {
            setDeleteWorkspaceConfirm(true);
            setMenuAnchor(null);
          }}
        >
          <ListItemIcon>
            <DeleteForever fontSize="small" color="secondary" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
      <SomethingWrongDialog text={wrongText} open={Boolean(wrongText)} onClose={() => setWrongText('')} />
      <ConfirmationDialog
        open={deleteWorkspaceConfirm}
        onClose={handleDeleteWorkspaceConfirm}
        cancelButtonText="Cancel"
        cancelButtonColor="inherit"
        acceptButtonText="Delete"
        acceptButtonVariant="contained"
        acceptButtonColor="secondary"
        title="Are you sure?"
        contentText="The workspace will forever be deleted and all items in it will not be recoverable"
      />
    </Stack>
  );
}
