import {createTheme, ThemeOptions} from '@mui/material';

const scrollbarSize = 7;

const themeOptions: ThemeOptions = {
  typography: {
    fontFamily: 'Montserrat',
  },
  palette: {
    primary: {
      main: '#00AB55',
      light: '#5BE584',
      dark: '#007B55',
      contrastText: '#fff',
    },
    secondary: {
      main: '#FF5557',
    },
    background: {
      default: '#fff',
    },
    info: {
      main: '#009EFF',
    },
    success: {
      main: '#81BE53',
    },
    warning: {
      main: '#FFC107',
    },
    error: {
      main: '#FF4842',
      contrastText: '#fff',
    },
  },
  mixins: {
    toolbar: {
      minHeight: 64,
    },
  },
  shadows: [
    'none',
    'rgb(145 158 171 / 24%) 0px 1px 2px 0px',
    'rgb(145 158 171 / 20%) 0px 3px 1px -2px, rgb(145 158 171 / 14%) 0px 2px 2px 0px, rgb(145 158 171 / 12%) 0px 1px 5px 0px',
    'rgb(145 158 171 / 20%) 0px 3px 3px -2px, rgb(145 158 171 / 14%) 0px 3px 4px 0px, rgb(145 158 171 / 12%) 0px 1px 8px 0px',
    'rgb(145 158 171 / 20%) 0px 2px 4px -1px, rgb(145 158 171 / 14%) 0px 4px 5px 0px, rgb(145 158 171 / 12%) 0px 1px 10px 0px',
    'rgb(145 158 171 / 20%) 0px 3px 5px -1px, rgb(145 158 171 / 14%) 0px 5px 8px 0px, rgb(145 158 171 / 12%) 0px 1px 14px 0px',
    'rgb(145 158 171 / 20%) 0px 3px 5px -1px, rgb(145 158 171 / 14%) 0px 6px 10px 0px, rgb(145 158 171 / 12%) 0px 1px 18px 0px',
    'rgb(145 158 171 / 20%) 0px 4px 5px -2px, rgb(145 158 171 / 14%) 0px 7px 10px 1px, rgb(145 158 171 / 12%) 0px 2px 16px 1px',
    'rgb(145 158 171 / 24%) 0px 8px 16px 0px',
    'rgb(145 158 171 / 20%) 0px 5px 6px -3px, rgb(145 158 171 / 14%) 0px 9px 12px 1px, rgb(145 158 171 / 12%) 0px 3px 16px 2px',
    'rgb(145 158 171 / 20%) 0px 6px 6px -3px, rgb(145 158 171 / 14%) 0px 10px 14px 1px, rgb(145 158 171 / 12%) 0px 4px 18px 3px',
    'rgb(145 158 171 / 20%) 0px 6px 7px -4px, rgb(145 158 171 / 14%) 0px 11px 15px 1px, rgb(145 158 171 / 12%) 0px 4px 20px 3px',
    'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 12px 24px 0px',
    'rgb(145 158 171 / 20%) 0px 7px 8px -4px, rgb(145 158 171 / 14%) 0px 13px 19px 2px, rgb(145 158 171 / 12%) 0px 5px 24px 4px',
    'rgb(145 158 171 / 20%) 0px 7px 9px -4px, rgb(145 158 171 / 14%) 0px 14px 21px 2px, rgb(145 158 171 / 12%) 0px 5px 26px 4px',
    'rgb(145 158 171 / 20%) 0px 8px 9px -5px, rgb(145 158 171 / 14%) 0px 15px 22px 2px, rgb(145 158 171 / 12%) 0px 6px 28px 5px',
    'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
    'rgb(145 158 171 / 20%) 0px 8px 11px -5px, rgb(145 158 171 / 14%) 0px 17px 26px 2px, rgb(145 158 171 / 12%) 0px 6px 32px 5px',
    'rgb(145 158 171 / 20%) 0px 9px 11px -5px, rgb(145 158 171 / 14%) 0px 18px 28px 2px, rgb(145 158 171 / 12%) 0px 7px 34px 6px',
    'rgb(145 158 171 / 20%) 0px 9px 12px -6px, rgb(145 158 171 / 14%) 0px 19px 29px 2px, rgb(145 158 171 / 12%) 0px 7px 36px 6px',
    'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 20px 40px -4px',
    'rgb(145 158 171 / 20%) 0px 10px 13px -6px, rgb(145 158 171 / 14%) 0px 21px 33px 3px, rgb(145 158 171 / 12%) 0px 8px 40px 7px',
    'rgb(145 158 171 / 20%) 0px 10px 14px -6px, rgb(145 158 171 / 14%) 0px 22px 35px 3px, rgb(145 158 171 / 12%) 0px 8px 42px 7px',
    'rgb(145 158 171 / 20%) 0px 11px 14px -7px, rgb(145 158 171 / 14%) 0px 23px 36px 3px, rgb(145 158 171 / 12%) 0px 9px 44px 8px',
    'rgb(145 158 171 / 24%) 0px 0px 4px 0px, rgb(145 158 171 / 24%) 0px 24px 48px 0px',
  ],
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          border: '1px solid #ebf8f2',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '*::-webkit-scrollbar': {
          height: scrollbarSize,
          width: scrollbarSize,
        },
        '*::-webkit-scrollbar-track': {
          background: 'rgb(229 232 235)',
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgb(167 176 184)',
          borderRadius: scrollbarSize,
        },
        '*': {
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
        },
      },
    },
  },
};

const theme = createTheme(themeOptions);
export {theme};
