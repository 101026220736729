import { Add, ExpandMore } from '@mui/icons-material';
import { AccordionActions, Button, Divider, styled, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useMemo, useRef, useState } from 'react';
import { composeValidator, maxLengthValidator, minLengthValidator, patternValidator, requiredValidator, ValidationErrors, ValidatorFn } from '../../../forms/validators';
import { HandleType } from '../../../protos/graph/v1/public_messages_pb';
import { NodeHandle, OutputVariables, Variable } from '../../../services/project';
import { ConfirmationDialog } from '../../../shared/ConfirmationDialog';
import { VariablesEditor } from './VariablesEditor';

type HandleEditorProps = {
  handles: NodeHandle[];
  onChange: (newHandles: NodeHandle[]) => void;
}

const ToolbarContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: theme.spacing(1),
}));

const handleIdPattern = /^[a-z0-9]+[a-z0-9\-_]*[a-z0-9]+$/;
const handleIdValidators: ValidatorFn[] = [
  requiredValidator,
  minLengthValidator(2),
  maxLengthValidator(20),
  patternValidator(handleIdPattern),
];

const handleLabelValidator: ValidatorFn = composeValidator(
  requiredValidator,
  minLengthValidator(2),
  maxLengthValidator(20),
);

const handleDescriptionValidator: ValidatorFn = composeValidator(
  maxLengthValidator(500),
);

function validateUniqueHandleId(value: unknown, handles: NodeHandle[]): ValidationErrors | null {
  if (!value) {
    return null;
  }
  if (handles.some(item => item.getId() === value)) {
    return { unique: true };
  }
  return null;
}

function getNodeHandleIdErrorMessage(errors: ValidationErrors | null): string | undefined {
  if (!errors) {
    return undefined;
  }
  if (errors.required) {
    return 'The id is required';
  }
  if (errors.unique) {
    return 'The id must be unique';
  }
  if (errors.minLength) {
    return `The id must be at least ${errors.minLength} characters long`;
  }
  if (errors.maxLength) {
    return `The id must be at most ${errors.maxLength} characters long`;
  }
  if (errors.regex) {
    return 'The id must contain only lowercase letters, numbers, hyphens, or underscores, and must start and end with a letter or number';
  }
  return '';
}

function getNodeHandleLabelErrorMessage(errors: ValidationErrors | null): string | undefined {
  if (!errors)
    return undefined;
  if (errors.required) {
    return 'The label is required';
  }
  if (errors.minLength) {
    return `The label must be at least ${errors.minLength} characters long`;
  }
  if (errors.maxLength) {
    return `The label must be at most ${errors.maxLength} characters long`;
  }
  return '';
}

function getNodeHandleDescriptionErrorMessage(errors: ValidationErrors | null): string | undefined {
  if (!errors) {
    return undefined;
  }
  if (errors.maxLength) {
    return `The description must be at most ${errors.maxLength} characters long`;
  }
  return '';
}

export function HandleEditor({ handles, onChange }: HandleEditorProps) {
  const [selectedHandleId, setSelectedHandleId] = useState<string | null>(null);
  const handleIdValidator = useMemo<ValidatorFn>(
    () => composeValidator(...handleIdValidators, (value) => validateUniqueHandleId(value, handles.filter(item => item.getId() !== selectedHandleId))),
    [handles, selectedHandleId]
  );
  const selectedHandle = useMemo(() => handles.find(item => item.getId() === selectedHandleId), [selectedHandleId, handles]);
  const [isAddHandlePanelOpen, setAddHandlePanelOpen] = useState<boolean>(false);

  const [newHandleId, setNewHandleId] = useState<string>('');
  const newHandleIdErrors = useMemo(() => handleIdValidator(newHandleId), [newHandleId]);
  const newHandleIdErrorMessage = getNodeHandleIdErrorMessage(newHandleIdErrors);

  const [editHandleId, setEditHandleId] = useState<string>('');
  const editHandleIdErrors = useMemo(() => handleIdValidator(editHandleId), [editHandleId]);
  const editHandleIdErrorMessage = getNodeHandleIdErrorMessage(editHandleIdErrors);

  const [editHandleLabel, setEditHandleLabel] = useState<string>('');
  const editHandleLabelErrors = useMemo(() => handleLabelValidator(editHandleLabel), [editHandleLabel]);
  const editHandleLabelErrorMessage = getNodeHandleLabelErrorMessage(editHandleLabelErrors);

  const [editHandleDescription, setEditHandleDescription] = useState<string>('');
  const editHandleDescriptionErrors = useMemo(() => handleDescriptionValidator(editHandleDescription), [editHandleDescription]);
  const editHandleDescriptionErrorMessage = getNodeHandleDescriptionErrorMessage(editHandleDescriptionErrors);

  const hasMadeHandleChanges = selectedHandle && (
    selectedHandle.getId() !== editHandleId
    || selectedHandle.getLabel() !== editHandleLabel
    || selectedHandle.getDescription() !== editHandleDescription
  );

  const [showConfirmationDialog, setShowConfirmationDialog] = useState<{ index: number, handle: NodeHandle } | null>(null);

  function addHandleClick() {
    if (
      !newHandleId
      || handles.find(item => item.getId() === newHandleId)
    ) {
      return;
    }

    const newHandle = new NodeHandle()
      .setId(newHandleId)
      .setLabel(newHandleId)
      .setType(HandleType.SUCCESS_HANDLE)
      .setOutputVariables(
        new OutputVariables().setVariablesList([])
      );
    onChange([...handles, newHandle]);
    setNewHandleId('');
    setAddHandlePanelOpen(false);
  }

  function confirmRemoveHandle(at: number, handle: NodeHandle): void {
    setShowConfirmationDialog({ index: at, handle });
  }

  function removeHandle(at: number): void {
    onChange(handles.filter((_, index) => index !== at));
    setSelectedHandleId(null);
  }

  function selectHandle(handle: NodeHandle) {
    setSelectedHandleId(handle.getId());
    setEditHandleId(handle.getId());
    setEditHandleLabel(handle.getLabel());
    setEditHandleDescription(handle.getDescription());
    setAddHandlePanelOpen(false);
  }

  function commitEditHandleChanges() {
    if (!selectedHandle) {
      return;
    }
    const newHandle = selectedHandle.clone()
      .setId(editHandleId)
      .setLabel(editHandleLabel)
      .setDescription(editHandleDescription);
    const newHandles = handles.map(item => item.getId() === selectedHandleId ? newHandle : item);
    onChange(newHandles);
    selectHandle(newHandle);
  }

  function handleVariablesChanged(variables: Variable[]) {
    if (!selectedHandle) {
      return;
    }
    const newHandle = NodeHandle.deserializeBinary(selectedHandle.serializeBinary());
    newHandle.setOutputVariables(new OutputVariables().setVariablesList(variables));
    const newHandles = [...handles];
    newHandles[newHandles.findIndex(item => item.getId() === selectedHandleId)] = newHandle;
    onChange(newHandles);
  }

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Stack spacing={2}>
      <Typography variant="subtitle2" fontWeight="bold" sx={{ mb: 2 }}>
        Handles
      </Typography>
      <div>
        {handles.length > 0 && handles.map((handle, index) => (
          <Accordion
            key={handle.getId()}
            expanded={selectedHandleId === handle.getId()}
            onChange={(_, expanded) => expanded ? selectHandle(handle) : setSelectedHandleId(null)}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography>{handle.getLabel()}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack gap={1} sx={{ mb: 1 }}>
                <Typography variant={'subtitle2'} fontWeight={'bold'}>
                  Properties
                </Typography>
                <TextField
                  value={editHandleId}
                  variant="outlined"
                  label="ID"
                  size="small"
                  error={editHandleIdErrors !== null}
                  helperText={editHandleIdErrorMessage || 'Unique identifier used by other nodes to reference this handle'}
                  onChange={event => setEditHandleId(event.target.value)}
                />
                <TextField
                  value={editHandleLabel}
                  variant="outlined"
                  label="Label"
                  size="small"
                  error={editHandleLabelErrors !== null}
                  helperText={editHandleLabelErrorMessage || 'The label displayed in the editor'}
                  onChange={event => setEditHandleLabel(event.target.value)}
                />
                <TextField
                  value={editHandleDescription}
                  variant="outlined"
                  label="Description"
                  size="small"
                  error={editHandleDescriptionErrors !== null}
                  helperText={editHandleDescriptionErrorMessage || 'Description of the handle'}
                  multiline
                  rows={2}
                  onChange={event => setEditHandleDescription(event.target.value)}
                />
              </Stack>
            </AccordionDetails>
            <AccordionActions sx={{ justifyContent: 'space-between' }}>
              <Button
                color="secondary"
                onClick={() => confirmRemoveHandle(index, handle)}
                variant="outlined"
              >Delete</Button>
              <Stack direction="row" gap={1}>
                <Button
                  color="inherit"
                  variant="text"
                  onClick={() => selectHandle(handle)}
                  disabled={!hasMadeHandleChanges}
                >Discard</Button>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => commitEditHandleChanges()}
                  disabled={!hasMadeHandleChanges || editHandleIdErrors !== null || editHandleLabelErrors !== null || editHandleDescriptionErrors !== null}
                >Save</Button>
              </Stack>
            </AccordionActions>
            <Divider />
            <AccordionDetails sx={{ px: 2, pt: 3 }}>
              {selectedHandle && (
                <VariablesEditor
                  variables={selectedHandle.getOutputVariables()?.getVariablesList() as Variable[]}
                  onChange={handleVariablesChanged}
                />
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
      {(handles.length === 0 || isAddHandlePanelOpen) && (
        <Stack gap={1}>
          <TextField
            label="Handle ID"
            variant="outlined"
            size="small"
            error={newHandleId.length > 0 && newHandleIdErrors !== null}
            helperText={newHandleId.length > 0 && newHandleIdErrorMessage}
            value={newHandleId}
            inputRef={inputRef}
            onChange={(event) => setNewHandleId(event.target.value)}
            inputProps={{ min: 2, max: 20, pattern: handleIdPattern.source.substring(1, handleIdPattern.source.length - 1) }}
          />
          <Stack direction="row" justifyContent="flex-end" gap={1}>
            {handles.length > 0 && (
              <Button
                color="inherit"
                onClick={() => setAddHandlePanelOpen(false)}
                variant="text"
              >Cancel</Button>
            )}
            <Button
              startIcon={<Add />}
              disabled={newHandleId.length === 0 || newHandleIdErrors !== null}
              color="primary"
              onClick={addHandleClick}
              variant="outlined"
            >Add handle</Button>
          </Stack>
        </Stack>
      )}
      {handles.length > 0 && !isAddHandlePanelOpen && (
        <ToolbarContainer>
          <Button
            startIcon={<Add />}
            onClick={() => {
              setSelectedHandleId(null);
              setAddHandlePanelOpen(true);
              setTimeout(
                () => {
                  inputRef.current?.focus();
                },
                300
              );
            }}
            variant="text"
            color="primary"
          >New handle</Button>
        </ToolbarContainer>
      )}
      <ConfirmationDialog
        open={showConfirmationDialog !== null}
        title="Delete handle"
        cancelButtonColor="inherit"
        cancelButtonText="No, keep it"
        acceptButtonColor="secondary"
        acceptButtonText="Yes, delete"
        acceptButtonVariant="contained"
        contentText={`Are you sure you want to delete the handle "${showConfirmationDialog?.handle.getLabel() || 'N/A'}"?`}
        onClose={(reason: boolean) => {
          if (reason && showConfirmationDialog) {
            removeHandle(showConfirmationDialog.index);
          }
          setShowConfirmationDialog(null);
        }}
      />
    </Stack>
  );
}