import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExtensionIcon from '@mui/icons-material/Extension';
import WorkIcon from '@mui/icons-material/Work';
import {
  Avatar,
  Box,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  Theme,
  Typography,
  useTheme
} from '@mui/material';
import Drawer from '@mui/material/Drawer';
import { SystemStyleObject } from '@mui/system';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUser } from './hooks/ProvideUser';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));


type MenuDotProps = {
  selected: boolean;
}
const MenuDot = styled('span', {
  shouldForwardProp: (prop) => prop !== 'selected',
})<MenuDotProps>(({ selected, theme }) => ({
  marginLeft: 14,
  '::before': {
    width: 4,
    height: 4,
    content: '""',
    display: 'block',
    transition: 'transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderRadius: '50%',
    backgroundColor: selected ? theme.palette.primary.main : '#919EAB',
    ...(selected && {
      transform: 'scale(2)',
    }),
  },
}));

type CompanyInfoProps = {
  name: string,
  image?: string
}

function CompanyInfo({ name, image }: CompanyInfoProps) {
  const logo = image ?
    (<img src={image} style={{ maxHeight: 50, maxWidth: 150, marginBottom: 5 }} alt={'Tenant logotype'} />)
    :
    (<Avatar style={{ width: 50, height: 50 }}>{name.charAt(0)}</Avatar>);
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'center',
      alignItems: 'center',
      marginTop: -3,
      marginBottom: 1,
    }}>
      {logo}
      <Typography variant={'caption'}>
        {name}
      </Typography>
    </Box>
  );
}

type MenuSubItem = {
  id: string;
  label: string;
  path: string;
}
type MenuItem = {
  id: string;
  label: string;
  icon: JSX.Element;
  path: string;
  items: MenuSubItem[];
}
type MenuListProps = {
  drawerOpen: boolean;
  items: MenuItem[];
  onDrawerOpen: () => void;
}

function MenuList({ drawerOpen, onDrawerOpen, items }: MenuListProps) {
  const [menusOpen, setMenusOpen] = useState<{ [key: string]: boolean }>({});
  const { pathname } = useLocation();
  const navigate = useNavigate();

  function handleItemClick(item: MenuItem) {
    if (item.items.length === 0) {
      navigate(item.path);
    } else {
      const newMenusOpen = { ...menusOpen };
      if (!drawerOpen) {
        onDrawerOpen();
        newMenusOpen[item.id] = true;
      } else {
        newMenusOpen[item.id] = !newMenusOpen[item.id];
      }
      setMenusOpen(newMenusOpen);
    }
  }

  function handleSubItemClick(item: MenuSubItem) {
    navigate(item.path);
  }

  function isSelected(item: MenuItem | MenuSubItem): boolean {
    return pathname.startsWith(item.path);
  }

  return (
    <List style={{ paddingTop: 0 }}>
      {items.map(item => ({ ...item, isSelected: isSelected(item), isMenuOpen: menusOpen[item.id] })).map((item) => (
        <React.Fragment key={item.id}>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => handleItemClick(item)}
              selected={item.isSelected}
              sx={[{
                justifyContent: drawerOpen ? 'initial' : 'center',
                px: 2.5,
              },
              item.isSelected && {
                '::before': {
                  top: 0,
                  right: 0,
                  width: 3,
                  bottom: 0,
                  content: '""',
                  position: 'absolute',
                  backgroundColor: 'primary.main',
                },
              },
              ]}>
              <ListItemIcon
                sx={[{
                  minWidth: 0,
                  mr: drawerOpen ? 3 : 'auto',
                  justifyContent: 'center',
                },
                item.isSelected && {
                  color: 'primary.main',
                },
                ]}>
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.label}
                sx={[
                  { opacity: drawerOpen ? 1 : 0 },
                  {
                    '& .MuiTypography-root': {
                      fontSize: 14,
                    },
                  },
                  item.isSelected && {
                    '& .MuiTypography-root': {
                      color: 'primary.main',
                      fontWeight: 'fontWeightBold',
                    },
                  },
                ]} />
              {item.items.length > 0 && drawerOpen && (
                item.isMenuOpen
                  ? <ExpandMoreIcon fontSize="small" color={item.isSelected ? 'primary' : 'inherit'} />
                  : <ChevronRightIcon fontSize="small" color={item.isSelected ? 'primary' : 'inherit'} />
              )}
            </ListItemButton>
          </ListItem>
          <Collapse in={item.isMenuOpen && drawerOpen} timeout="auto" unmountOnExit>
            <List disablePadding>
              {item.items.map(subItem => ({ ...subItem, isSelected: isSelected(subItem) })).map((subItem) => (
                <ListItem
                  disablePadding
                  key={`${item.id}-${subItem.id}`}
                >
                  <ListItemButton
                    onClick={() => handleSubItemClick(subItem)}
                    selected={subItem.isSelected}
                  >
                    <ListItemIcon>
                      <MenuDot selected={subItem.isSelected} />
                    </ListItemIcon>
                    <ListItemText
                      primary={subItem.label}
                      sx={[
                        {
                          '& .MuiTypography-root': {
                            fontSize: 14,
                          },
                        },
                        subItem.isSelected && {
                          '& .MuiTypography-root': {
                            fontWeight: 'fontWeightBold',
                          },
                        },
                      ]}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Collapse>
        </React.Fragment>
      ))}
    </List>
  );
}

const openedMixin = (theme: Theme, drawerWidth: number): SystemStyleObject<Theme> => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): SystemStyleObject<Theme> => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});


const menuItems: MenuItem[] = [
  {
    id: 'workspaces',
    label: 'Workspaces',
    icon: <WorkIcon />,
    items: [],
    path: '/workspaces',
  },
  {
    id: 'extensions',
    label: 'Extensions',
    icon: <ExtensionIcon />,
    items: [
      {
        id: 'develop-extensions',
        label: 'Develop',
        path: '/extensions/develop',
      },
      {
        id: 'install-extensions',
        label: 'Installed',
        path: '/extensions/installed',
      },
    ],
    path: '/extensions',
  },
];

type LeftDrawerProps = {
  open: boolean;
  drawerWidth: number;
  onClose: () => void;
  onOpen: () => void;
};

export function LeftDrawer({ open, drawerWidth, onClose, onOpen }: LeftDrawerProps) {
  const theme = useTheme();
  const user = useUser();

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        zIndex: theme.zIndex.appBar - 1,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
          ...openedMixin(theme, drawerWidth),
          '& .MuiDrawer-paper': openedMixin(theme, drawerWidth),
        }),
        ...(!open && {
          ...closedMixin(theme),
          '& .MuiDrawer-paper': closedMixin(theme),
        }),
      }}
    >
      <DrawerHeader>
        <IconButton
          aria-label="close drawer"
          onClick={onClose}
        >
          {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </DrawerHeader>
      {open && (<CompanyInfo name={user.tenantName} image={user.tenantLogo} />)}
      <Divider />
      <nav>
        <MenuList drawerOpen={open} items={menuItems} onDrawerOpen={onOpen} />
      </nav>
    </Drawer>
  );
}
