import { Add, ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, FormControl, InputLabel, MenuItem, Select, styled, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useMemo, useRef, useState } from 'react';
import { composeValidator, maxLengthValidator, minLengthValidator, patternValidator, requiredValidator, ValidationErrors, ValidatorFn } from '../../../forms/validators';
import { TypeDescription, ValueType, Variable } from '../../../services/project';
import { ConfirmationDialog } from '../../../shared/ConfirmationDialog';

type VariablesEditorProps = {
  variables: Variable[];
  onChange: (newVariables: Variable[]) => void;
}

const ToolbarContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: theme.spacing(1),
}));

const ContainedDiv = styled('div')({
  maxWidth: '100%',
});

const variableIdPattern = /^[a-z0-9]+[a-z0-9\-_]*[a-z0-9]+$/;
const variableIdValidators: ValidatorFn[] = [
  requiredValidator,
  minLengthValidator(2),
  maxLengthValidator(100),
  patternValidator(variableIdPattern),
];

const variableLabelValidator: ValidatorFn = composeValidator(
  minLengthValidator(2),
  maxLengthValidator(100),
);

const variableDescriptionValidator: ValidatorFn = composeValidator(
  maxLengthValidator(500),
);

function validateUniqueVariableId(value: unknown, variables: Variable[]): ValidationErrors | null {
  if (!value) {
    return null;
  }
  if (variables.some(item => item.getId() === value)) {
    return { unique: true };
  }
  return null;
}

function getVariableIdErrorMessage(errors: ValidationErrors | null): string | undefined {
  if (!errors) {
    return undefined;
  }
  if (errors.required) {
    return 'The id is required';
  }
  if (errors.unique) {
    return 'The id must be unique';
  }
  if (errors.minLength) {
    return `The id must be at least ${errors.minLength} characters long`;
  }
  if (errors.maxLength) {
    return `The id must be at most ${errors.maxLength} characters long`;
  }
  if (errors.regex) {
    return 'The id must contain only lowercase letters, numbers, hyphens, or underscores, and must start and end with a letter or number';
  }
  return '';
}

function getVariableLabelErrorMessage(errors: ValidationErrors | null): string | undefined {
  if (!errors) {
    return undefined;
  }
  if (errors.required) {
    return 'The label is required';
  }
  if (errors.minLength) {
    return `The label must be at least ${errors.minLength} characters long`;
  }
  if (errors.maxLength) {
    return `The label must be at most ${errors.maxLength} characters long`;
  }
  return '';
}

function getVariableDescriptionErrorMessage(errors: ValidationErrors | null): string | undefined {
  if (!errors) {
    return undefined;
  }
  if (errors.maxLength) {
    return `The description must be at most ${errors.maxLength} characters long`;
  }
  return '';
}

function formatVariableType(type: ValueType | undefined): string {
  switch (type) {
    case ValueType.STRING:
      return 'String';
    case ValueType.DECIMAL:
      return 'Decimal';
    case ValueType.INTEGER:
      return 'Integer';
    case ValueType.DATETIME:
      return 'Datetime';
    case ValueType.BOOLEAN:
      return 'Boolean';
    default:
      return 'Unknown';
  }
}

export function VariablesEditor({ variables, onChange }: VariablesEditorProps) {
  const [selectedVariableId, setSelectedVariableId] = useState<string | null>(null);
  const variableIdValidator = useMemo<ValidatorFn>(
    () => composeValidator(...variableIdValidators, (value) => validateUniqueVariableId(value, variables.filter(item => item.getId() !== selectedVariableId))),
    [variables, selectedVariableId]
  );
  const selectedVariable = useMemo(() => variables.find(item => item.getId() === selectedVariableId), [selectedVariableId, variables]);
  const [isAddVariablePanelOpen, setAddVariablePanelOpen] = useState<boolean>(false);

  const [newVariableId, setNewVariableId] = useState<string>('');
  const [newVariableType, setNewVariableType] = useState<ValueType>(ValueType.STRING);
  const newVariableIdErrors = useMemo(() => variableIdValidator(newVariableId), [newVariableId]);
  const newVariableIdErrorMessage = getVariableIdErrorMessage(newVariableIdErrors);

  const [editVariableId, setEditVariableId] = useState<string>('');
  const editVariableIdErrors = useMemo(() => variableIdValidator(editVariableId), [editVariableId]);
  const editVariableIdErrorMessage = getVariableIdErrorMessage(editVariableIdErrors);

  const [editVariableLabel, setEditVariableLabel] = useState<string>('');
  const editVariableLabelErrors = useMemo(() => variableLabelValidator(editVariableLabel), [editVariableLabel]);
  const editVariableLabelErrorMessage = getVariableLabelErrorMessage(editVariableLabelErrors);

  const [editVariableDescription, setEditVariableDescription] = useState<string>('');
  const editVariableDescriptionErrors = useMemo(() => variableDescriptionValidator(editVariableDescription), [editVariableDescription]);
  const editVariableDescriptionErrorMessage = getVariableDescriptionErrorMessage(editVariableDescriptionErrors);

  const [editVariableType, setEditVariableType] = useState<ValueType>(ValueType.UNKNOWN_VALUE);

  const hasMadeVariableChanges = selectedVariable && (
    selectedVariable.getId() !== editVariableId
    || selectedVariable.getLabel() !== editVariableLabel
    || selectedVariable.getDescription() !== editVariableDescription
    || selectedVariable.getAccepting()?.getType() !== editVariableType
  );

  const [showConfirmationDialog, setShowConfirmationDialog] = useState<{ index: number, variable: Variable } | null>(null);

  function addVariableClick() {
    if (
      !newVariableId
      || variables.find(item => item.getId() === newVariableId)
    ) {
      return;
    }

    const newVariable = new Variable()
      .setId(newVariableId)
      .setLabel(newVariableId)
      .setAccepting(new TypeDescription().setType(newVariableType));
    onChange([...variables, newVariable]);
    setNewVariableId('');
    setAddVariablePanelOpen(false);
  }

  function confirmRemoveVariable(at: number, variable: Variable): void {
    setShowConfirmationDialog({ index: at, variable });
  }

  function removeVariable(at: number): void {
    onChange(variables.filter((_, index) => index !== at));
    setSelectedVariableId(null);
  }

  function selectVariable(variable: Variable) {
    setSelectedVariableId(variable.getId());
    setEditVariableId(variable.getId());
    setEditVariableLabel(variable.getLabel());
    setEditVariableDescription(variable.getDescription());
    setEditVariableType(variable.getAccepting()?.getType() || ValueType.UNKNOWN_VALUE);
    setAddVariablePanelOpen(false);
  }

  function commitEditVariableChanges() {
    if (!selectedVariable) {
      return;
    }
    const newVariable = selectedVariable.clone()
      .setId(editVariableId)
      .setLabel(editVariableLabel)
      .setDescription(editVariableDescription)
      .setAccepting(new TypeDescription().setType(editVariableType));
    const newVariables = variables.map(item => item.getId() === selectedVariableId ? newVariable : item);
    onChange(newVariables);
    selectVariable(newVariable);
  }

  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <Stack>
      <Typography variant="subtitle2" fontWeight="bold" sx={{ mb: 2 }}>
        Variables
      </Typography>
      <div>
        {variables.length > 0 && variables.map((variable, index) => (
          <Accordion
            key={variable.getId()}
            expanded={selectedVariableId === variable.getId()}
            onChange={(_, expanded) => expanded ? selectVariable(variable) : setSelectedVariableId(null)}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              sx={{
                gap: 2,
                '>.MuiAccordionSummary-content': {
                  maxWidth: '100%',
                },
              }}
            >
              <ContainedDiv>
                <Typography sx={{ maxWidth: '100%', overflowX: 'hidden', textOverflow: 'ellipsis' }}>{variable.getLabel() || variable.getId()}</Typography>
                <small>
                  <Typography sx={(theme) => ({ color: theme.palette.grey[500] })}>{formatVariableType(variable.getAccepting()?.getType())}</Typography>
                </small>
              </ContainedDiv>
            </AccordionSummary>
            <AccordionDetails>
              <Stack gap={1} sx={{ mb: 1 }}>
                <Typography variant={'subtitle2'} fontWeight={'bold'}>
                  Properties
                </Typography>
                <TextField
                  value={editVariableId}
                  variant="outlined"
                  label="ID"
                  size="small"
                  error={editVariableIdErrors !== null}
                  helperText={editVariableIdErrorMessage || 'Unique identifier used by other nodes to reference this variable'}
                  onChange={event => setEditVariableId(event.target.value)}
                />
                <TextField
                  value={editVariableLabel}
                  variant="outlined"
                  label="Label"
                  size="small"
                  error={editVariableLabelErrors !== null}
                  helperText={editVariableLabelErrorMessage || 'The human-readable name of the variable'}
                  onChange={event => setEditVariableLabel(event.target.value)}
                />
                <TextField
                  value={editVariableDescription}
                  variant="outlined"
                  label="Description"
                  size="small"
                  error={editVariableDescriptionErrors !== null}
                  helperText={editVariableDescriptionErrorMessage || 'Description of the variable'}
                  multiline
                  rows={2}
                  onChange={event => setEditVariableDescription(event.target.value)}
                />
                <FormControl fullWidth>
                  <InputLabel id="variable-type">Type</InputLabel>
                  <Select
                    label="Type"
                    variant="outlined"
                    value={editVariableType || ''}
                    onChange={(option) => setEditVariableType(option.target.value as ValueType)}
                    size="small"
                  >
                    <MenuItem value={ValueType.STRING}>String</MenuItem>
                    <MenuItem value={ValueType.DECIMAL}>Decimal</MenuItem>
                    <MenuItem value={ValueType.INTEGER}>Integer</MenuItem>
                    <MenuItem value={ValueType.DATETIME}>Datetime</MenuItem>
                    <MenuItem value={ValueType.BOOLEAN}>Boolean</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
            </AccordionDetails>
            <AccordionActions sx={{ justifyContent: 'space-between' }}>
              <Button
                color="secondary"
                onClick={() => confirmRemoveVariable(index, variable)}
                variant="outlined"
              >Delete</Button>
              <Stack direction="row" gap={1}>
                <Button
                  color="inherit"
                  variant="text"
                  onClick={() => selectVariable(variable)}
                  disabled={!hasMadeVariableChanges}
                >Discard</Button>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => commitEditVariableChanges()}
                  disabled={!hasMadeVariableChanges || editVariableIdErrors !== null || editVariableLabelErrors !== null || editVariableDescriptionErrors !== null}
                >Save</Button>
              </Stack>
            </AccordionActions>
          </Accordion>
        ))}
      </div>
      {(variables.length === 0 || isAddVariablePanelOpen) && (
        <Stack gap={1}>
          <Stack direction="row" gap={1}>
            <TextField
              label="Variable id"
              variant="outlined"
              error={newVariableId.length > 0 && newVariableIdErrors !== null}
              helperText={newVariableId.length > 0 && newVariableIdErrorMessage}
              value={newVariableId}
              inputRef={inputRef}
              onChange={(event) => setNewVariableId(event.target.value)}
              inputProps={{ min: 2, max: 20, pattern: variableIdPattern.source.substring(1, variableIdPattern.source.length - 1) }}
              size="small"
              sx={{ flexGrow: 1 }}
            />
            <FormControl
              sx={{ flex: '0 0 150px' }}
            >
              <InputLabel id="new-variable-type">Type</InputLabel>
              <Select
                label="Type"
                variant="outlined"
                value={newVariableType}
                onChange={(option) => setNewVariableType(option.target.value as ValueType)}
                size="small"
              >
                <MenuItem value={ValueType.STRING}>String</MenuItem>
                <MenuItem value={ValueType.DECIMAL}>Decimal</MenuItem>
                <MenuItem value={ValueType.INTEGER}>Integer</MenuItem>
                <MenuItem value={ValueType.DATETIME}>Datetime</MenuItem>
                <MenuItem value={ValueType.BOOLEAN}>Boolean</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <Stack direction="row" justifyContent="flex-end" gap={1}>
            {variables.length > 0 && (
              <Button
                color="inherit"
                onClick={() => setAddVariablePanelOpen(false)}
                variant="text"
              >Cancel</Button>
            )}
            <Button
              startIcon={<Add />}
              disabled={newVariableId.length === 0 || newVariableIdErrors !== null}
              color="primary"
              onClick={addVariableClick}
              variant="outlined"
            >Add variable</Button>
          </Stack>
        </Stack>
      )}
      {variables.length > 0 && !isAddVariablePanelOpen && (
        <ToolbarContainer>
          <Button
            startIcon={<Add />}
            onClick={() => {
              setSelectedVariableId(null);
              setAddVariablePanelOpen(true);
              setTimeout(
                () => {
                  inputRef.current?.focus();
                },
                300
              );
            }}
            variant="text"
            color="primary"
          >New variable</Button>
        </ToolbarContainer>
      )}
      <ConfirmationDialog
        open={showConfirmationDialog !== null}
        title="Delete variable"
        cancelButtonColor="inherit"
        cancelButtonText="No, keep it"
        acceptButtonColor="secondary"
        acceptButtonText="Yes, delete"
        acceptButtonVariant="contained"
        contentText={`Are you sure you want to delete the variable "${showConfirmationDialog?.variable.getLabel() || 'N/A'}"?`}
        onClose={(reason: boolean) => {
          if (reason && showConfirmationDialog) {
            removeVariable(showConfirmationDialog.index);
          }
          setShowConfirmationDialog(null);
        }}
      />
    </Stack>);
}
