import { Close, DragIndicator, UnfoldLess, UnfoldMore } from '@mui/icons-material';
import {
  Box,
  Drawer,
  IconButton,
  useTheme
} from '@mui/material';
import { Stack } from '@mui/system';
import { PropsWithChildren, useCallback, useEffect, useState } from 'react';

export const minDrawerWidth = 250;
export const dividerWidth = 13;

export interface ResizableDrawerProps extends PropsWithChildren {
  open: boolean;
  showFoldOptions?: boolean;
  minWidth?: number;
  onClose: () => void;
}

export function ResizableDrawer({
  open: isOpen,
  showFoldOptions,
  minWidth,
  children,
  onClose,
}: ResizableDrawerProps) {
  const theme = useTheme();
  const [drawerWidth, setDrawerWidth] = useState<number>(Math.max(minWidth || 0, 350));

  useEffect(
    () => {
      function handleWindowResize() {
        setDrawerWidth(value =>
          Math.min(
            document.body.clientWidth,
            Math.max(
              minWidth || 0,
              minDrawerWidth,
              value
            )
          ));
      }

      window.addEventListener('resize', handleWindowResize);
      return () => {
        window.removeEventListener('resize', handleWindowResize);
      };
    },
    [minWidth]
  );

  const handlePointerDown = useCallback(
    (event: React.PointerEvent<HTMLDivElement>) => {
      event.currentTarget.setPointerCapture(event.pointerId);
      event.currentTarget.addEventListener('pointermove', handlePointerMove, true);
    },
    []
  );

  const handlePointerUp = useCallback(
    (event: React.PointerEvent<HTMLDivElement>) => {
      event.currentTarget.releasePointerCapture(event.pointerId);
      event.currentTarget.removeEventListener('pointermove', handlePointerMove, true);
      setDrawerWidth(value => Math.max(minWidth || 0, minDrawerWidth, value));
    },
    [minWidth]
  );

  const handlePointerMove = useCallback(
    (e: PointerEvent) => {
      setDrawerWidth((previous: number) => previous - e.movementX);
    },
    []
  );

  function handleUnfoldClick() {
    setDrawerWidth(document.body.clientWidth);
  }

  function handleMinimizeClick() {
    setDrawerWidth(minDrawerWidth);
  }

  return (
    <Drawer
      open={isOpen}
      anchor="right"
      elevation={20}
      variant="temporary"
    >
      <Box
        sx={{
          width: dividerWidth,
          cursor: 'ew-resize',
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          zIndex: theme.zIndex.drawer + 1,
          backgroundColor: theme.palette.divider,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onPointerDown={handlePointerDown}
        onPointerUp={handlePointerUp}
      >
        <DragIndicator sx={{ color: 'text.secondary' }} />
      </Box>
      <Stack sx={{ height: '100%', minWidth: minWidth || minDrawerWidth, width: drawerWidth, pl: `${dividerWidth}px` }}>
        <Stack direction="row" justifyContent={showFoldOptions ? 'space-between' : 'flex-end'} alignItems="center">
          {showFoldOptions && (
            <div>
              <IconButton onClick={handleUnfoldClick}>
                <UnfoldMore style={{ transform: 'rotate(90deg)' }} />
              </IconButton>
              <IconButton onClick={handleMinimizeClick}>
                <UnfoldLess style={{ transform: 'rotate(90deg)' }} />
              </IconButton>
            </div>
          )}
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Stack>
        {children}
      </Stack>
    </Drawer>
  );
}
