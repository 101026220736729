import { Tab, TabTypeMap } from '@mui/material';
import { Link, To } from 'react-router-dom';

type LinkTabProps = TabTypeMap['props'] & {
  id?: string;
  value: To;
  selected?: boolean;
}

export function LinkTab({
  selected,
  value,
  id,
  ...props
}: LinkTabProps) {
  return (
    <Tab
      id={id}
      component={Link}
      aria-current={selected && 'page'}
      iconPosition="start"
      to={value}
      value={value.toString()}
      {...props}
    />
  );
}