import { RpcError, StatusCode } from 'grpc-web';
import { createContext, useEffect, useMemo, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { GetWorkspaceRequest, Workspace, getWorkspace } from '../services/workspace';

interface WorkspaceState {
  workspaceId?: string;
  workspace: Workspace;
  loading: boolean;
  exists: boolean;
  setWorkspace: React.Dispatch<React.SetStateAction<Workspace>>;
}

export const WorkspaceContext = createContext<WorkspaceState>({} as WorkspaceState);

export function WorkspaceWrapper() {
  const { workspaceId } = useParams();
  const [workspace, setWorkspace] = useState<Workspace>(new Workspace());
  const [notFound, setNotFound] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(
    () => {
      let subscribed = true;

      async function fetch() {
        if (!workspaceId) {
          return;
        }
        setLoading(true);
        try {
          const request = new GetWorkspaceRequest();
          request.setId(workspaceId);
          const result = await getWorkspace(request);
          if (subscribed) {
            setWorkspace(result.getItem() as Workspace);
          }
        } catch (error: unknown) {
          if (error instanceof RpcError && error.code === StatusCode.NOT_FOUND) {
            if (subscribed) {
              setNotFound(true);
            }
          } else {
            throw error;
          }
        } finally {
          if (subscribed) {
            setLoading(false);
          }
        }
      }

      fetch();

      return () => { subscribed = false; };
    },
    [workspaceId]
  );

  const state = useMemo(
    () => ({
      workspaceId,
      workspace,
      loading,
      exists: !notFound,
      setWorkspace,
    }),
    [workspaceId, workspace, loading, notFound]
  );

  return (
    <WorkspaceContext.Provider value={state}>
      <Outlet />
    </WorkspaceContext.Provider>
  );
}