import { Box, styled } from '@mui/material';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { LeftDrawer } from './LeftDrawer';
import { TopBar } from './TopBar';

const drawerWidth = 200;
const TopMargin = styled('div')(({ theme }) => ({
  ...theme.mixins.toolbar,
}));

function App() {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Box sx={{ display: 'flex', bgcolor: 'background.paper', height: '100%' }}>
      <TopBar
        drawerWidth={drawerWidth}
        open={open}
        onOpen={() => setOpen(true)}
      />
      <LeftDrawer
        open={open}
        drawerWidth={drawerWidth}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          width: '100%',
        }}
      >
        <TopMargin />
        <Outlet />
      </Box>
    </Box>
  );
}

export default App;
